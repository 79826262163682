import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { Product, ProductCategory } from '../models';

@Injectable()
export class ProfileService {
  constructor(private apiService: ApiService) {}

  create(data = {}): Observable<any> {
    return this.apiService
      .post('/answersmetadata', data)
      .pipe(map((res) => res.data));
  }

  getInfo(id: any): Observable<any> {
    return this.apiService
      .get(`/answersmetadata/searchbyuser?user_id=${id}`)
      .pipe(map((res) => res.data));
  }
  update(data = {}): Observable<any> {
    return this.apiService
      .put(`/answersmetadata`, data)
      .pipe(map((res) => res.data));
  }
}
