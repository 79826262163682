import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// external
import { ImagePickerConf } from 'ngp-image-picker';
import { switchMap } from 'rxjs/operators';
import moment from 'moment';
import { UnderwritingService } from '../../../core/services/underwriting.service';
// services
import {
  MediaService,
  ProductService,
  StoreService,
  NotificationService,
  ErrorHandlingService,
  BrokerService,
  UserService,
  InsurancePolicyService,
} from 'src/app/core/services';
// models
import { BrokerFirm, Product } from 'src/app/core/models';
import { Store } from 'src/app/core/models';
// components
import { RatingTableFormComponent } from 'src/app/shared/components/forms/rating-table-form/rating-table-form.component';
import { isDefined } from '@angular/compiler/src/util';
@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
})
export class ProductInfoComponent implements OnInit {
  storeInfo: Store;
  selectedRatingTable: any;
  selectedProductCategory: any;
  buttonFlag = false;
  storeId: number;
  productId: number;
  productInfo: Product;
  isEditingMode = false;
  productForm: FormGroup;
  productLogo: File;
  imagePickerConfig: ImagePickerConf = {
    borderRadius: '4px',
    language: 'en',
    width: '100%',
    height: '170px',
  };
  ratingTable: any[];
  selectedCategoryId: any;
  ratingtableData: any;
  brokerFirms: any[];
  preBrokerFirmList: any;
  preBrokerList: any;
  userInfo: any;
  policyWordingTemplates: any = [];
  displayedColumns = ['title', 'status', 'actions'];
  displayedUWColumns = ['question','operator','answer','active'];
  showPolicyWordingTemplate = false;
  policyWordingData: any;
  underwritingList: any = [];
  selectedOperator = '=';
  canViewDiv: boolean = false;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private mediaService: MediaService,
    private storeService: StoreService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private brokerService: BrokerService,
    private insurancePolicyService: InsurancePolicyService,
    private userService: UserService,
    private underwritingService: UnderwritingService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.userService.getCurrentUser();
    this.storeId = this.userInfo?.stores?.id;
    this.productId = parseInt(
      this.activatedRoute.snapshot?.paramMap?.get('id'),
      10
    );
    this.fetchProductInfo();
    this.fetchWordingTemplate();
    this.fetchBrokerFirms();
    this.fetchStoreInfo();
      //PRAJAL
    this.fetchUWC();
  }

  fetchUWC(): void {
    this.underwritingService
    .getInfo(this.productId)
    .subscribe((response) => {
      this.underwritingList = response;
    });
  }

  fetchWordingTemplate(): void {
    const data = {
      insurance_products_id: this.productId,
    };

    this.insurancePolicyService
      .searchByInsuranceProduct(data)
      .subscribe((response) => {
        this.policyWordingTemplates = response;
      });
  }

  fetchBrokerFirms(): void {
    this.brokerService.getBrokerFirmsByProductId(this.productId).subscribe(
      (response) => {
        this.brokerFirms = response;
      },
      (error) => {
        this.notificationService.error(
          'Error while fetching broker firms',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  fetchProductInfo(): void {
    this.productService.getInfo(this.productId,'brokerfirms,rating_table').subscribe(
      (response) => {
        this.productInfo = response;
        this.selectedProductCategory = response?.insurance_product_category_id;
        const data: any = response;
        this.preBrokerFirmList = data?.brokerfirms.map((item) => item.id);
        this.preBrokerList = data?.brokers?.map((item) => item.id); // PRAJAL TODO
        this.createProductFormBuilder();
      },
      (error) => {
        this.notificationService.error(
          'Error while fetching product info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  createProductFormBuilder(): void {
    this.productForm = this.formBuilder.group({
      name: [this.productInfo?.name, Validators.compose([Validators.required])],
      description: [this.productInfo?.description],
      product_number: [
        this.productInfo?.product_number,
        Validators.compose([Validators.required]),
      ],
      region: [
        this.productInfo?.region,
        Validators.compose([Validators.required]),
      ],
      valid_from: [
        moment(this.productInfo?.valid_from),
        Validators.compose([Validators.required]),
      ],
      valid_to: [
        moment(this.productInfo?.valid_to),
        Validators.compose([Validators.required]),
      ],
      terms_and_conditions: [this.productInfo?.terms_and_conditions],
      is_underwriter_review: [this.productInfo?.is_underwriting_review_req],
      rating_table_id: ['', Validators.compose([Validators.required])],
      insurance_product_category_id: [
        Validators.compose([Validators.required]),
      ],
    });
  }

  addEvent(data): void {
    const selectedDate = new Date(
      moment(data.target.value).format('YYYY-MM-DD')
    );
    this.productForm.controls['valid_from'].setValue(
      selectedDate.toISOString().split('T')[0]
    );
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    const addedYear = new Date(year + 1, month, day);

    this.productForm.controls['valid_to'].setValue(
      addedYear.toISOString().split('T')[0]
    );
  }

  toggleEditingMode(): void {
    this.isEditingMode = !this.isEditingMode;
  }

  onImageChanged(file): void {
    this.productLogo = file;
  }

  onChangeText(data: any): void {
    this.productForm.get('description').setValue(data);
  }

  fetchStoreInfo(): void {
    this.storeService.getInfo(this.storeId,null).subscribe(
      (response) => {
        this.storeInfo = response;
        this.ratingtableData = response?.mga_firm?.rating_tables;
        this.ratingTable = this.ratingtableData.filter(
          (data) =>
            data.insurance_product_category_id === this.selectedCategoryId
        );
      },
      (error) => {
        this.notificationService.error(
          'Error while fetching the store info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  openRatingTableDialog(): void {
    const dialogRef = this.dialog.open(RatingTableFormComponent, {
      width: '50%',
      data: { ...this.storeInfo, selectedCategoryId: this.selectedCategoryId },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchStoreInfo();
      }
    });
  }

  selectedRatingTableId(id: number): void {
    this.selectedRatingTable = id;
    this.productForm.get('rating_table_id').setValue(id);
  }

  getSelectedProdcutCategories(id: any): void {
    this.selectedCategoryId = id;
    this.ratingTable = this.ratingtableData.filter(
      (data) => data.insurance_product_category_id === this.selectedCategoryId
    );
  }

  saveProductForm(): void {
    //get details here to update underwriting review PRAJAL

    if(!this.productForm.value
      .is_underwriter_review){
        let isValid = true;
      this.underwritingList.forEach((item) => {
        //console.log('validation',item);
        if(item.active ==1 || item.active == '1'){
            if(item.type != 'date'){
              if(item.answer == null || item.answer == '')
                 isValid = false;
            } else{
              try{
                if(item.answer == null || item.answer == ''){
                  isValid = false;
                } else {
                  const date = moment(new Date(item.answer)).format('YYYY-MM-DD');
                }
              }catch(error){
                isValid = false;
              }
            }
        }
      });
      if(!isValid){
        this.notificationService.error(
          'Enter valid answers for all active Underwriting Criterias',''
        );
      }
    }
    this.underwritingService
      .updateAll(this.underwritingList)
      .subscribe((response) => {
        //console.log(response);     
        //alert('save successfully');
    });
    

    if (this.productLogo) {
      this.buttonFlag = true;
      this.mediaService
        .create(this.productLogo)
        .pipe(
          switchMap((mediaResponse) => {
            return this.productService.update(this.productId, {
              ...this.productForm.value,
              region: this.productForm.value.region.map((x) => x).join(', '),
              aggregate_limit: 1,
              rating_table_id:
                this.productForm.value.rating_table_id ||
                this.productInfo?.rating_table?.id, // TODO: Replace static values with default value.
              store_id: this.storeId,
              logo_id: mediaResponse.id,
              valid_from: this.productForm.value.valid_from.format(
                'YYYY-MM-DD'
              ),
              valid_to: this.productForm.value.valid_to.format('YYYY-MM-DD'),
              brokerfirms: this.preBrokerFirmList,
              brokers: this.preBrokerList,
              is_underwriting_review_req: this.productForm.value
                .is_underwriter_review,
            });
          })
        )
        .subscribe(
          (response) => {
            this.notificationService.success(
              '',
              'Product has been updated successfully'
            );
            this.router.navigate(['/mga/products/']);
          },
          (error) => {
            this.notificationService.error(
              '',
              this.errorHandlingService.getErrors(error)
            );
            this.buttonFlag = false;
          }
        );
    } else {
      this.productService
        .update(this.productId, {
          ...this.productForm.value,
          region: this.productForm.value.region.map((x) => x).join(', '),
          aggregate_limit: 1,
          rating_table_id: this.selectedRatingTable, // TODO: Replace static values with default value.
          store_id: this.storeId,
          logo_id: this.productInfo.logo?.id,
          valid_from: this.productForm.value.valid_from.format('YYYY-MM-DD'),
          valid_to: this.productForm.value.valid_to.format('YYYY-MM-DD'),
          brokerfirms: this.preBrokerFirmList,
          brokers: this.preBrokerList,
          is_underwriting_review_req: this.productForm.value
            .is_underwriter_review,
        })
        .subscribe(
          (response) => {
            this.buttonFlag = false;
            this.fetchProductInfo();
            this.toggleEditingMode();
            this.notificationService.success(
              '',
              'Product has been updated successfully'
            );
          },
          (error) => {
            this.notificationService.error(
              'Error while saving product form',
              this.errorHandlingService.getErrors(error)
            );
            this.buttonFlag = false;
          }
        );
    }
  }

  addPolicyTemplate(): void {
    this.showPolicyWordingTemplate = true;
  }

  editPolicyTemplate(templateData: any): void {
    this.policyWordingData = templateData;
    this.showPolicyWordingTemplate = true;
  }

  deletePolicyTemplate(templateData: any): void {
    const templateId = templateData.id;
    if (templateId) {
      this.insurancePolicyService
        .deletePolicyWordingTemplate(templateId)
        .subscribe((response) => {
          this.fetchWordingTemplate();
        });
    }
  }

  async updatePolicyTemplatesList(event): Promise<void> {//PRAJAL
    this.closePolicyBuilder(event);
    await this.sleep(1000);
    await this.fetchWordingTemplate();  
  }

  closePolicyBuilder(event): void {
    this.showPolicyWordingTemplate = false;
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  changeOperator(element,event) {
    let value;
    if(event?.value != undefined){
      value = event.value;
      //console.log('#Prajal: ' +event.value);
    } else if(event?.target?.value != undefined){
      value = event.target.value;
      //console.log('#Prajal: ' +event.target.value);
    }
  //  console.log('#Operator: ',value);  
   // console.log('#Before Operator Update: ',element);  
    let index = this.underwritingList.indexOf(element);
    this.underwritingList[index].operator = value;
   // console.log('#After Operator Update: ',this.underwritingList[index]);  
  }

  changeAnswer(element,event) {
    let value;
    if(event?.value?._d){
      const date = moment(new Date(event.value._d)).format('YYYY-MM-DD');
     // console.log('#Prajal22: ',date);
      value = date;  
    } else if(event?.value != undefined){
      //console.log('#Prajal: ' +event.value);
      value = event.value;
    } else if(event?.target?.value != undefined){
      //console.log('#Prajal: ' +event?.target?.value);
      value = event.target.value;
    } 
    //console.log('#Value: ',value);  
    //console.log('#Before Update: ',element);  
    let index = this.underwritingList.indexOf(element);
    this.underwritingList[index].answer = value;
  //  console.log('#After: ',this.underwritingList[index]);  
   
  }

  checkboxModelChange(element,event){
    //console.log('#Active : ',event.checked);
    //console.log('#Before Active : ',element);
    
    let index = this.underwritingList.indexOf(element);
    this.underwritingList[index].active = event.checked === true ? 1 : 0;
  //  console.log('#After Active Update: ',this.underwritingList[index]);
  }
  changeAnswer1(element,event){
    let value;
    if(event?.value?._d){
      const date = moment(new Date(event.value._d)).format('YYYY-MM-DD');
    //  console.log('#Prajal22: ',date);
      value = date;  
    } else if(event?.value != undefined){
      //console.log('#Prajal: ' +event.value);
      value = event.value;
    } else if(event?.target?.value != undefined){
      //console.log('#Prajal: ' +event?.target?.value);
      value = event.target.value;
    } 
    //console.log('#Value: ',value);  
    //console.log('#Before Update: ',element);  
    if(value == "0")
      this.canViewDiv = true;
      else
      this.canViewDiv = false;
   
  }

}
