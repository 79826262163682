import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// rxjs
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
// env
import { environment } from '../../../environments/environment';
import { MatFormField } from '@angular/material/form-field';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  private setHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.useAuthToken()}`,
    });
  }

  private setTokenHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.useAuthToken()}`,
    });
  }

  private formatErrors(error: any): Observable<any> {
    return observableThrowError(error);
  }

  private convertFormData(data): FormData {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((el) => {
          formData.append(key, el);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });
    return formData;
  }

  public convertMediaArray(data: File[]): FormData {
    const formData = new FormData();
    data.forEach((item) => {
      formData.append('medias[]', item);
    });
    return formData;
  }

  addAuthToken(token): void {
    window.localStorage.setItem('token', token);
  }

  useAuthToken(): string {
    return window.localStorage.getItem('token');
  }

  deleteAuthToken(): void {
    window.localStorage.removeItem('token');
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${environment.api_url}${path}`, {
        headers: this.setHeaders(),
        params,
      })
      .pipe(catchError(this.formatErrors));
  }

  getMedia(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.http
      .get(`${path}`, {
        params,
        responseType: 'blob',
        // observe: 'response',
      })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body = {}): Observable<any> {
    return this.http
      .put(`${environment.api_url}${path}`, JSON.stringify(body), {
        headers: this.setHeaders(),
      })
      .pipe(catchError(this.formatErrors));
  }

  putFormData(path: string, body = {}): Observable<any> {
    return this.http
      .put(`${environment.api_url}${path}`, this.convertFormData(body))
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body = {}, options = {}, headers = {}): Observable<any> {
    return this.http
      .post(
        `${environment.api_url}${path}`,
        body,
        Object.assign({}, options, {
          headers: this.setHeaders(),
        })
      )
      .pipe(catchError(this.formatErrors));
  }

  postFormData(path: string, body = {}, options = {}): Observable<any> {
    return this.http
      .post(
        `${environment.api_url}${path}`,
        this.convertFormData(body),
        Object.assign({}, options, { headers: this.setTokenHeaders() })
      )
      .pipe(catchError(this.formatErrors));
  }

  postFormDataArray(path: string, data = [], options = {}): Observable<any> {
    return this.http
      .post(
        `${environment.api_url}${path}`,
        this.convertMediaArray(data),
        Object.assign({}, options, { headers: this.setTokenHeaders() })
      )
      .pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http
      .delete(`${environment.api_url}${path}`, { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  getBusinessOwnerVerified(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.http
      .get(`${path}`, {
        headers: this.setHeaders(),
        params,
      })
      .pipe(catchError(this.formatErrors));
  }

  postClientResetPassword(
    path: string,
    body = {},
    options = {},
    headers = {}
  ): Observable<any> {
    return this.http
      .post(
        `${path}`,
        body,
        Object.assign({}, options, { headers: this.setHeaders() })
      )
      .pipe(catchError(this.formatErrors));
  }

  base64ImageToBlob(str): Blob {
    // extract content type and base64 payload from original string
    const pos = str.indexOf(';base64,');
    const type = str.substring(5, pos);
    const b64 = str.substr(pos + 8);
    // decode base64
    const imageContent = atob(b64);
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);
    // fill the view, using the decoded base64
    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    // convert ArrayBuffer to Blob
    const blob = new Blob([buffer], { type });
    return blob;
  }
}
