import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// rxjs
import { switchMap } from 'rxjs/operators';
// external
import {
  Location,
  Appearance,
} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { ImagePickerConf } from 'ngp-image-picker';
// shared
import { CustomEmailValidator } from 'src/app/shared';
// services
import {
  AddressService,
  FirmService,
  MediaService,
  UserService,
  FormValidatorService,
  NotificationService,
  ErrorHandlingService,
  StoreService,
} from '../../../core/services';

@Component({
  selector: 'app-admin-store-create',
  templateUrl: './store-create.component.html',
  styleUrls: ['./store-create.component.scss'],
})
export class StoreCreateComponent implements OnInit {
  phoneNumber: any;
  storeForm: FormGroup;
  storeLogo: File;
  storeCover: File;
  firmDocuments: File[] = [];
  isStoreCreated = false;
  // image picker config
  imagePickerConfig: ImagePickerConf = {
    borderRadius: '4px',
    language: 'en',
    width: '100%',
    height: '170px',
  };
  // google maps
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  // submission items
  storeLogoId: string;
  storeCoverId: string;
  buttonFlag = false;
  constructor(
    private formBuilder: FormBuilder,
    private mediaService: MediaService,
    private addressService: AddressService,
    private storeService: StoreService,
    private userService: UserService,
    private formValidatorService: FormValidatorService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.createStoreFormGroup();
  }

  formatPhoneNumber(event: any): string {
    const cleaned = ('' + event).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      this.phoneNumber =
        '(' + match[1] + ') ' + '-' + match[2] + '-' + match[3];
    }
    return null;
  }

  createStoreFormGroup(): void {
    this.storeForm = this.formBuilder.group({
      store_name: ['', Validators.compose([Validators.required])],
      firm_name: ['', Validators.compose([Validators.required])],
      firm_address: ['', Validators.compose([Validators.required])],
      city: [''],
      postal_code: [''],
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      phone_number: [
        '',
        Validators.compose([Validators.required, Validators.pattern('[0-9]+')]),
      ],
    });
  }

  onLogoChanged(file): void {
    this.storeLogo = file;
  }

  onCoverChanged(file): void {
    this.storeCover = file;
  }

  onSelectFirmDocuments(event): void {
    this.firmDocuments.push(...event.addedFiles);
  }

  onRemoveFirmDocuments(file): void {
    this.firmDocuments.splice(this.firmDocuments.indexOf(file), 1);
  }

  getCityFromResult(result: PlaceResult): string {
    const cities = result.address_components.filter((item) => {
      if (item.types.includes('locality')) {
        return item.long_name;
      }
    });
    return cities.length > 0 ? cities[0].long_name : 'N/A';
  }

  getPostalCodeFromResult(result: PlaceResult): string {
    const postalCodes = result.address_components.filter((item) => {
      if (item.types.includes('postal_code')) {
        return true;
      }
    });
    return postalCodes.length > 0 ? postalCodes[0].long_name : 'N/A';
  }

  onAutocompleteSelected(result: PlaceResult): void {
    this.storeForm.get('firm_address').setValue(result.name);
    this.storeForm.get('city').setValue(this.getCityFromResult(result));
    this.storeForm
      .get('postal_code')
      .setValue(this.getPostalCodeFromResult(result));
  }

  onLocationSelected(location: Location): void {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  createNewStore(): void {
    if (this.storeForm.valid) {
      this.buttonFlag = true;
      const storeManagerObj = {
        email: this.storeForm.get('email').value,
        first_name: this.storeForm.get('first_name').value,
        last_name: this.storeForm.get('last_name').value,
        beneficiary_business_name: 'PeerCircle',
        phone_number: this.storeForm.get('phone_number').value,
        admin: 1,
      };

      this.mediaService
        .create(this.storeLogo)
        .pipe(
          switchMap((storeLogoResponse) => {
            this.storeLogoId = storeLogoResponse.id;
            return this.mediaService.create(this.storeCover);
          }),
          switchMap((storeCoverResponse) => {
            this.storeCoverId = storeCoverResponse.id;
            return this.addressService.create({
              street_address: this.storeForm.get('firm_address').value,
              city: this.storeForm.get('city').value,
              zip_code: this.storeForm.get('postal_code').value,
              country_id: 1, // TODO: Replace Static Country in V2
            });
          }),
          switchMap((addressResponse) => {
            const storeData = {
              name: this.storeForm.get('store_name').value,
              cover_media_id: this.storeCoverId,
            };
            const firmData = {
              name: this.storeForm.get('firm_name').value,
              address_id: addressResponse.id,
              logo_id: this.storeLogoId,
              documents: [],
            };
            return this.storeService.create(
              storeData,
              firmData,
              storeManagerObj
            );
          })
        )
        .subscribe(
          (response) => {
            this.notificationService.success(
              'Store has been created Successfully',
              ''
            );
            this.isStoreCreated = true;
          },
          (err) => {
            this.buttonFlag = false;
            this.notificationService.error(
              'Error occurred while creating store manager',
              this.errorHandlingService.getErrors(err)
            );
          }
        );
    } else {
      this.formValidatorService.validateAllFormFields(this.storeForm);
    }
  }
}
