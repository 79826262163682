import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// services
import {
  ApiService,
  ErrorHandlingService,
  NotificationService,
  UserService,
} from '../../core/services';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SignInComponent implements OnInit {
  isSubmitting = false;
  authForm: FormGroup;
  errorMsg = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private errorHandlingService: ErrorHandlingService,
    private notificationService: NotificationService,
    private apiService: ApiService
  ) {
    this.authForm = this.fb.group({
      email: ['', Validators.compose([Validators.required])],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  submitForm(): void {
    if (this.authForm.valid) {
      this.isSubmitting = true;
      this.userService.login(this.authForm.value).subscribe(
        (loginResponse) => {
          this.apiService.addAuthToken(loginResponse.data.token.accessToken);
          this.userService.setAuth(loginResponse.data.user);
          // PRAJAL
          const url = window.localStorage.getItem('redirectURL');
          if (url) {
            window.localStorage.setItem('redirectURL', '');
            window.location.href = url;
          } else {
            this.navigateToDashboard(loginResponse.data.user.userable_type);
          }
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while submitting form',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
    }
  }

  navigateToDashboard(userType): void {
    if (this.userService.isAdmin(userType)) {
      this.router.navigate([`/admin/broker-firms`]);
    } else if (this.userService.isBroker(userType)) {
      this.router.navigate([`/broker`]);
    } else if (this.userService.isMga(userType)) {
      this.router.navigate([`/mga`]);
    } else if (this.userService.isClient(userType)) {
      this.router.navigate([`/client`]);
    }
  }
}
