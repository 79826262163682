import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FirmService,
  NotificationService,
  ProductService,
} from 'src/app/core/services';
import { CustomEmailValidator } from 'src/app/shared';

@Component({
  selector: 'app-contact-broker-form-home',
  templateUrl: './contact-broker-form-home.component.html',
  styleUrls: ['./contact-broker-form-home.component.scss'],
})
export class ContactBrokerFormHomeComponent implements OnInit {
  private contactBrokerForm;
  private productInfo;

  constructor(
    public dialogRef: MatDialogRef<ContactBrokerFormHomeComponent>,
    private formBuilder: FormBuilder,
    private firmService: FirmService,
    private productService: ProductService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public dialogData
  ) {}

  ngOnInit(): void {
    this.initializeContactBrokerForm();
    this.getProductInfo();
  }

  getProductInfo(): void {
    this.productService
      .getInfo(this.dialogData.productId,'')
      .subscribe((response) => {
        this.productInfo = response;
      });
  }

  initializeContactBrokerForm(): void {
    this.contactBrokerForm = this.formBuilder.group({
      first_name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z]+'),
        ]),
      ],
      last_name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z]+'),
        ]),
      ],
      company_name: ['', Validators.compose([Validators.required])],
      phone_number: [
        '',
        Validators.compose([Validators.required, Validators.pattern('[0-9]+')]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          CustomEmailValidator.validate,
        ]),
      ],
      message: ['', Validators.compose([])],
    });
  }

  submitForm(): void {
    const postData = {
      broker_firm_id: this.dialogData.brokerFirmInfo.id,
      email: this.contactBrokerForm.get('email').value,
      firstname: this.contactBrokerForm.get('first_name').value,
      lastname: this.contactBrokerForm.get('last_name').value,
      companyname: this.contactBrokerForm.get('company_name').value,
      phone: this.contactBrokerForm.get('phone_number').value,
      productname: this.productInfo?.name,
      storename: this.productInfo?.store?.name,
      message: this.contactBrokerForm.get('message').value,
    };

    this.firmService.contactBrokerFirm(postData).subscribe(() => {
      this.notificationService.success('Email sent successfully');
      this.dialogRef.close();
    });
  }
}
