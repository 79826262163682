import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// service
import { BrokerService } from 'src/app/core/services';

@Component({
  selector: 'app-broker-info',
  templateUrl: './broker-info.component.html',
  styleUrls: ['./broker-info.component.scss'],
})
export class BrokerInfoComponent implements OnInit {
  brokerInfo: any;
  brokerId: any;
  brokerFirmId: any;
  constructor(
    private activateRouter: ActivatedRoute,
    private brokerService: BrokerService
  ) {}

  ngOnInit(): void {
    this.brokerId = this.activateRouter?.snapshot?.paramMap?.get('brokerId');
    this.brokerFirmId = this.activateRouter?.snapshot?.paramMap?.get(
      'brokerFirmId'
    );
    this.fetchBrokerInfo();
  }

  fetchBrokerInfo(): void {
    this.brokerService.getInfo(this.brokerId).subscribe(
      (response) => {
        this.brokerInfo = response;
      },
      (err) => {}
    );
  }
}
