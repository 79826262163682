import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// service
import {
  ProductService,
  NotificationService,
  ErrorHandlingService,
} from '../../../core/services';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
})
export class ProductInfoComponent implements OnInit {
  productId: number;
  productInfo: any;
  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.productId = +params['id']; // (+) converts string 'id' to a number
    });

    this.fetchProductInfo();
  }

  fetchProductInfo(): void {
    this.productService.getInfo(this.productId,'').subscribe(
      (response) => {
        this.productInfo = response;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching form info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
}
