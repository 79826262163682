import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
// models
import { Product } from 'src/app/core/models';

@Component({
  selector: 'app-products-card-list',
  templateUrl: './products-card-list.component.html',
  styleUrls: ['./products-card-list.component.scss'],
})
export class ProductsCardListComponent implements OnInit {
  @Input() products: Product[];
  @Output() productClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onProductClick(event): void {
    this.productClick.emit(event);
  }
}
