import { Component, OnInit } from '@angular/core';
// service
import {
  NotificationService,
  ProductService,
  ErrorHandlingService,
  UserService,
  BrokerService,
} from '../../../core/services';
// dialog
import { MatDialog } from '@angular/material/dialog';
// components
import { DeleteDialogComponent } from '../../../shared/components/delete-dialog/delete-dialog.component';
// models
import { Broker, Product } from 'src/app/core/models';
import { switchMap, mergeMap } from 'rxjs/operators';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { interval } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// loader
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  displayedColumns = [
    'product_number',
    'name',
    'category',
    'region',
    'valid_from',
    'valid_to',
    'actions',
    'select',
  ];
  productId: any;
  productInfo: any;
  currentUser: any;
  finalDataList = [];
  finalFinalList: any[] = [];
  brokerData: any;
  brokerList: any;
  addBrokers: any;
  updateBrokerList: any;
  products: any;
  userInfo: any;
  brokerFirmId: any;
  brokerFirmIdList: any[] = [];
  brokerIdList: any[] = [];
  brokersInfo: any;
  brokersIdList: any[] = [];
  data: any[] = [];
  brokerFirmProductList: any;
  testing: any[] = [];
  let = true;
  addingField: any[] = [];
  finalData: any;
  productBrokerFirmList: any[] = [];
  isChangeButtonDisabled = false;
  constructor(
    public dialog: MatDialog,
    private productService: ProductService,
    private errorHandlingService: ErrorHandlingService,
    private notificationService: NotificationService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private brokerService: BrokerService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.brokerFirmId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.userInfo = this.userService.getCurrentUser();
    this.fetchAllData();
    // this.fetchProductList();
    // this.fetchProductByBrokerFirmId();
    // this.fetchBrokerListByBrokerFirmId();
    // this.fetchBrokerFirmInfo();
  }

  // fetchBrokerListByBrokerFirmId(): void {
  //   this.brokerService.getListByBrokerFirmId(this.brokerFirmId).subscribe(
  //     (res) => {
  //       this.brokersInfo = res.map((item) => item.id);
  //     },
  //     (err) => {}
  //   );
  // }

  // fetchProductByBrokerFirmId(): void {
  //   this.productService.searchByBrokerFirmId(this.brokerFirmId).subscribe(
  //     (res) => {
  //       this.brokerFirmProductList = res;
  //       console.log('product list by broker firm>>', res);
  //     },
  //     (err) => {}
  //   );
  //  }
  //  fetchProductList(): void {
  //   this.productService
  //     .searchProductsByMgaFirmId(this.userInfo?.userable?.mga_firm_id)
  //     .subscribe(
  //       (response) => {
  //         const productListId = this.brokerFirmProductList?.map(
  //           (item) => item.id
  //         );

  //         this.products = response;
  //         this.finalData = response.map((item) => {
  //           if (productListId?.includes(item.id)) {
  //             item['select'] = true;
  //             return item;
  //           } else {
  //             item['select'] = false;
  //             return item;
  //           }
  //         });
  //         console.log('final data >>>', this.finalData);
  //       },
  //       (error) => {
  //         this.notificationService.error(
  //           '',
  //           this.errorHandlingService.getErrors(error)
  //         );
  //       }
  //     );
  //  }

  fetchAllData(): void {
    this.productService
      .searchProductsByMgaFirmId(
        this.userInfo?.userable?.mga_firm_id,
        'insurance_product_category,brokerfirms'
      )
      .subscribe(
        (response) => {
          const url = window.location.href;
          const firmId = url
            .substring(
              url.lastIndexOf('/broker-firms/') + 1,
              url.lastIndexOf('/products')
            )
            .split('/')[1];

          this.products = response;
          // PRAJAL
          this.products.forEach((item) => {
            const firms = [];
            if (
              item?.brokerfirms &&
              Object.prototype.toString.call(item?.brokerfirms) !==
                '[object Array]'
            ) {
              const bfirms = [];
              let i = 0;
              while (i <= 100) {
                if (item.brokerfirms[i]) {
                  bfirms.push(item.brokerfirms[i]);
                }
                i++;
              }
              item['brokerfirms'] = bfirms;
            }

            if (item?.brokerfirms.length > 0) {
              item?.brokerfirms?.every((element) => {
                if (element.id === firmId) {
                  item['select'] = true;
                  return false;
                } else {
                  item['select'] = false;
                  return true;
                }
              });
            }
          });
          this.finalData = this.products;
        },
        (err) => {}
      );
  }

  checkboxModelChange(selectedBroker: any, event: any): void {
    if (event.checked) {
      this.products[selectedBroker.id - 1].select = true;
      this.products[selectedBroker.id - 1].isChanged = true;
    } else {
      this.products[selectedBroker.id - 1].select = false;
      this.products[selectedBroker.id - 1].isChanged = true;
    }
  }

  addBrokerToProduct(): void {
    this.updateProductWithBrokerFirm();
  }

  updateProductWithBrokerFirm(): void {
    // select updated product list from backend
    const url = window.location.href;
    const firmId = url
      .substring(
        url.lastIndexOf('/broker-firms/') + 1,
        url.lastIndexOf('/products')
      )
      .split('/')[1];
    const updateBrokerFirms = [];

    // PRAJAL START
    const prods = [];
    let prodCount = 0;
    let isThereChanges = false;
    this.products.forEach((item) => {
      if (item.select === undefined) {
        item.select = false;
      }
      if (item.isChanged) {
        isThereChanges = true;
        // console.log(item.id,':',item.select);
        prods[prodCount++] = {
          id: item.id,
          select: item.select,
        };
      }
    });
    const data = {
      products: prods,
      brokerFirmId: firmId,
    };
    if (!isThereChanges) {
      this.notificationService.success('', 'There are no changes');
      this.router.navigate([`/mga/broker-firms`]);
      return;
    } else {
      this.notificationService.info(' ', 'Updating...', {
        // maxOpened:1,
        toastId: 999,
        timeOut: 100000,
        // preventDuplicates:true,
        // resetTimeoutOnDuplicate:true,
        positionClass: 'toast-bottom-right',
      });
    }

    // sending request to update all
    this.productService.updateAll(data).subscribe(
      (productUpdateResponse) => {
        this.notificationService.success(
          '',
          'Product(s) successfully updated to broker firm.'
        );
        this.router.navigate([`/mga/broker-firms`]);
      },
      (err) => {
        this.notificationService.error(
          'Failed to update Products!',
          this.errorHandlingService.getErrors(err)
        );
      }
    );

    return;
  }
}
