import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// components
import { DeleteDialogComponent } from '../../../shared/components/delete-dialog/delete-dialog.component';
// services
import {
  ProductService,
  StoreService,
  UserService,
  NotificationService,
  ErrorHandlingService,
} from '../../../core/services';
// models
import { Store } from '../../../core/models';
@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss'],
})
export class StoreInfoComponent implements OnInit {
  storeInfo: Store;
  storeId: number;
  displayedColumns = [
    'name',
    'product_number',
    'region',
    'rating_table',
    'valid_from',
    'valid_to',
    'actions',
  ];

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private storeService: StoreService,
    private productService: ProductService,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.storeId = parseInt(
      this.activatedRoute.snapshot?.paramMap?.get('id'),
      10
    );
    this.fetchStoreInfo();
  }

  fetchStoreInfo(): void {
    this.storeService.getInfo(this.storeId,null).subscribe(
      (response) => {
        this.storeInfo = response;
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while store info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  handleStoreManagerDelete(result): void {
    this.userService
      .deleteStoreManager(result.storeManagerId, result.userId)
      .subscribe(
        (response) => {
          this.fetchStoreInfo();
        },
        (error) => {
          this.notificationService.error(
            'Error occurred while deleting store manager',
            this.errorHandlingService.getErrors(error)
          );
        }
      );
  }

  openDeleteDialog(data): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'are you sure you want to delete this use?',
        result: data.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.productService.delete(result).subscribe((res) => {
          this.notificationService.success(
            'Product has been Deleted',
            data.name
          );
          this.fetchStoreInfo();
        });
      }
    });
  }
}
