import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-products-list-table',
  templateUrl: './products-list-table.component.html',
  styleUrls: ['./products-list-table.component.scss'],
})
export class ProductsListTableComponent implements OnInit {
  @Input() products: Array<any>;
  @Input() displayAllColumns: boolean;
  @Output() buyProduct = new EventEmitter<any>();

  public product: any;

  constructor() {}

  ngOnInit(): void {}

  buy(product): void {
    this.buyProduct.emit(product);
  }
}
