import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  ClientService,
  NotificationService,
  ErrorHandlingService,
  ProfileService,
} from 'src/app/core/services';
@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss'],
})
export class ClientCreateComponent implements OnInit {
  @Output() isClientCreate = new EventEmitter<any>();
  @Output() clientData = new EventEmitter<any>();
  @Input() isBrokerClient: boolean;

  @Input() questions;
  @Output() clientProfileInfo = new EventEmitter<any>();
  @Output() hasProduct = new EventEmitter<any>();
  @Output() profileFlag = new EventEmitter<any>();
  @Output() updatedQuestionList = new EventEmitter<any>();
  @Output() selectedClient = new EventEmitter<any>();
  y;
  public selectedClientData: object = {};
  public currentUserInfo: any;
  public clientList: any[] = [];
  public responseData: any;
  clientCreateForm: FormGroup;
  isRegisterButtonDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private profileService: ProfileService
  ) {
    this.clientCreateForm = this.formBuilder.group({
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {}

  clientSubmitForm(): void {
    if (this.isBrokerClient) {
      this.brokerClientCreate();
    } else {
      this.clientCreate();
    }
  }

  clientCreate(): void {
    if (this.clientCreateForm.valid) {
      this.isRegisterButtonDisabled = true;
      this.clientService.createClient(this.clientCreateForm.value).subscribe(
        (response) => {
          this.selectedClientData['user'] = response;
          this.notificationService.success('Client created successfully', '');
          this.onApplicationCreate(response.id);
          this.clientData.emit(response);
        },
        (err) => {
          this.isRegisterButtonDisabled = false;
          this.notificationService.error(
            'Error occurred while submitting form',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
    }
  }

  brokerClientCreate(): void {
    if (this.clientCreateForm.valid) {
      this.clientService.createClient(this.clientCreateForm.value).subscribe(
        (res) => {
          this.clientData.emit(res);
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while creating client',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
    }
  }

  bacKToClientSearch(): void {
    this.isClientCreate.emit(false);
  }

  onApplicationCreate(userId: any): void {
    this.profileService.getInfo(userId).subscribe(
      (res) => {
        this.questions?.forEach((categoryQuestion) => {
          res?.answers?.forEach((profileQuestion) => {
            if (categoryQuestion?.id === profileQuestion.question_id) {
              categoryQuestion['answer'] = profileQuestion.answer;
              categoryQuestion['question_id'] = profileQuestion?.question_id;

              if (profileQuestion?.options) {
                categoryQuestion['options'] = profileQuestion?.options;
              }
            }
          });
        });
        if (res?.user_id) {
          this.selectedClientData['user_id'] = res?.user_id;
        } else {
          this.selectedClientData['user_id'] = '';
        }
        if (res?.id) {
          this.selectedClientData['profile_id'] = res?.id;
        }
        this.clientProfileInfo.emit(res);
        this.selectedClient.emit(this.selectedClientData);
        this.updatedQuestionList.emit(this.questions);
        this.profileFlag.emit(true);
      },
      (err) => {}
    );
  }
}
