import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';

@Injectable()
export class EftPaymentService {
  constructor(private https: HttpClient, private apiService: ApiService) {}

  // generateEmbedUrl(data: FormData): Observable<any> {
  //   return this.https
  //     .post(
  //       `https://earthnode-dev.vopay.com/api/v2/iq11/generate-embed-url`,
  //       data,
  //       Object.assign(
  //         {},
  //         {
  //           headers: new HttpHeaders({
  //             'Content-Type': 'multipart/form-data',
  //             'Access-Control-Allow-Origin': '*',
  //             'Access-Control-Request-Method': 'POST',
  //           }),
  //         }
  //       )
  //     )
  //     .pipe(map((res) => res));
  // }

  // let param: any = {'userId': 2};
  generateEmbedUrl(id: any): Observable<any> {
    return this.apiService.get(`/vopay/generate-url?id=${id}`);
  }

  getPostManCode(): void {
    const formdata = new FormData();
    formdata.append('AccountID', 'wealthtab');
    formdata.append('Key', '7f8958db73377704038f4ba2cf465203d6702e1f');
    formdata.append('Signature', 'ed5e13fb092ddf1563192d9300981a1d91a575f5');
    formdata.append(
      'RedirectURL',
      'http://localhost:4200/client/application/payment'
    );

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    fetch('https://earthnode-dev.vopay.com/api/v2/iq11/generate-embed-url', {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    })
      .then((response) => response.text())
      .then((result) => result)
      .catch((error) => {});
  }

  postEtfPayment(data): Observable<any> {
    return this.apiService.post(`/vopay`, data);
  }
}
