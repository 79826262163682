import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import {
  InsurancePolicyService,
  MediaService,
  NotificationService,
} from 'src/app/core/services';
import { ApplicationService } from 'src/app/core/services/application.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  @Input() transactionData: any;

  private id;
  private file;
  private applicationData;

  constructor(
    private applicationService: ApplicationService,
    private mediaService: MediaService,
    private insurancePolicyService: InsurancePolicyService,
    private notificationService: NotificationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });
    this.fetchApplicationInfo();
    this.fetchWordingTemplate();
  }

  fetchWordingTemplate(): void {
    const data = {
      insurance_products_id: this.id,
    };
  }

  fetchApplicationInfo(): void {
    this.applicationService.getInfo(this.id).subscribe(
      (res) => {
        this.applicationData = res;
      },
      (err) => {}
    );
  }
}
