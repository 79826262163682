import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// rxjs
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { Application } from '../models';
import { BusinessService } from './business.service';
import { AddressService } from './address.service';
@Injectable()
export class ApplicationService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private businseeService: BusinessService,
    private addressService: AddressService
  ) {}

  getList(): Observable<Application[]> {
    return this.apiService.get(`/application`).pipe(map((res) => res.data));
  }

  getStatusList(): Observable<any[]> {
    return this.apiService
      .get(`/application-status`)
      .pipe(map((res) => res.data));
  }

  getInfo(applicationId: number): Observable<Application> {
    return this.apiService
      .get(`/application/${applicationId}`)
      .pipe(map((res) => res.data));
  }

  create(data = {}): Observable<Application> {
    return this.apiService
      .post(`/application`, data)
      .pipe(map((res) => res.data));
  }

  quoteApplicationCreate(data = {}): Observable<any> {
    return this.apiService
      .post(`/application/register`, data)
      .pipe(map((res) => res.data));
  }

  update(applicationId: number, data = {}): Observable<Application> {
    return this.apiService
      .put(`/application/${applicationId}`, data)
      .pipe(map((res) => res.data));
  }

  delete(applicationId: number): Observable<any> {
    return this.apiService.delete(`/application/${applicationId}`);
  }

  deleteCustom(data = {}): Observable<any> {
    return this.apiService.post(`/application/deleteCustom`,data);
  }

  searchApplicationByBrokerId(
    brokerId: string,
    paramstr
  ): Observable<Application[]> {
    return this.apiService
      .get(
        `/application/search?broker_id=${brokerId}&requiredparms=${paramstr}&ob=id&sb=desc`
      )
      .pipe(map((res) => res.data));
  }

  searchApplicationByMgaFirmId(mgaFirmId: any): Observable<any> {
    // Not used so far
    return this.apiService
      .get(
        `/application/search?quotes\\.insuranceProduct\\.mga_firm_id=${mgaFirmId}`
      )
      .pipe(map((res) => res.data));
  }

  createBrokerApplication(
    data: any,
    client_id: any,
    broker_id: any,
    mediasData: any
  ): Observable<any> {
    return this.addressService
      .create({
        street_address: data.street_address,
        city: data.city,
        zip_code: data.zip_code,
        country_id: 1,
      })
      .pipe(
        switchMap((addressCreateResponse) => {
          return this.businseeService.create({
            name: data.company_name,
            business_number: data.business_number,
            inception_date: data.inception_date.format('YYYY-MM-DD'),
            number_of_employees: data.number_of_employees,
            projected_revenue: parseInt(
              data.projected_revenue.substring(1).replace(',', '')
            ),
            business_address_id: addressCreateResponse.id,
            owner_id: client_id,
            industry_id: data.industry_id,
            pending_lawsuit: data.pending_lawsuit,
            medias: [],
          });
        }),
        switchMap((businessCreateResponse) => {
          return this.create({
            broker_id: broker_id,
            application_status_id: 7,
            client_id: client_id,
            business_id: businessCreateResponse.id,
            medias: mediasData,
            submitted_to_mga_firm: true,
            insurance_products: [],
          });
        })
      );
  }

  changeApplicationStatus(data: any): Observable<any> {
    return this.apiService
      .post(`/application/statusUpdate`, data)
      .pipe(map((res) => res.data));
  }
}
