import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// services
import {
  ClientService,
  ErrorHandlingService,
  NotificationService,
} from 'src/app/core/services';

@Component({
  selector: 'app-client-update',
  templateUrl: './client-update.component.html',
  styleUrls: ['./client-update.component.scss'],
})
export class ClientUpdateComponent implements OnInit {
  clientId: any;
  clientInfo: any;
  clientForm: FormGroup;

  constructor(
    private clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.clientId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.fetchClientInfo();
  }

  fetchClientInfo(): void {
    this.clientService.getInfo(this.clientId).subscribe(
      (response) => {
        this.clientInfo = response;
        this.createClientFormBuilder();
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching client info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
  createClientFormBuilder(): void {
    this.clientForm = this.formBuilder.group({
      first_name: [
        this.clientInfo?.user?.first_name,
        Validators.compose([Validators.required]),
      ],
      last_name: [
        this.clientInfo?.user?.last_name,
        Validators.compose([Validators.required]),
      ],
      email: [
        this.clientInfo?.user?.email,
        Validators.compose([Validators.required]),
      ],
    });
  }

  saveClientForm(): void {
    // console.log('update form', this.clientForm.value);
    // this.userService.update(this.clientInfo?.user?.id,{}).subscribe((response) =>{
    //   console.log('response', response);
    // }, (err) => {
    //   console.log('err', err);
    // })
  }
}
