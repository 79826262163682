import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Product } from 'src/app/core/models';
import {MatDialog} from '@angular/material/dialog';
// service
import {
  RatingTableService,
  ProductService,
  UserService,
  NotificationService,
  ErrorHandlingService,
  EmailService,
  ApplicationService,
} from 'src/app/core/services';
@Component({
  selector: 'app-quote-policy',
  templateUrl: './quote-policy.component.html',
  styleUrls: ['./quote-policy.component.scss'],
})
export class QuotePolicyComponent implements OnInit {
  @Input() selectedCategories: any[];
  @Input() userInfo: any;
  @Input() businessInfo: any;
  @Input() applicationInfo: any;
  @Input() clientInfo: any;
  @Input() ratingVariableId: any;
  @Input() ilfValue: any;
  @Input() selectedRetantionClass: any;
  @Input() selectedLiabilityLimit: any;
  @Output() moveBack = new EventEmitter<any>();
  @Output() moveNext = new EventEmitter<any>();
  @Output() selectProduct = new EventEmitter<any[]>();
  productPriceWithIlf: any;
  productsList: Product[];
  currentBrokerId: any;
  currentBrokerFirmId: any;
  selectedRatingTable: any;
  productPriceList: any[] = [];
  professionalLiabilityProductList: any[] = [];
  cyberInsuranceProductList: any[] = [];
  selectedQuote: any[] = [];
  isQuoteSelected = false;
  firstProduct: any;
  secondProduct: any;
  brokerProductList: any[] = [];
  brokerProductData: any[] = [];
  mediaIds: any[] = [];
  pending_law_suits_mediaIds: any[] = [];
  retantion = [
    { id: 'r1', name: '500' },
    { id: 'r2', name: '750' },
    { id: 'r3', name: '1000' },
    { id: 'r4', name: '1500' },
    { id: 'r5', name: '2500' },
    { id: 'r6', name: '5000' },
    { id: 'r7', name: '7500' },
  ];
  selectedRetention: any;
  productIdList: any;
  constructor(
    private ratingTableService: RatingTableService,
    private productService: ProductService,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private emailService: EmailService,
    private applicationService: ApplicationService,
    public dialog: MatDialog
  ) {}

  openDialog() {
    this.dialog.open(DialogElementsExampleDialog);
  }

  ngOnInit(): void {
    const user = this.userService.getCurrentUser();
    this.currentBrokerId = user.userable_id;
    this.currentBrokerFirmId = user.userable?.broker_firm_id;
    // this.fetchProductsBBrokerId();
    this.getProductByCategories();
    this.applicationInfo?.medias?.map((item) => {
      this.mediaIds.push(item.id);
    });
    this.applicationInfo?.pending_lawsuit_medias?.map((item) => {
      this.pending_law_suits_mediaIds.push(item.id);
    });
    this.retantion.forEach((item) => {
      if (item.id === this.selectedRetantionClass) {
        this.selectedRetention = item.name;
      }
    });
  }

  fetchProductsBBrokerId(): void {
    this.productService
      // .searchProductsByBrokerId(this.currentBrokerId, '')
      .searchProductsByBrokerFirmId(this.currentBrokerFirmId, '')
      .subscribe(
        (res) => {
          this.brokerProductList = res;
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching products',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  getProductByCategories(): void {
    this.productService
      // .searchProductsByBrokerId(this.currentBrokerId, '')
      .searchProductsByBrokerFirmId(this.currentBrokerFirmId, 'insurance_product_category')
      .subscribe((response) => {
        if (
          this.ratingVariableId?.rating_selections[0]?.rating_table_id === 1 &&
          this.ratingVariableId?.rating_selections[1]?.rating_table_id === 2
        ) {
          // this.productsList = response.flat();
          const professionalLiabilityProductData = response.filter(
           // (item) => item.rating_table.id === 1
            (item) => item.rating_table_id === 1
           
          );
          this.professionalLiabilityProductList = professionalLiabilityProductData;

          const cyberInsuranceProductData = response.filter(
           // (item) => item.rating_table.id === 2
           (item) => item.rating_table_id === 2
          );
          this.cyberInsuranceProductList = cyberInsuranceProductData;
          this.brokerProductData = response;
          this.updateProductsWithPrice([
            ...this.professionalLiabilityProductList,
            ...this.cyberInsuranceProductList,
          ]);
        } else if (
          this.ratingVariableId?.rating_selections[0]?.rating_table_id === 1
        ) {
         // const data = response.filter((item) => item.rating_table.id === 1);
         const data = response.filter((item) => item.rating_table_id === 1);
          this.brokerProductData = data;
          this.professionalLiabilityProductList = data;
          this.updateProductsWithPrice(this.brokerProductData);
        } else if (
          this.ratingVariableId?.rating_selections[0]?.rating_table_id === 2
        ) {
          const cyberInsuranceProduct = response.filter(
            // (item) => item.rating_table.id === 2
            (item) => item.rating_table_id === 2
          );
          this.brokerProductData = cyberInsuranceProduct;
          // this.productsList = response[1];
          this.cyberInsuranceProductList = cyberInsuranceProduct;
          this.updateProductsWithPrice(this.brokerProductData);
        }
        // this.productsList = response.flat();
        // this.updateProductsWithPrice(this.productsList);
      });

    // retrieving products by list of selected categories
    // if (this.ratingVariableId.rating_selections[0].id)
    // this.productService
    //   .searchByCategoryIds(this.selectedCategories.map((item) => item.id))
    //   .subscribe((response) => {
    //     if (
    //       this.ratingVariableId?.rating_selections[0]?.rating_table_id === 1 &&
    //       this.ratingVariableId?.rating_selections[1]?.rating_table_id === 2
    //     ) {
    //       this.productsList = response.flat();
    //       this.professionalLiabilityProductList = response[0];
    //       this.cyberInsuranceProductList = response[1];
    //       this.updateProductsWithPrice(this.productsList);
    //     } else if (
    //       this.ratingVariableId?.rating_selections[0]?.rating_table_id === 1
    //     ) {
    //       console.log('broker prodcut list >>>', this.brokerProductList);
    //       const data = this.brokerProductList.filter(
    //         (item) => item.rating_table.id === 1
    //       );
    //       console.log('rating table on condition', data);
    //       this.productsList = response[0];
    //       this.professionalLiabilityProductList = response[0];
    //       this.updateProductsWithPrice(this.productsList);
    //     } else if (
    //       this.ratingVariableId?.rating_selections[0]?.rating_table_id === 2 &&
    //       response.length === 2
    //     ) {
    //       this.productsList = response[1];
    //       this.cyberInsuranceProductList = response[1];
    //       this.updateProductsWithPrice(this.productsList);
    //     } else if (
    //       this.ratingVariableId?.rating_selections[0]?.rating_table_id === 2 &&
    //       response.length === 1
    //     ) {
    //       this.productsList = response[0];
    //       this.cyberInsuranceProductList = response[0];
    //       this.updateProductsWithPrice(this.productsList);
    //     }
    //     // this.productsList = response.flat();
    //     // this.updateProductsWithPrice(this.productsList);
    //   });
  }

  /**
   * Updating list of products with prices from rating tables
   * @param productsList any[]
   */
  updateProductsWithPrice(data): void {
    let responseCount = 0;
    // console.log(
    //   'variable 2',
    //   new Date().getFullYear() -
    //     new Date(this.businessInfo?.inception_date).getFullYear()
    // );
    // console.log('variable 3', this.businessInfo?.number_of_employees);
    // console.log('variable 4', this.businessInfo?.projected_revenue);
    const observerList = [];
    let count = 0;
    data.forEach((item) => {
      count = count + 1;
      const yearsInOperation =
        new Date().getFullYear() -
        new Date(this.businessInfo?.inception_date).getFullYear();

      if (
        this.ratingVariableId?.rating_selections[0]?.rating_table_id === 1 &&
        this.ratingVariableId?.rating_selections[1]?.rating_table_id === 2
      ) {
        if (
          this.ratingVariableId.rating_selections[0].rating_table_id === 1 &&
          this.professionalLiabilityProductList.length >= count
        ) {
          this.selectedRatingTable = {
            1: this.ratingVariableId.rating_selections[0].id,
            2: yearsInOperation,
            3: this.businessInfo?.number_of_employees,
            4: this.businessInfo?.projected_revenue,
          };

          const postData = {
            insurance_product_id: item.id,
            client_id: this.clientInfo?.userable_id,
            application_id: this.applicationInfo.id,
            rating_variables: this.selectedRatingTable,
          };
          observerList.push(
            this.ratingTableService.searchRatingVariableRow(
              item.rating_table_id,
              postData
            )
          );
        } else if (
          this.ratingVariableId?.rating_selections[1]?.rating_table_id === 2
        ) {
          this.selectedRatingTable = {
            5: this.ratingVariableId.rating_selections[1].id,
            6: this.businessInfo?.projected_revenue,
            7: this.selectedRetantionClass,
            8: this.selectedLiabilityLimit,
          };
          const postData = {
            insurance_product_id: item.id,
            client_id: this.clientInfo?.userable_id,
            application_id: this.applicationInfo.id,
            rating_variables: this.selectedRatingTable,
          };
          observerList.push(
            this.ratingTableService.searchRatingVariableRow(
              item.rating_table_id,
              postData
            )
          );
        }
      } else if (
        this.ratingVariableId?.rating_selections[0]?.rating_table_id === 1
      ) {
        this.selectedRatingTable = {
          1: this.ratingVariableId.rating_selections[0].id,
          2: yearsInOperation,
          3: this.businessInfo?.number_of_employees,
          4: this.businessInfo?.projected_revenue,
        };

        const postData = {
          insurance_product_id: item.id,
          client_id: this.clientInfo?.userable_id,
          application_id: this.applicationInfo.id,
          rating_variables: this.selectedRatingTable,
        };
        observerList.push(
          this.ratingTableService.searchRatingVariableRow(
            item.rating_table_id,
            postData
          )
        );
      } else if (
        this.ratingVariableId?.rating_selections[0]?.rating_table_id === 2
      ) {
        this.selectedRatingTable = {
          5: this.ratingVariableId.rating_selections[0].id,
          6: this.businessInfo?.projected_revenue,
          7: this.selectedRetantionClass,
          8: this.selectedLiabilityLimit,
        };

        const postData = {
          insurance_product_id: item.id,
          client_id: this.clientInfo?.userable_id,
          application_id: this.applicationInfo.id,
          rating_variables: this.selectedRatingTable,
        };
        observerList.push(
          this.ratingTableService.searchRatingVariableRow(
            item.rating_table_id,
            postData
          )
        );
      } else if (
        this.ratingVariableId?.rating_selections[1]?.rating_table_id === 2
      ) {
        this.selectedRatingTable = {
          5: this.ratingVariableId.rating_selections[1].id,
          6: this.businessInfo?.projected_revenue,
          7: this.selectedRetantionClass,
          8: this.selectedLiabilityLimit,
        };

        const postData = {
          insurance_product_id: item.id,
          client_id: this.clientInfo?.userable_id,
          application_id: this.applicationInfo.id,
          rating_variables: this.selectedRatingTable,
        };
        const row = this.ratingTableService.searchRatingVariableRow(
                      item.rating_table_id,
                      postData
                    );

        //console.log(row);
        if(row){
          observerList.push(
            row
          );
        }
        
      }
      // this.selectedRatingTable = {
      //   1: this.ratingVariableId,
      //   2: yearsInOperation,
      //   3: this.businessInfo?.number_of_employees,
      //   4: this.businessInfo?.projected_revenue,
      // };
      // console.log(
      //   'checking selected rating table data',
      //   this.selectedRatingTable
      // );
      // const postData = {
      //   insurance_product_id: item.id,
      //   client_id: this.clientInfo?.userable_id,
      //   application_id: this.applicationInfo.id,
      //   rating_variables: this.selectedRatingTable,
      // };
      // observerList.push(
      //   this.ratingTableService.searchRatingVariableRow(
      //     item.rating_table_id,
      //     postData
      //   )
      // );
      // console.log('observerlist', observerList);
    });
    forkJoin(observerList).subscribe((response : any) => {
      this.productsList = this.brokerProductData.map((product) => {
        responseCount = responseCount + 1;
        let price = 0;
        let isError = false;
        for (var i = 0, len = response.length; i < len; i++) {
          if(response[i].success == false){
            isError = true;
          } else{
            isError = false;
          }
          
        }
        if(isError){
          //clear apps
          const postData = {
            application_id: this.applicationInfo.id,
          };
          this.applicationService.deleteCustom(postData).subscribe(
            (res) => {
              //console.log(res);
                    },
            (err) => {}
          );
          alert('No quote available for this product(s)');
          //this.openDialog();
          //this.dialog.open(DialogElementsExampleDialog);
          window.location.href = window.location.origin + '/' + 'broker/applications';
        }
        const ratingVariableRow: any[] = response.filter(
          (item: any) => item.insurance_product_id === product.id
        );

        const priceValues = ratingVariableRow[0].quote_coverage_variables.map(
          (item) => item.frozen_price
        );

        price = priceValues.reduce((acc, cv) => acc + cv);
        // final premium calculation for Professional Liability
        const basePremium = price * this.ilfValue;
        const tax = 0.13;
        const brokerageCharge = 0.15;
        const riskMargin = 0.05;
        const fixedExpense = 200.0;
        const FinalPremium =
          (fixedExpense + basePremium * (1 + riskMargin)) /
          (1 - tax - brokerageCharge);

        const product_price_with_ilf = FinalPremium;

        this.productPriceWithIlf = product_price_with_ilf;
        if (product?.rating_table_id === 1) {
          product['price'] = product_price_with_ilf;
          product['quote_id'] = ratingVariableRow[0].id;
          product['quote_status'] = ratingVariableRow[0].quote_status;
          this.productPriceWithIlf = product_price_with_ilf;
          this.productPriceList.push(product_price_with_ilf);
          return {
            ...product,
            product_price_with_ilf,
            quote_id: ratingVariableRow[0].id,
            quote_status: ratingVariableRow[0].quote_status,
          };
        } else if (product?.rating_table_id === 2) {
          // final premium calculation for Cyber
          const basePremiumCyber = price;
          const FinalPremiumRating2 =
            (fixedExpense + basePremiumCyber * (1 + riskMargin)) /
            (1 - tax - brokerageCharge);

          this.productPriceWithIlf = FinalPremiumRating2;
          product['price'] = FinalPremiumRating2;
          product['quote_id'] = ratingVariableRow[0].id;
          product['quote_status'] = ratingVariableRow[0].quote_status;
          this.productPriceList.push(FinalPremiumRating2);

          return {
            ...product,
            FinalPremiumRating2,
            quote_id: ratingVariableRow[0].id,
            quote_status: ratingVariableRow[0].quote_status,
          };
        }
      });
    });
  }

  moveBackToApplications(): void {
    this.moveBack.emit(null);
  }

  moveToPayment(): void {
    this.moveNext.emit();
  }

  selectProductToBuy(product): void {
    this.firstProduct = product;
    this.isQuoteSelected = true;
  }

  selectSecondProduct(prodcut): void {
    this.secondProduct = prodcut;
    this.isQuoteSelected = true;
  }

  goToNextPage(): void {
    if (this.firstProduct && this.secondProduct) {
      this.selectedQuote.push(this.firstProduct);
      this.selectedQuote.push(this.secondProduct);
    } else if (this.firstProduct) {
      this.selectedQuote.push(this.firstProduct);
    } else if (this.secondProduct) {
      this.selectedQuote.push(this.secondProduct);
    }
    this.selectProduct.emit(this.selectedQuote);
    this.productIdList = this.selectedQuote.map((data) => {
      return data.id;
    });
    this.updateApplicationWithSelectedProduct();
  }

  updateApplicationWithSelectedProduct(): void {
    this.selectedQuote.forEach((element) => {
      // need to loop prodculist and get procutlist ids and pass while updating applications
      this.emailService
        .sendQuoteSummaryEmail({
          client_email: this.userInfo.email,
          quote_id: element?.quote_id,
          quote_status: element?.quote_status?.status,
          quote_amount: element?.price,
          product_id: element?.id,
        })
        .pipe(
          switchMap(() => {
            return this.applicationService.update(this.applicationInfo?.id, {
              broker_id: this.applicationInfo?.broker?.userable_id,
              application_status_id: this.applicationInfo
                ?.application_status_id,
              client_id: this.applicationInfo?.client?.userable_id,
              business_id: this.applicationInfo?.business_id,
              submitted_to_mga_firm: true,
              medias: this.mediaIds,
              pending_lawsuit_medias: this.pending_law_suits_mediaIds,
              insurance_products: this.productIdList,
              premium_amount: element.price,
              deductible_amount: this.selectedRetention,
            });
          })
        )
        .subscribe(
          (updatedApplication) => {
            this.moveToPayment();
          },
          (error) => {
            this.notificationService.error(
              '',
              this.errorHandlingService.getErrors(error)
            );
          }
        );
    });
  }
}

@Component({
  selector: 'dialog',
  templateUrl: 'dialog.html',
})
export class DialogElementsExampleDialog {}

