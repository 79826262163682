import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
// services
import {
  FormValidatorService,
  MediaService,
  RatingTableService,
} from 'src/app/core/services';

@Component({
  selector: 'app-rating-table-form',
  templateUrl: './rating-table-form.component.html',
  styleUrls: ['./rating-table-form.component.scss'],
})
export class RatingTableFormComponent implements OnInit {
  ratingTableForm: FormGroup;
  selectedProductCategoryId: any;
  bluePrints: File[] = [];

  constructor(
    public dialogRef: MatDialogRef<RatingTableFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private ratingTableService: RatingTableService,
    private formValidationService: FormValidatorService,
    private mediaService: MediaService
  ) {
    this.ratingTableForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      insurance_product_category_id: [
        '',
        Validators.compose([Validators.required]),
      ],
    });
  }

  ngOnInit(): void {
    this.selectedProductCategoryId = this.data.selectedCategoryId;
  }

  onSelectBluePrint(event): void {
    this.bluePrints.push(...event.addedFiles);
  }

  onRemoveBluePrint(file): void {
    this.bluePrints.splice(this.bluePrints.indexOf(file), 1);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  createRatingsTable(): void {
    if (this.ratingTableForm.valid) {
      this.mediaService
        .createMany(this.bluePrints)
        .pipe(
          switchMap((response) => {
            return this.ratingTableService.create({
              ...this.ratingTableForm.value,
              mga_firm_id: this.data.mga_firm_id,
              blueprint_id: response.data[0].id,
            });
          })
        )
        .subscribe((response) => {
          this.dialogRef.close(true);
        });
    } else {
      this.formValidationService.validateAllFormFields(this.ratingTableForm);
    }
  }
}
