import { Component, OnInit } from '@angular/core';
import {
  ClientService,
  UserService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit {
  isClientCreated = true;
  currentUser: any;
  newClientInformation: any;
  public clientList: any;
  public displayedColumns = [
    'name',
    'email',
    'applications',
    'created',
    'application_created',
  ];

  constructor(
    private clientService: ClientService,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.fetchClientsByBrokerId();
  }

  fetchClientsByBrokerId(): void {
    this.clientService
      .searchByBrokerId(this.currentUser?.userable_id, 'user,applications')
      .subscribe(
        (response) => {
          this.clientList = response;
        },
        (err) => {
          this.notificationService.error(
            'Error while fetching clients by broker id',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  onClientCreate(): void {
    this.isClientCreated = false;
  }
  backToClientList(): void {
    this.isClientCreated = true;
  }

  clientInformation(data: any): void {
    this.newClientInformation = data;
    this.fetchClientsByBrokerId();
    this.notificationService.success(
      '',
      'Client has been created successfully'
    );
    this.isClientCreated = true;
  }
}
