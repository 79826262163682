import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// components
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
@Component({
  selector: 'app-store-managers-list',
  templateUrl: './store-managers-list.component.html',
  styleUrls: ['./store-managers-list.component.scss'],
})
export class StoreManagersListComponent implements OnInit {
  @Input() storeManagers: any[];
  @Output() deleteStoreManager = new EventEmitter<string>();

  displayedColumns = ['full_name', 'email', 'owner_status', 'actions'];

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDeleteDialog(storeManagerId: string, userId: number): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'Are you sure you want to delete this Store Manager ?',
        result: { storeManagerId, userId },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteStoreManager.emit(result);
      }
    });
  }
}
