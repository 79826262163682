import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// services
import {
  AddressService,
  FirmService,
  FormValidatorService,
  MediaService,
  StoreService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
// models
import { Store } from 'src/app/core/models';
// external
import { ImagePickerConf } from 'ngp-image-picker';
import {
  Location,
  Appearance,
} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-store-update',
  templateUrl: './store-update.component.html',
  styleUrls: ['./store-update.component.scss'],
})
export class StoreUpdateComponent implements OnInit {
  storeUpdateForm: FormGroup;
  storeId: number;
  storeInfo: Store;
  storeLogo: File;
  storeCover: File;
  buttonFlag = false;
  // image picker config
  imagePickerConfig: ImagePickerConf = {
    borderRadius: '4px',
    language: 'en',
    width: '100%',
    height: '170px',
  };
  // google maps
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  // supplied id
  storeLogoId: string;
  storeCoverId: string;
  preselectedAddress: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storeService: StoreService,
    private formValidatorService: FormValidatorService,
    private addressService: AddressService,
    private mediaService: MediaService,
    private firmService: FirmService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.storeId = parseInt(
      this.activatedRoute.snapshot?.paramMap?.get('id'),
      10
    );
    this.fetchStoreInfo();
  }
  fetchStoreInfo(): void {
    this.storeService.getInfo(this.storeId,null).subscribe(
      (response) => {
        this.storeInfo = response;
        this.preselectedAddress =
          this.storeInfo?.mga_firm?.firm?.address?.street_address +
          ', ' +
          this.storeInfo?.mga_firm?.firm?.address?.city +
          ', ' +
          this.storeInfo?.mga_firm?.firm?.address?.zip_code;
        this.createStoreFormGroup();
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while store info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  createStoreFormGroup(): void {
    this.storeUpdateForm = this.formBuilder.group({
      store_name: [
        this.storeInfo?.name,
        Validators.compose([Validators.required]),
      ],
      firm_name: [
        this.storeInfo?.mga_firm?.firm?.name,
        Validators.compose([Validators.required]),
      ],
      firm_address: [
        this.storeInfo?.mga_firm?.firm?.address?.street_address,
        Validators.compose([Validators.required]),
      ],
      city: [''],
      postal_code: [''],
    });
  }

  onAutocompleteSelected(result: PlaceResult): void {
    this.storeUpdateForm.get('firm_address').setValue(result.name);
    this.storeUpdateForm.get('city').setValue(this.getCityFromResult(result));
    this.storeUpdateForm
      .get('postal_code')
      .setValue(this.getPostalCodeFromResult(result));
  }

  onLocationSelected(location: Location): void {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  getCityFromResult(result: PlaceResult): string {
    const cities = result.address_components.filter((item) => {
      if (item.types.includes('locality')) {
        return item.long_name;
      }
    });
    return cities.length > 0 ? cities[0].long_name : 'N/A';
  }

  getPostalCodeFromResult(result: PlaceResult): string {
    const postalCodes = result.address_components.filter((item) => {
      if (item.types.includes('postal_code')) {
        return true;
      }
    });
    return postalCodes.length > 0 ? postalCodes[0].long_name : 'N/A';
  }

  onLogoChanged(file): void {
    this.storeLogo = file;
  }

  onCoverChanged(file): void {
    this.storeCover = file;
  }

  storeUpdateFormSubmit(): void {
    if (this.storeUpdateForm.valid) {
      this.buttonFlag = true;
      this.updateStore()
        .then((response) => {
          this.notificationService.success(
            'Store information has been updated successfully'
          );
          this.router.navigate(['/admin/stores', this.storeId]);
        })
        .catch((error) => {
          this.buttonFlag = false;
          this.notificationService.error(
            'Error occurred while submitting store update form',
            this.errorHandlingService.getErrors(error)
          );
        });
    } else {
      this.formValidatorService.validateAllFormFields(this.storeUpdateForm);
    }
  }

  async updateStore(): Promise<any> {
    const addressId = this.storeInfo?.mga_firm?.firm?.address?.id;
    try {
      const logoResponse = this.storeLogo
        ? await this.mediaService.create(this.storeLogo).toPromise()
        : null;
      const coverResponse = this.storeCover
        ? await this.mediaService.create(this.storeCover).toPromise()
        : null;
      const addressResponse = await this.addressService
        .update(addressId, {
          street_address:
            this.storeUpdateForm.get('firm_address').value ||
            this.storeInfo?.mga_firm?.firm?.address?.street_address,
          city:
            this.storeUpdateForm.get('city').value ||
            this.storeInfo?.mga_firm?.firm?.address?.city,
          zip_code:
            this.storeUpdateForm.get('postal_code').value ||
            this.storeInfo?.mga_firm?.firm?.address?.zip_code,
          country_id: 1,
        })
        .toPromise();
      const storeResponse = await this.storeService
        .update(this.storeId, {
          name: this.storeUpdateForm.get('store_name').value,
          cover_media_id: coverResponse
            ? coverResponse.id
            : this.storeInfo?.cover_media.id,
          mga_firm_id: this.storeInfo?.mga_firm_id,
        })
        .toPromise();
      const firmResponse = await this.firmService
        .update(this.storeInfo.mga_firm.firm.id, {
          name: this.storeUpdateForm.get('firm_name').value,
          address_id: addressId,
          logo_id: logoResponse
            ? logoResponse.id
            : this.storeInfo?.mga_firm?.firm?.logo.id,
          documents: [],
        })
        .toPromise();
    } catch (error) {
      this.formValidatorService.validateAllFormFields(this.storeUpdateForm);
    }
  }
}
