import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-product-info-dialog',
  templateUrl: './product-info-dialog.component.html',
  styleUrls: ['./product-info-dialog.component.scss'],
})
export class ProductInfoDialogComponent implements OnInit {
  productInfo: any;
  constructor(
    private matDialogRef: MatDialogRef<ProductInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.productInfo = this.data.insuranceProduct;
  }
  close(): void {
    this.matDialogRef.close(false);
  }
}
