import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-applications-list-table',
  templateUrl: './applications-list-table.component.html',
  styleUrls: ['./applications-list-table.component.scss'],
})
export class ApplicationsListTableComponent implements OnInit {
  @Input() applicationList: Array<any>;
  @Input() displayAllColumns: any;
  @Output() selectedApplication = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onSelecteApplication(selectApplication): void {
    this.selectedApplication.emit(selectApplication);
  }
}
