import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Product } from 'src/app/core/models';
// service
import { RatingTableService, ProductService } from 'src/app/core/services';
@Component({
  selector: 'app-quote-policy',
  templateUrl: './quote-policy.component.html',
  styleUrls: ['./quote-policy.component.scss'],
})
export class QuotePolicyComponent implements OnInit {
  @Input() selectedCategories: any[];
  @Input() clientInfo: any;
  @Input() businessInfo: any;
  @Input() applicationInfo: any;
  @Output() moveBack = new EventEmitter<any>();
  @Output() moveNext = new EventEmitter<any>();
  @Output() selectProduct = new EventEmitter<any>();

  productsList: Product[];

  constructor(
    private ratingTableService: RatingTableService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.getProductByCategories();
  }

  getProductByCategories(): void {
    // retrieving products by list of selected categories
    this.productService
      .searchByCategoryIds(this.selectedCategories.map((item) => item.id))
      .subscribe((response) => {
        this.productsList = response.flat();
        this.updateProductsWithPrice(this.productsList);
      });
  }

  /**
   * Updating list of products with prices from rating tables
   * @param productsList any[]
   */
  updateProductsWithPrice(productsList): void {
    const observerList = [];
    productsList.forEach((item) => {
      const yearsInOperation =
        new Date().getFullYear() -
        new Date(this.businessInfo?.inception_date).getFullYear();
      const postData = {
        insurance_product_id: item.id,
        client_id: this.clientInfo.userable_id,
        application_id: this.applicationInfo.id,
        rating_variables: {
          1: this.businessInfo?.industry?.id,
          2: yearsInOperation,
          3: this.businessInfo?.number_of_employees,
          4: this.businessInfo?.projected_revenue,
        },
      };
      observerList.push(
        this.ratingTableService.searchRatingVariableRow(
          item.rating_table_id,
          postData
        )
      );
    });

    forkJoin(observerList).subscribe((response) => {
      this.productsList = productsList.map((product) => {
        let price = 0;

        const ratingVariableRow: any[] = response.filter(
          (item: any) => item.insurance_product_id === product.id
        );

        const priceValues = ratingVariableRow[0].quote_coverage_variables.map(
          (item) => item.frozen_price
        );

        price = priceValues.reduce((acc, cv) => acc + cv);

        return {
          ...product,
          price,
          quote_id: ratingVariableRow[0].id,
          quote_status: ratingVariableRow[0].quote_status,
        };
      });
    });
  }

  moveBackToApplications(): void {
    this.moveBack.emit(null);
  }

  moveToPayment(): void {
    this.moveNext.emit();
  }

  selectProductToBuy(product): void {
    this.selectProduct.emit(product);
    this.moveToPayment();
  }
}
