import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// service
import {
  BrokerService,
  UserService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
// external
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-broker-information',
  templateUrl: './broker-information.component.html',
  styleUrls: ['./broker-information.component.scss'],
})
export class BrokerInformationComponent implements OnInit {
  // phoneMask = [
  //   '(',
  //   /[1-9]/,
  //   /\d/,
  //   /\d/,
  //   ')',
  //   ' ',
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   '-',
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   /\d/,
  // ];
  public currentBrokerInfo: any;
  currentUserInfo: any;
  brokerTypes: any[] = [
    { label: 'Broker', value: false },
    { label: 'Broker Admin', value: true },
  ];
  selected = 'Broker';
  brokerCreateForm: FormGroup;
  brokerFirmId: any;
  buttonFlag = false;
  userUpdatedFlag = false;
  phoneNumber: any;

  constructor(
    private formBuilder: FormBuilder,
    private brokerService: BrokerService,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.currentUserInfo = this.userService.getCurrentUser();
    this.getBrokerInfo();
  }
  updateBrokerFirm(): void {
    this.brokerCreateForm = this.formBuilder.group({
      first_name: [
        this.currentBrokerInfo?.user?.first_name,
        Validators.compose([Validators.required]),
      ],
      last_name: [
        this.currentBrokerInfo?.user?.last_name,
        Validators.compose([Validators.required]),
      ],
      phone_number: [this.phoneNumber],
      broker_type: [
        this.brokerTypes[this.currentBrokerInfo?.user?.userable?.admin].value,
      ],
    });
  }
  onUpdate(): void {
    this.userUpdatedFlag = true;
    this.buttonFlag = false;
  }
  onBackToBrokers(): void {
    this.userUpdatedFlag = false;
  }

  getBrokerInfo(): void {
    this.brokerService.getInfo(this.currentUserInfo?.userable_id).subscribe(
      (res) => {
        this.currentBrokerInfo = res;
        this.phoneNumber = this.testingPhoneNumber(
          this.currentBrokerInfo?.phone_number
        );
        this.updateBrokerFirm();
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching broker info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
  testingPhoneNumber(preSelectedPhoneNumber: any): any {
    const cleaned = ('' + preSelectedPhoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + '- ' + match[2] + '- ' + match[3];
    }
  }

  formatPhoneNumber(event: any): string {
    const cleaned = ('' + event).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      this.phoneNumber =
        '(' + match[1] + ') ' + '- ' + match[2] + '- ' + match[3];
    }
    // this.brokerCreateForm.patchValue({ phone_number: this.phoneNumber });
    return null;
  }

  submitForm(): void {
    if (this.brokerCreateForm.value) {
      this.buttonFlag = true;
      this.brokerService
        .update(this.currentUserInfo?.userable_id, {
          phone_number: this.brokerCreateForm.value.phone_number,
          broker_firm_id: this.currentUserInfo?.userable?.broker_firm_id,
          admin: this.brokerCreateForm.value.broker_type,
        })
        .pipe(
          switchMap((brokerResponse) => {
            return this.userService.update(this.currentUserInfo?.id, {
              first_name: this.brokerCreateForm.value.first_name,
              last_name: this.brokerCreateForm.value.last_name,
            });
          })
        )
        .subscribe(
          (response) => {
            this.notificationService.success(
              'Broker has been updated successfully',
              ''
            );
            this.getBrokerInfo();
            this.userUpdatedFlag = false;
          },
          (err) => {
            this.notificationService.error(
              'Error occurred while form submit',
              this.errorHandlingService.getErrors(err)
            );
          }
        );
    }
  }
}
