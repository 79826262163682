import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// service
import {
  ErrorHandlingService,
  NotificationService,
  ProductService,
} from 'src/app/core/services';

@Component({
  selector: 'app-broker-firm-product-info',
  templateUrl: './broker-firm-product-info.component.html',
  styleUrls: ['./broker-firm-product-info.component.scss'],
})
export class BrokerFirmProductInfoComponent implements OnInit {
  productId: any;
  brokerFirmId: any;
  productInfo: any;
  constructor(
    private productService: ProductService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private errorHandlingService: ErrorHandlingService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.productId = this.activatedRoute.snapshot?.paramMap?.get('productId');
    this.brokerFirmId = this.activatedRoute.snapshot?.paramMap?.get(
      'brokerFirmId'
    );
    this.getProductInfo();
  }

  getProductInfo(): void {
    this.productService.getInfo(this.productId,'NA').subscribe(
      (res) => {
        this.productInfo = res;
      },
      (err) => {
        this.notificationService.error(
          '',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
}
