import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-region-select',
  templateUrl: './region-select.component.html',
  styleUrls: ['./region-select.component.scss'],
})
export class RegionSelectComponent implements OnInit {
  @Input() selectedRegions: any;
  @Input() formControl: string;
  regions: any[];
  preSelectedRegion: any;

  constructor() {
    // this.regions;
    this.regions = [
      { id: 1, label: 'Alberta', value: 'Alberta' },
      { id: 2, label: 'British Columbia', value: 'British Columbia' },
      { id: 3, label: 'Manitoba', value: 'Manitoba' },
      { id: 4, label: 'New Brunswick', value: 'New Brunswick' },
      {
        id: 5,
        label: 'Newfoundland and Labrador',
        value: 'Newfoundland and Labrador',
      },
      { id: 6, label: 'Nova Scotia', value: 'Nova Scotia' },
      { id: 7, label: 'Ontario', value: 'Ontario' },
      { id: 8, label: 'Prince Edward Island', value: 'Prince Edward Island' },
      { id: 9, label: 'Quebec', value: 'Quebec' },
      { id: 10, label: 'Saskatchewan', value: 'Saskatchewan' },
    ];
  }

  ngOnInit(): void {
    this.preSelectedRegion = this.selectedRegions.split(',');
  }
}
