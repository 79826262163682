import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// services
import {
  PageDataService,
  FirmService,
  UserService,
  ClientService,
  QuestionService,
  NotificationService,
  ErrorHandlingService,
} from '../../core/services';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {
  selectedCategories: any[] = [];
  hasProducts = false;
  hasQuote = false;
  applicationId: number;
  isSuccess = false;
  selectedBrokerId: any;
  applicationInfo: any;
  businessInfo: any;
  clientInfo: any;
  userInfo: any;
  selectedProduct: any[] = [];
  brokerId: any;
  brokerList: any;
  filteredClientsList: any;
  clientSearchedData: any;
  clientProfileData: any;
  isClientCreate = false;
  insuranceCategories: any[] = [];
  questions: any;
  ratingVariableId: any;
  ilfValue: any;
  selectedRetantionClass: any;
  selectedLiabilityLimit: any;
  selectedClientId: any;
  preSelectedClientData: any;
  profileStatus: boolean;
  selectedClientDetails: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private pageDataService: PageDataService,
    private userService: UserService,
    private questionService: QuestionService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.pageDataService.updateStepperState('chooseProduct', {
      current: true,
      passed: false,
    });
  }

  ngOnInit(): void {
    this.selectedBrokerId = this.userService.getCurrentUser().userable_id;
    this.activatedRoute.queryParams.subscribe((params) => {
      this.selectedClientId = params?.client_id;
      if (this.selectedClientId) {
        this.fetchClientInfo();
      }
    });
  }

  fetchClientInfo(): void {
    this.clientService.getInfo(this.selectedClientId).subscribe(
      (res) => {
        //  this.clientSearchedData = res;
        this.preSelectedClientData = res;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching client info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
  onFilteredClientsList(data: any): void {
    this.filteredClientsList = data;
  }
  clientProfileInfo(data: any): void {
    // this.clientSearchedData = data[0];
    this.clientProfileData = data;
  }
  profileFlagStatus(status: any): void {
    this.profileStatus = status;
  }

  updatedQuestionList(questionList: any): void {
    this.questions = questionList;
  }
  clientCreate(): void {
    this.isClientCreate = true;
  }

  onClientCreate(data: any): void {
    this.isClientCreate = data;
  }

  clientCreated(data: any): void {
    this.clientSearchedData = data;
    this.profileStatus = true;
  }

  saveRatingVariableId(id: any): void {
    this.ratingVariableId = id;
  }

  selectedCategoriesChange(selectedCategories): void {
    this.selectedCategories = selectedCategories;
  }
  saveSelectedRetantion(selectedRatantion: any): void {
    this.selectedRetantionClass = selectedRatantion;
  }

  saveSelectedLiabilityLimit(selectedLimit: any): void {
    this.selectedLiabilityLimit = selectedLimit;
  }

  fetchQuestionsByCategoriesId(data: any): void {
    this.questionService.questionsByCattegoryId(data).subscribe(
      (response) => {
        this.questions = response;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching questions by category id',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  saveIlfData(value: any): void {
    this.ilfValue = value;
  }

  hasProductSelected(value: any): void {
    this.hasProducts = value;
  }

  selectedClient(data: any): void {
    this.selectedClientDetails = data;
  }

  moveToApplicationCreate(): void {
    if (this.selectedCategories.length === 0) {
      this.notificationService.error('', 'Please Select Insurance Category');
    } else {
      this.clientSearchedData = this.preSelectedClientData;
      const data = this.selectedCategories?.map((res) => res.id);

      const obj = { insurance_product_category_id: data };
      this.fetchQuestionsByCategoriesId(obj);
      this.hasProducts = true;
    }
  }

  moveToProducts(): void {
    this.pageDataService.updateStepperState('chooseProduct', {
      current: true,
      passed: false,
    });
    this.pageDataService.updateStepperState('createApplication', {
      current: false,
      passed: false,
    });
    this.pageDataService.updateStepperState('policyDetails', {
      current: false,
      passed: false,
    });
    this.pageDataService.updateStepperState('payment', {
      current: false,
      passed: false,
    });
    this.clientSearchedData = null;
  }

  moveToQuotePolicy(applicationId): void {
    this.applicationId = applicationId;
    this.pageDataService.updateStepperState('chooseProduct', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('createApplication', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('policyDetails', {
      current: true,
      passed: false,
    });
  }

  moveToPayment(): void {
    this.hasQuote = true;
    this.pageDataService.updateStepperState('chooseProduct', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('createApplication', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('policyDetails', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('payment', {
      current: true,
      passed: false,
    });
  }

  moveToSummary(): void {
    this.isSuccess = true;
    this.pageDataService.updateStepperState('chooseProduct', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('createApplication', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('policyDetails', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('payment', {
      current: true,
      passed: true,
    });
  }

  saveApplicationInfo(applicationInfo): void {
    this.applicationInfo = applicationInfo;
  }

  saveBusinessInfo(businessInfo): void {
    this.businessInfo = businessInfo;
  }

  saveClientInfo(clientInfo): void {
    this.clientInfo = clientInfo;
  }

  saveUserInfo(userInfo): void {
    this.userInfo = userInfo;
  }

  saveSelectedProduct(product): void {
    this.selectedProduct = product;
  }
}
