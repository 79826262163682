import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// rxjs
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { BrokerFirm, MGAFirm } from '../models';

/**
 * This service contains all the services related to firm, STORE firm and broker firm
 */
@Injectable()
export class FirmService {
  constructor(private apiService: ApiService) {}

  getList(): Observable<any[]> {
    return this.apiService.get(`/firm`).pipe(map((res) => res.data));
  }

  getBrokerFirmList(paramstr): Observable<any> {
    // PRAJAL
    if (paramstr) {
      return this.apiService
        .get(`/broker-firm?requiredparms=${paramstr}`)
        .pipe(map((res) => res.data));
    } else {
      return this.apiService.get(`/broker-firm`).pipe(map((res) => res.data));
    }
  }

  getMGAFirmList(): Observable<any> {
    return this.apiService.get(`/mga-firm`).pipe(map((res) => res.data));
  }

  getInfo(firmId: string): Observable<any> {
    return this.apiService.get(`/firm/${firmId}`).pipe(map((res) => res.data));
  }

  getMgaInfo(mgaFirmId: string): Observable<any> {
    return this.apiService
      .get(`/mga-firm/${mgaFirmId}`)
      .pipe(map((res) => res.data));
  }
  getBrokerFirmInfo(brokerFirmId: string): Observable<BrokerFirm> {
    return this.apiService
      .get(`/broker-firm/${brokerFirmId}`)
      .pipe(map((res) => res.data));
  }

  getMGAFirmInfo(MGAFirmId: string): Observable<MGAFirm> {
    return this.apiService
      .get(`/mga-firm/${MGAFirmId}`)
      .pipe(map((res) => res.data));
  }

  create(data = {}): Observable<any> {
    return this.apiService.post(`/firm`, data).pipe(map((res) => res.data));
  }

  createMGAFirm(data): Observable<any> {
    return this.apiService.post(`/mga-firm`, {}).pipe(
      switchMap((mgaResponse) => {
        return this.create({
          firm_name: data.firm_name,
          address_id: data.address_id,
          logo_id: data.logo_id,
          firmable_id: mgaResponse.data.id,
          firmable_type: 'App\\Models\\Firm\\MgaFirm',
        });
      })
    );
  }

  getMgaByProductId(insuranceProduct: any): Observable<any> {
    return this.apiService
      .get(`/mga-firm/by-insurance-product/${insuranceProduct}`)
      .pipe(map((res) => res.data));
  }
  getBrokerFirmByInsuranceProduct(insuranceProduct: any): Observable<any> {
    return this.apiService
      .get(`/broker-firm/list-by-insurance-product/${insuranceProduct}`)
      .pipe(map((res) => res.data));
  }

  createBrokerFirm(data): Observable<any> {
    return this.apiService.post(`/broker-firm`, {}).pipe(
      switchMap((brokerFirmResponse) => {
        return this.create({
          name: data.name,
          date_of_incorporation: data.date_of_incorporation,
          address_id: data.address_id,
          logo_id: data.logo_id,
          firmable_id: brokerFirmResponse.data.id,
          firmable_type: 'App\\Models\\Firm\\BrokerFirm',
          documents: [],
        });
      })
    );
  }

  update(firmId: number, data = {}): Observable<any> {
    return this.apiService
      .put(`/firm/${firmId}`, data)
      .pipe(map((res) => res.data));
  }

  delete(firmId: string): Observable<any> {
    return this.apiService.delete(`/firm/${firmId}`);
  }

  deleteBrokerFirm(brokerFirmId: string): Observable<any> {
    return this.apiService.delete(`/broker-firm/${brokerFirmId}`);
  }

  contactBrokerFirm(brokerFirmInfo: any): Observable<any> {
    return this.apiService.post('/broker-firm/sendemail', brokerFirmInfo);
  }
}
