import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';

@Injectable()
export class ClientService {
  constructor(private apiService: ApiService) {}

  getInfo(clientId: string): Observable<any> {
    return this.apiService
      .get(`/client/${clientId}`)
      .pipe(map((res) => res.data));
  }

  createClient(data: any): Observable<any> {
    return this.apiService
      .post(`/auth/register/auth/client/new`, data)
      .pipe(map((res) => res.data));
  }

  create(data: any): Observable<any> {
    return this.apiService.post(`/client`, {}).pipe(
      switchMap((clientResponse) => {
        return this.apiService.post(`/user`, {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          userable_id: clientResponse.data.id,
          userable_type: 'App\\Models\\User\\Client',
        });
      })
    );
  }

  registerClientWithAddress(
    addressData = {},
    clientData = {},
    userData = {}
  ): Observable<any> {
    return this.apiService.post(`/address`, addressData).pipe(
      switchMap((addressResponse) => {
        return this.apiService.post(`/client`, {
          ...clientData,
          personal_address_id: addressResponse.data.id,
        });
      }),
      switchMap((clientResponse) => {
        return this.apiService.post(`/user`, {
          ...userData,
          userable_id: clientResponse.data.id,
          userable_type: 'App\\Models\\User\\Client',
        });
      })
    );
  }

  update(clientId: number, data = {}): Observable<any> {
    return this.apiService
      .put(`/client/${clientId}`, data)
      .pipe(map((res) => res.data));
  }

  delete(clientId: number): Observable<any> {
    return this.apiService.delete(`/client/${clientId}`);
  }

  searchClient(email: any): any {
    return this.apiService
      .get(`/user/search?email=${email}`)
      .pipe(map((res) => res.data));
  }

  getApplicationList(clientId: any, paramstr): Observable<any> {
    // PRAJAL2
    // return this.apiService.get(`/application/search?client.id=${clientId}`);
    return this.apiService.get(
      `/application/search?client.id=${clientId}&requiredparms=${paramstr}&ob=id&sb=desc`
    );
  }

  searchClientsByBrokerId(brokerId: string): Observable<any[]> {
    return this.apiService
      .get(`/client/search?applications\\.broker_id=${brokerId}`)
      .pipe(map((res) => res.data));
  }

  searchByBrokerId(brokerId: string, paramstr): Observable<any[]> {
    return (
      this.apiService
        // .get(`/client/search?brokers\\.broker_id=${brokerId}`)
        .get(
          `/client/search?brokers\\.broker_id=${brokerId}&requiredparms=${paramstr}`
        )
        .pipe(map((res) => res.data))
    );
  }
}
