import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// shared
import { SharedModule, AccountLayoutComponent } from '../shared';
// components
import { SignInComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { SignupConfirmComponent } from './signup-confirm/signup-confirm.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { SuccessComponent } from './success/success.component';
import { SignupSuccessComponent } from './signup-success/signup-success.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const accountRouting: ModuleWithProviders<any> = RouterModule.forRoot([
  {
    path: 'account',
    component: AccountLayoutComponent,
    children: [
      { path: 'signin', component: SignInComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'signup-confirm', component: SignupConfirmComponent },
      { path: 'verify-email', component: VerifyEmailComponent },
      { path: 'success', component: SuccessComponent },
      { path: 'signup-success', component: SignupSuccessComponent },
      { path: 'create-user', component: CreateUserComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
    ],
  },
]);

@NgModule({
  declarations: [
    SignInComponent,
    SignupComponent,
    SignupConfirmComponent,
    VerifyEmailComponent,
    SuccessComponent,
    SignupSuccessComponent,
    CreateUserComponent,
    ResetPasswordComponent,
  ],
  imports: [SharedModule, accountRouting],
  exports: [CreateUserComponent],
})
export class AccountModule {}
