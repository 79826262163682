import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// external
import * as FileSaver from 'file-saver';
// components
import { ProductInfoDialogComponent } from '../../product-info-dialog/product-info-dialog.component';
// services
import {
  ApplicationService,
  QuoteService,
  NotificationService,
  ErrorHandlingService,
} from '../../../core/services';
// rxjs
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-application-info',
  templateUrl: './application-info.component.html',
  styleUrls: ['./application-info.component.scss'],
})
export class ApplicationInfoComponent implements OnInit {
  businessStartedDate: any;
  public id: any;
  public application: any;
  public productAndQuoteInfo: any;
  public applicationId: any;
  public productList: any;
  public quoteList: any[] = [];
  constructor(
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private quoteService: QuoteService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });
    this.fetchApplicationInfo();
  }

  fetchApplicationInfo(): void {
    this.applicationService.getInfo(this.id).subscribe(
      (res) => {
        this.application = res;
        this.applicationId = res.id;
        this.productList = res.insurance_products;
        this.fetchQuotes();
      },
      (err) => {}
    );
  }
  fetchQuotes(): void {
    this.productList.forEach((element) => {
      this.quoteService
        .searchQuoteByApplicationIdAndProductId(
          this.applicationId,
          element.id,
          'quote_status'
        )
        .subscribe(
          (res) => {
            this.quoteList.push(res[0]);
          },
          (err) => {}
        );
    });
  }
  // fetchApplicationInfo(): void {
  //   this.applicationService
  //     .getInfo(this.id)
  //     .pipe(
  //       switchMap((applicationInfoResponse) => {
  //         console.log('application info', applicationInfoResponse);
  //         this.application = applicationInfoResponse;
  //         return this.quoteService.searchQuoteByApplicationIdAndProductId(
  //           applicationInfoResponse.id,
  //           applicationInfoResponse.insurance_products[0].id
  //         );
  //       })
  //     )
  //     .subscribe(
  //       (response) => {
  //         this.productAndQuoteInfo = response[0];
  //         console.log(' quote base on application and product', response);
  //       },
  //       (error) => {
  //         this.notificationService.error(
  //           'Error occurred while fetching application info',
  //           this.errorHandlingService.getErrors(error)
  //         );
  //       }
  //     );
  // }

  getPdf(fileData: any): void {
    FileSaver.saveAs(fileData, `application.pdf`);
  }

  openProductInfoDialog(): void {
    const dialogRef = this.dialog.open(ProductInfoDialogComponent, {
      width: '680px',
      height: '700px',
      data: { insuranceProduct: this.application?.insurance_products[0] },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.getProductinfo(this.productId);
    });
  }

  /* openBrokertInfoDialog(): void {
    const dialogRef = this.dialog.open(BrokerInfoDialogComponent, {
      width: '680px',
      height: '700px',
      data: { brokerId: this.application?.broker_id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.getProductinfo(this.productId);
    });
  } */
}
