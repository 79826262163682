import { Component, OnInit } from '@angular/core';
// services
import {
  InsurancePolicyService,
  UserService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent implements OnInit {
  currentUserInfo: any;
  policies: any;
  displayedPolicyColumns = [
    'policy_number',
    'insurance_type',
    'Inception_date',
    'Expiration_date',
    'actions',
  ];

  constructor(
    private userService: UserService,
    private insurancePolicyService: InsurancePolicyService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.currentUserInfo = this.userService.getCurrentUser();
    this.getInsurancePolicyList();
  }

  getInsurancePolicyList(): void {
    this.insurancePolicyService
      .getPolicyListByClientId(
        this.currentUserInfo.userable_id,
        'insurance_product'
      )
      .subscribe(
        (response) => {
          this.policies = response;
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching insurance policy list',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }
}
