import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// components
import { DeleteDialogComponent } from '../../../shared/components/delete-dialog/delete-dialog.component';
// services
import {
  ClientService,
  InsurancePolicyService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
})
export class ClientInfoComponent implements OnInit {
  clientId: any;
  clientInfo: any;
  policies: any;
  displayedColumns = ['application_id', 'actions'];
  displayedPolicyColumns = [
    'policy_number',
    'insurance_type',
    'Inception_date',
    'Expiration_date',
    'actions',
  ];
  constructor(
    public dialog: MatDialog,
    private clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    private insurancePolicyService: InsurancePolicyService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.clientId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.clientService.getInfo(this.clientId).subscribe(
      (response) => {
        this.clientInfo = response;
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching client info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
    this.policyList();
  }

  openDeleteDialog(data): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'Are you sure you want to delete this policy?',
        result: data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.insurancePolicyService.delete(result).subscribe((res) => {
          this.notificationService.success(
            'Policy has been Deleted',
            data.name
          );
          this.policyList();
        });
      }
    });
  }

  policyList(): void {
    this.insurancePolicyService.getList().subscribe(
      (response) => {
        this.policies = response;
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching policy list',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
}
