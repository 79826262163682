import { Location } from '@angular/common';
import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/core/models';
// service
import {
  FirmService,
  ProductService,
  NotificationService,
  ErrorHandlingService,
  BrokerService,
  AddressService,
} from 'src/app/core/services';
import { ContactBrokerFormHomeComponent } from 'src/app/shared/components/forms/contact-broker-form-home/contact-broker-form-home.component';
@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
})
export class ProductInfoComponent implements OnInit {
  @Input() selectedProduct: any;
  productId: number;
  productInfo;
  mgaDetails: any;
  brokerFirmInfo: any;
  brokerInfo: any = [];
  addressArr: any = [];
  backUrl;
  categoryId;
  productName: any;

  public displayAllColumns = [
    'logo',
    'name',
    'address',
    'contact_name',
    'phone',
    // 'email',
    'actions',
  ];

  constructor(
    private firmService: FirmService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private route: ActivatedRoute,
    private brokerService: BrokerService,
    private dialog: MatDialog,
    private router: Router,
    private addressService: AddressService,
    private productService: ProductService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((param) => {
      this.productName = param.name;
    });
    this.route.params.subscribe((param) => {
      this.productId = param.id;
    });
    this.getProductInfo();
    this.getMgaFirmInfo();
    this.getBrokerFirmInfo();
  }

  getProductInfo(): void {
    this.productService.getInfo(this.productId,'').subscribe((response) => {
      this.categoryId = response.insurance_product_category_id;
    });
  }

  getMgaFirmInfo(): void {
    this.firmService.getMgaByProductId(this.productId).subscribe(
      (response) => {
        this.mgaDetails = response;
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching STORE firm info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  getBrokerFirmInfo(): void {
    this.firmService.getBrokerFirmByInsuranceProduct(this.productId).subscribe(
      (response) => {
        this.brokerFirmInfo = response;
        this.brokerFirmInfo.forEach((brokerFirm) => {
          this.brokerInfo.push(brokerFirm?.brokers[0]);
          /* this.brokerService
            .getInfo(brokerFirm?.brokers[0].id)
            .subscribe((res) => {
            }); */
          this.addressService
            .getInfo(brokerFirm.firm.address_id)
            .subscribe((res) => {
              this.addressArr.push(res);
            });
        });
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching broker firm info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  openContactBrokerModal(brokerFirmInfo): void {
    this.dialog.open(ContactBrokerFormHomeComponent, {
      data: { brokerFirmInfo: brokerFirmInfo, productId: this.productId },
    });
  }

  back(): void {
    this.location.back();
  }
}
