import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { Broker, BrokerFirm } from '../models';

@Injectable()
export class BrokerService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getList(): Observable<Broker[]> {
    return this.apiService.get(`/broker`).pipe(map((res) => res.data));
  }

  getBrokerFirmList(): Observable<BrokerFirm[]> {
    return this.apiService.get(`/broker-firm`).pipe(map((res) => res.data));
  }

  getBrokerFirmsByProductId(productId: number): Observable<BrokerFirm[]> {
    return this.apiService
      .get(
        //`/broker-firm/search?brokers\\.insuranceproducts\\.insurance_product_id=${productId}`
        `/broker-firm/search?insuranceproducts\\.insurance_product_id=${productId}`
      )
      .pipe(map((res) => res.data));
  }

  getInfo(brokerId: number): Observable<Broker> {
    return this.apiService
      .get(`/broker/${brokerId}`)
      .pipe(map((res) => res.data));
  }

  getBrokerFirmInfo(brokerFirmId: string): Observable<Broker> {
    return this.apiService
      .get(`/broker-firm/${brokerFirmId}`)
      .pipe(map((res) => res.data));
  }

  create(data = {}): Observable<Broker> {
    return this.apiService.post(`/broker`, data).pipe(map((res) => res.data));
  }

  update(brokerId: number, data = {}): Observable<Broker> {
    return this.apiService
      .put(`/broker/${brokerId}`, data)
      .pipe(map((res) => res.data));
  }

  updateBrokerFirm(brokerId: number, data = {}): Observable<Broker> {
    return this.apiService
      .put(`/broker/${brokerId}`, data)
      .pipe(map((res) => res.data));
  }

  delete(brokerId: number): Observable<any> {
    return this.apiService.delete(`/broker/${brokerId}`);
  }

  getListByBrokerFirmId(brokerFirmId: number): Observable<Broker[]> {
    return this.apiService
      .get(`/broker/search?brokerfirm\\.id=${brokerFirmId}`)
      .pipe(map((res) => res.data));
  }
  getBrokerListByMgaFirmId(mga_firm_id: number): Observable<Broker[]> {
    return this.apiService
      .get(`/broker/search?insuranceProducts\\.mga_firm_id=${mga_firm_id}`)
      .pipe(map((res) => res.data));
  }
}
