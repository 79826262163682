import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// service
import { ApiService, UserService } from '../../../core/services';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent implements OnInit {
  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {}

  logout(): void {
    this.userService.purgeAuth();
    this.router.navigate(['/account/signin']);
  }
}
