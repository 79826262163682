import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-quote-success',
  templateUrl: './quote-success.component.html',
  styleUrls: ['./quote-success.component.scss'],
})
export class QuoteSuccessComponent implements OnInit {
  @Input() selectedProduct: any;
  @Input() businessInfo: any;
  @Input() userInfo: any;
  @Input() applicationInfo: any;

  constructor() {}

  ngOnInit(): void {}
}
