import { Component, OnInit } from '@angular/core';
// services
import {
  FirmService,
  NotificationService,
  ErrorHandlingService,
} from '../../core/services';
import { MatDialog } from '@angular/material/dialog';
// models
import { BrokerFirm } from '../../core/models';
// router
import { Router } from '@angular/router';
// components

import { DeleteDialogComponent } from '../../shared/components/delete-dialog/delete-dialog.component';
@Component({
  selector: 'app-broker-firms',
  templateUrl: './broker-firms.component.html',
  styleUrls: ['./broker-firms.component.scss'],
})
export class BrokerFirmsComponent implements OnInit {
  displayedColumns = [
    'firm_logo',
    'firm_name',
    'firm_street_address',
    'firm_city',
    'firm_created_at',
    'view',
    'select',
  ];
  brokerFirmsList: BrokerFirm[];
  selectedBrokerFirm: any;
  isBrokerFirmSelected = false;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private firmService: FirmService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.fetchBrokerFirmList();
  }

  fetchBrokerFirmList(): void {
    this.firmService.getBrokerFirmList('firm').subscribe(
      (response) => {
        this.brokerFirmsList = response;
      },
      (error) => {
        this.notificationService.error(
          'Error while fetching broker firm list',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  openDeleteDialog(brokerFirmId: any): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'Are you sure you want to delete this brokerFirm?',
        result: brokerFirmId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.firmService.deleteBrokerFirm(result).subscribe((res) => {
          this.notificationService.success(
            'Broker firm has been deleted Successfully',
            ''
          );
          this.fetchBrokerFirmList();
        });
      }
    });
  }

  checkboxModelChange(selectedBroker: any): void {
    this.selectedBrokerFirm = selectedBroker;
    this.isBrokerFirmSelected = true;
  }
  addBrokerToProduct(): void {
    if (this.selectedBrokerFirm?.brokers?.length === 0) {
      this.notificationService.warning(
        'There are no brokers within this firm',
        'Please get the broker firm to add brokers before moving to Product Authorization'
      );
    } else {
      this.router.navigate([
        `/mga/broker-firms/`,
        this.selectedBrokerFirm.id,
        'products',
      ]);
    }
  }
}
