import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms';

@Directive({
  /* tslint:disable-next-line:directive-selector */
  selector: '[custom-email]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: CustomEmailValidator, multi: true },
  ],
})
export class CustomEmailValidator implements Validator {
  public static validate(c: FormControl): { [key: string]: any } {
    if (c.value == null) {
      return null;
    }

    /* tslint:disable-next-line:max-line-length */
    const emailPattern: RegExp = /^(([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9|-])+((\.){0,10}[A-Z|a-z|0-9]){2,10}\.[a-z]{2,3})$/;
    if (!emailPattern.test(c.value)) {
      return { customEmail: true };
    }
    return null;
  }

  validate(c: FormControl): any {
    return CustomEmailValidator.validate(c);
  }
}
