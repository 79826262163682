import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { Product, ProductCategory } from '../models';

@Injectable()
export class QuestionService {
  constructor(private apiService: ApiService) {}

  byCategories(data = {}): Observable<any> {
    return this.apiService
      .post(`/question/by-categories`, data)
      .pipe(map((res) => res.data));
  }

  getInfo(questionId: number): Observable<Product> {
    return this.apiService
      .get(`/question/${questionId}`)
      .pipe(map((res) => res.data));
  }

  getQuestionList(): Observable<any> {
    return this.apiService.get(`/question/`);
  }

  create(data = {}): Observable<any> {
    return this.apiService.post('/question', data).pipe(map((res) => res.data));
  }

  update(questionId: number, data = {}): Observable<any> {
    return this.apiService
      .put(`/question/${questionId}`, data)
      .pipe(map((res) => res.data));
  }

  delete(questionId: number): Observable<any> {
    return this.apiService.delete(`/question/${questionId}`);
  }

  questionsByCattegoryId(data: any): Observable<any> {
    return this.apiService
      .post('/question/by-categories', data)
      .pipe(map((res) => res.data));
  }
}
