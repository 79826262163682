import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// services
import {
  ErrorHandlingService,
  NotificationService,
  ProductService,
} from '../../../core/services';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  constructor(
    private productService: ProductService,
    private matDialogRef: MatDialogRef<DialogComponent>,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.productService.delete(this.data.productId).subscribe(
      (response) => {},
      (err) => {
        this.notificationService.error(
          'Error occurred while deleting product',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
    this.matDialogRef.close(false);
  }

  fetchProductList(): void {}
}
