import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  ModuleWithProviders,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
// material
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
// External Packages
import { ToastrModule } from 'ngx-toastr';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { QuillModule } from 'ngx-quill';
import { TextMaskModule } from 'angular2-text-mask';
// components
import { AppComponent } from './app.component';
// services
import {
  AddressService,
  ApiService,
  ApplicationService,
  BrokerService,
  BusinessService,
  ClientService,
  DataFormatService,
  FirmService,
  FormValidatorService,
  IndustryService,
  MediaService,
  NotificationService,
  PageDataService,
  ProductService,
  UserService,
  ErrorHandlingService,
  BusinessOwnerService,
  StoreService,
  RatingTableService,
  QuestionService,
  EmailService,
  InsurancePolicyService,
  QuoteService,
  ProfileService,
  StripeService,
  EftPaymentService,
  PaymentService,
  UnderwritingService,
} from './core/services';
// modules
import { AccountModule } from './account/account.module';
import { AdminModule } from './admin/admin.module';
import { BrokerModule } from './broker/broker.module';
import { ClientModule } from './client/client.module';
import { HomeModule } from './home/home.module';
import { MaterialModule } from './material/material.module';
import { MgaModule } from './mga/mga.module';
import { QuoteModule } from './quote/quote.module';
import { SharedModule } from './shared/shared.module';
const rootRouting: ModuleWithProviders<any> = RouterModule.forRoot([], {
  useHash: false,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    TextMaskModule,
    BrowserAnimationsModule,
    FormsModule,
    rootRouting,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ['clean'],
        ],
      },
    }),
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
      resetTimeoutOnDuplicate: true,
    }),
    ScrollToModule.forRoot(),
    NgxDropzoneModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC-UR4AF-Zy-EC-EnGhybNWlfIA8XlyIP0',
      libraries: ['places'],
    }),
    MatGoogleMapsAutocompleteModule,
    // local modules
    AccountModule,
    AdminModule,
    BrokerModule,
    ClientModule,
    HomeModule,
    MaterialModule,
    MgaModule,
    QuoteModule,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: UserService) => {
        return () => userService.getInfo(1);
      },
      deps: [UserService],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    // services
    AddressService,
    ApiService,
    ApplicationService,
    BrokerService,
    BusinessService,
    ClientService,
    DataFormatService,
    FirmService,
    FormValidatorService,
    IndustryService,
    MediaService,
    NotificationService,
    PageDataService,
    ProductService,
    UserService,
    ErrorHandlingService,
    BusinessOwnerService,
    StoreService,
    RatingTableService,
    QuestionService,
    EmailService,
    InsurancePolicyService,
    QuoteService,
    ProfileService,
    StripeService,
    EftPaymentService,
    PaymentService,
    UnderwritingService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
