import {
  NgModule,
  ModuleWithProviders,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
// shared
import { AdminLayoutComponent, SharedModule } from '../shared';
// material ui
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CdkTableModule } from '@angular/cdk/table';
// components
import { AdminComponent } from './admin.component';
import { AdminSettingsComponent } from './settings/admin-settings.component';
// broker firm components
import { BrokerFirmsComponent } from './broker-firms/broker-firms.component';
import { BrokerFirmInfoComponent } from './broker-firms/broker-firm-info/broker-firm-info.component';
import { BrokerFirmCreateComponent } from './broker-firms/broker-firm-create/broker-firm-create.component';
// store
import { StoresComponent } from './stores/stores.component';
import { StoreCreateComponent } from './stores/store-create/store-create.component';
import { StoreInfoComponent } from './stores/store-info/store-info.component';
import { ProductCreateComponent } from './stores/product-create/product-create.component';
import { ProductInfoComponent } from './stores/product-info/product-info.component';
// external library
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
// services
import { ApplicationService } from '../core/services';
import { StoreManagerCreateComponent } from './stores/store-manager-create/store-manager-create.component';
import { StoreUpdateComponent } from './stores/store-update/store-update.component';
import { RatingTableCreateComponent } from './stores/rating-table-create/rating-table-create.component';
import { BrokerFirmUpdateComponent } from './broker-firms/broker-firm-update/broker-firm-update.component';
import { BrokerCreateComponent } from './broker-firms/broker-create/broker-create.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/image.min.js';

import { PolicyWordingTemplateComponent } from './stores/policy-wording-template/policy-wording-template.component';
import { EditPolicyWordingTemplateComponent } from './stores/edit-policy-wording-template/edit-policy-wording-template.component';

const adminRouting: ModuleWithProviders<any> = RouterModule.forRoot([
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'broker-firms',
        children: [
          { path: '', component: BrokerFirmsComponent },
          { path: 'create', component: BrokerFirmCreateComponent },
          { path: ':id', component: BrokerFirmInfoComponent },
          { path: ':id/broker-create', component: BrokerCreateComponent },
          { path: ':id/update', component: BrokerFirmUpdateComponent },
        ],
      },
      {
        path: 'stores',
        children: [
          { path: '', component: StoresComponent },
          { path: 'create', component: StoreCreateComponent },
          {
            path: ':id',
            children: [
              { path: '', component: StoreInfoComponent },
              { path: 'update', component: StoreUpdateComponent },
              {
                path: 'store-manager-create',
                component: StoreManagerCreateComponent,
              },
              { path: 'product-create', component: ProductCreateComponent },
            ],
          },
          {
            path: ':storeId/products/:productId',
            component: ProductInfoComponent,
          },
        ],
      },
      {
        path: 'information',
        children: [{ path: '', component: AdminSettingsComponent }],
      },
    ],
  },
]);

@NgModule({
  declarations: [
    AdminComponent,
    StoresComponent,
    BrokerFirmInfoComponent,
    StoreCreateComponent,
    AdminSettingsComponent,
    BrokerFirmsComponent,
    BrokerFirmCreateComponent,
    StoreInfoComponent,
    ProductCreateComponent,
    ProductInfoComponent,
    StoreManagerCreateComponent,
    StoreUpdateComponent,
    RatingTableCreateComponent,
    BrokerFirmUpdateComponent,
    BrokerCreateComponent,
    PolicyWordingTemplateComponent,
    EditPolicyWordingTemplateComponent,
  ],
  imports: [
    CommonModule,
    adminRouting,
    BrowserModule,
    CdkTableModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    FlexLayoutModule,
    SharedModule,
    NgxDropzoneModule,
    MatGoogleMapsAutocompleteModule,
    MatExpansionModule,
    MatCheckboxModule,
    // PolicyWordingTemplateComponent,
    // EditPolicyWordingTemplateComponent,
     FroalaEditorModule.forRoot(),
     FroalaViewModule.forRoot(),
  ],
  providers: [ApplicationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
