import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// service
import {
  BrokerService,
  NotificationService,
  ErrorHandlingService,
} from '../../core/services';
@Component({
  selector: 'app-broker-info-dialog',
  templateUrl: './broker-info-dialog.component.html',
  styleUrls: ['./broker-info-dialog.component.scss'],
})
export class BrokerInfoDialogComponent implements OnInit {
  brokerInfo: any;

  constructor(
    private matDialogRef: MatDialogRef<BrokerInfoDialogComponent>,
    private brokerService: BrokerService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.fetchBrokerInfo();
  }

  fetchBrokerInfo(): void {
    this.brokerService.getInfo(this.data.brokerId).subscribe(
      (response) => {
        this.brokerInfo = response;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching broker info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  close(): void {
    this.matDialogRef.close(false);
  }
}
