import { Media } from './media.model';
import { MGAFirm } from './mga-firm.model';

export class Store {
  id?: number;
  created_at?: Date;
  updated_at?: Date;
  name: string;
  cover_media: Media;
  mga_firm: any;
  mga_firm_id: string;
  store_managers: any[];
  cover_media_id: any;
}
