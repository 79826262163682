import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// service
import {
  UserService,
  FirmService,
  NotificationService,
  ErrorHandlingService,
  StoreService,
} from '../../core/services';

@Component({
  selector: 'app-mga-information',
  templateUrl: './mga-information.component.html',
  styleUrls: ['./mga-information.component.scss'],
})
export class MgaInformationComponent implements OnInit {
  phoneMask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public mgaInformation: any;
  public brokerFirmInformation: any;
  storeMangerFirmInfo: any;
  mgaUpdateForm: FormGroup;
  currentUser: any;
  userUpdatedFlag = false;
  buttonFlag = false;
  selectedOption: any;
  mgaUserInfo: any;
  phoneNumber: any;
  storeInfo: any;
  storeId: any;
  storeManagerType: any[] = [
    { name: 'Yes', id: 1 },
    { name: 'No', id: 0 },
  ];
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private firmService: FirmService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private storeService: StoreService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.fetchStoreInfo();
    this.fetchMgaInfo();
    this.fetchMgaFirmInfo();
  }

  fetchMgaInfo(): void {
    this.userService.getInfo(this.currentUser.id).subscribe(
      (res) => {
        this.selectedOption = res?.data?.userable?.admin;
        this.mgaUserInfo = res.data;
        this.phoneNumber = this.testingPhoneNumber(
          this.mgaUserInfo?.userable?.phone_number
        );

        this.updateMga();
      },
      (err) => {}
    );
  }

  testingPhoneNumber(preSelectedPhoneNumber: any): any {
    const cleaned = ('' + preSelectedPhoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + '- ' + match[2] + '- ' + match[3];
    }
  }

  formatPhoneNumber(event: any): string {
    const cleaned = ('' + event).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      this.phoneNumber =
        '(' + match[1] + ') ' + '- ' + match[2] + '- ' + match[3];
    }
    // this.brokerCreateForm.patchValue({ phone_number: this.phoneNumber });
    return null;
  }
  updateMga(): void {
    this.mgaUpdateForm = this.formBuilder.group({
      first_name: [
        this.mgaUserInfo?.first_name,
        Validators.compose([Validators.required]),
      ],
      last_name: [
        this.mgaUserInfo?.last_name,
        Validators.compose([Validators.required]),
      ],
      phone_number: [this.phoneNumber],
      store_manager_type: [''],
    });
  }

  fetchMgaFirmInfo(): void {
    this.firmService
      .getMgaInfo(this.currentUser?.userable?.mga_firm_id)
      .subscribe(
        (response) => {
          this.storeMangerFirmInfo = response;
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching firm info',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }
  handleStoreManagerDelete(result): void {
    this.userService
      .deleteStoreManager(result.storeManagerId, result.userId)
      .subscribe(
        (response) => {
          this.fetchStoreInfo();
        },
        (error) => {
          this.notificationService.error(
            'Error occurred while deleting store manager',
            this.errorHandlingService.getErrors(error)
          );
        }
      );
  }

  fetchStoreInfo(): void {
    this.storeService.getInfo(this.currentUser?.stores?.id,null).subscribe(
      (response) => {
        this.storeInfo = response;
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while store info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  submitForm(): void {
    this.userService
      .updateStoreManager(
        this.mgaUpdateForm.value,
        this.currentUser.userable_id,
        this.storeMangerFirmInfo.id,
        this.mgaUserInfo.id
      )
      .subscribe(
        (res) => {
          this.userUpdatedFlag = false;
          this.fetchMgaInfo();
        },
        (err) => {}
      );
  }

  onUpdate(): void {
    this.router.navigate([
      '/mga/information/store-manager/',
      this.currentUser?.userable_id,
      'update',
    ]);
    // this.userUpdatedFlag = true;
    this.buttonFlag = false;
  }
  onBackToBrokers(): void {
    this.userUpdatedFlag = false;
  }
}
