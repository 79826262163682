import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  ClientService,
  ErrorHandlingService,
  NotificationService,
  UserService,
  ProfileService,
} from 'src/app/core/services';
// material ui
import { MatTableDataSource } from '@angular/material/table/';
@Component({
  selector: 'app-broker-client-search',
  templateUrl: './broker-client-search.component.html',
  styleUrls: ['./broker-client-search.component.scss'],
})
export class BrokerClientSearchComponent implements OnInit {
  @Output() clientProfileInfo = new EventEmitter<any>();
  @Output() hasProduct = new EventEmitter<any>();
  @Output() profileFlag = new EventEmitter<any>();
  @Output() updatedQuestionList = new EventEmitter<any>();
  @Output() selectedClient = new EventEmitter<any>();
  @Input() questions;
  applicationData: any[] = [];
  dataSource = new MatTableDataSource<any>();
  public displayedColumns = [
    'first_name',
    'last_name',
    'email',
    'application_numbers',
    'select',
  ];
  public selectedClientData: any;
  public currentUserInfo: any;
  public clientList: any[] = [];
  public responseData: any;

  constructor(
    private clientService: ClientService,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.currentUserInfo = this.userService.getCurrentUser();
    this.getClientListByBrokerId();
  }

  doFilter(value: any): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  onRadioButtonClicked(data: any): void {
    const result = this.responseData.filter((item) => item.user.id === data.id);
    this.selectedClientData = result;
  }

  onBackButton(): void {
    this.hasProduct.emit(false);
  }

  onApplicationCreate(): void {
    this.profileService.getInfo(this.selectedClientData[0]?.user?.id).subscribe(
      (res) => {
        this.questions?.forEach((categoryQuestion) => {
          res?.answers?.forEach((profileQuestion) => {
            if (categoryQuestion?.id === profileQuestion.question_id) {
              categoryQuestion['answer'] = profileQuestion.answer;
              categoryQuestion['question_id'] = profileQuestion?.question_id;

              if (profileQuestion?.options) {
                categoryQuestion['options'] = profileQuestion?.options;
              }
            }
          });
        });
        if (res?.user_id) {
          this.selectedClientData[0]['user_id'] = res?.user_id;
        } else {
          this.selectedClientData[0]['user_id'] = '';
        }
        if (res?.id) {
          this.selectedClientData[0]['profile_id'] = res?.id;
        }
        this.clientProfileInfo.emit(res);
        this.selectedClient.emit(this.selectedClientData[0]);
        this.updatedQuestionList.emit(this.questions);
        this.profileFlag.emit(true);
      },
      (err) => {}
    );
  }

  getClientListByBrokerId(): void {
    this.clientService
      .searchByBrokerId(
        this.currentUserInfo.userable_id,
        'user,applications,businesses'
      )
      .subscribe(
        (response) => {
          this.responseData = response;
          this.applicationData = response;
          this.dataSource.data = response.map((item) => item.user);
        },
        (err) => {
          this.notificationService.error(
            '',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }
}
