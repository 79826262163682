import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// rxjs
import { Observable } from 'rxjs';
import {
  map,
  startWith,
  debounceTime,
  switchMap,
  distinctUntilChanged,
} from 'rxjs/operators';
// service
import { ClientService } from '../../core/services';

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss'],
})
export class ClientSearchComponent implements OnInit {
  @Output() clientCreateChange = new EventEmitter<boolean>();
  @Output() clientStatus = new EventEmitter<boolean>();
  @Input()
  isClientCreating: boolean;
  clientId: number;
  clientFormGroup: FormGroup;
  filteredClient: any;
  fetchedClientData: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private router: Router
  ) {
    this.createFormBuilder();
  }

  ngOnInit(): void {
    this.filteredClient = this.clientFormGroup.get('client').valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value) => {
        return this.filterClient(value);
      })
    );
  }

  filterClient(value: string): Observable<any> {
    const searchTerm = value.toLowerCase();
    return this.clientService.searchClient(searchTerm).pipe(
      map((data) => {
        this.fetchedClientData = data;
        return data;
      })
    );
  }
  getSelectedSearchedClientId(firstName: any): void {
    this.clientId = this.fetchedClientData.find(
      (client) => client.first_name === firstName
    ).userable_id;
    this.router.navigate([`/mga/policies/clients/`, this.clientId]);
  }

  createFormBuilder(): void {
    this.clientFormGroup = this.formBuilder.group({
      client: ['', Validators.required],
    });
  }

  changeClientCreate(): void {
    this.clientStatus.emit(true);
  }
}
