import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
// services
import { ApiService } from './api.service';
import { BusinessOwnerService } from './business-owner.service';
import { ClientService } from './client.service';
import { AddressService } from './address.service';
import { BrokerService } from './broker.service';
// models
import { User } from '../models';

@Injectable()
export class UserService {
  currentUser = null;

  constructor(
    private apiService: ApiService,
    private businessOwnerService: BusinessOwnerService,
    private clientService: ClientService,
    private addressService: AddressService,
    private brokerService: BrokerService
  ) {}

  getCurrentUser(): User {
    const user = JSON.parse(window.localStorage.getItem('currentUser'));
    if (
      user == null &&
      window.location.href !== window.location.origin &&
      window.location.href !== window.location.origin + '/'
    ) {
      window.localStorage.setItem('redirectURL', document.URL);
      window.location.href = window.location.origin + '/account/signin';
    } else if (user != null && window.localStorage.getItem('redirectURL')) {
      return user;
    } else {
      return user;
      // window.location.href = 'http://localhost:4200/account/signin';
    }
  }

  setAuth(user: User): void {
    window.localStorage.setItem('currentUser', JSON.stringify(user));
  }
  setClientableId(clientId: any): void {
    window.localStorage.setItem('clientable_id', clientId);
  }

  getClientableId(): string {
    return window.localStorage.getItem('clientable_id');
  }
  purgeAuth(): void {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('currentUser');
  }

  isAdmin(type: string): boolean {
    return type === 'App\\Models\\User\\Admin';
  }

  isBroker(type: string): boolean {
    return type === 'App\\Models\\User\\Broker';
  }

  isClient(type: string): boolean {
    return type === 'App\\Models\\User\\Client';
  }

  isMga(type: string): boolean {
    return type === 'App\\Models\\User\\StoreManager';
  }

  login(credentials): Observable<any> {
    return this.apiService.post(`/auth/login`, credentials);
  }

  registerBusinessOwner(data = {}): Observable<any> {
    return this.apiService.post(`/auth/register/business-owner`, data);
  }

  registerVerify(verificationToken): Observable<any> {
    return this.apiService.getBusinessOwnerVerified(`${verificationToken}`);
  }

  resendVerificationEmail(data): Observable<any> {
    return this.apiService.post(`/email/resend`, data);
  }

  createUser(data = {}): Observable<any> {
    return this.apiService.post(`/user`, data);
  }

  createStoreManager(
    data,
    phone_number: string,
    mga_firm_id: string,
    isAdmin: number
  ): Observable<any> {
    return this.apiService
      .post(`/store-manager`, {
        phone_number: phone_number,
        mga_firm_id: mga_firm_id,
        admin: isAdmin,
        email: data.email,
      })
      .pipe(
        switchMap((storeManagerResponse) => {
          return this.createUser({
            ...data,
            userable_id: storeManagerResponse.data.id,
            userable_type: 'App\\Models\\User\\StoreManager',
          });
        })
      );
  }

  updateStoreManager(
    data,
    storeManagerId: any,
    mga_firm_id: string,
    userId: any
  ): Observable<any> {
    return this.apiService
      .put(`/store-manager/${storeManagerId}`, {
        phone_number: data.phone_number,
        mga_firm_id: mga_firm_id,
        admin: data.store_manager_type,
      })
      .pipe(
        switchMap((storeMangerUpdatedResponse) => {
          return this.update(userId, {
            ...data,
            userable_id: storeMangerUpdatedResponse.data.id,
            userable_type: 'App\\Models\\User\\StoreManager',
          });
        })
      );
  }

  createBroker(data): Observable<any> {
    return this.brokerService
      .create({ phone_number: '324234234', broker_firm_id: 1, admin: true })
      .pipe(
        switchMap((brokerResponse) => {
          return this.createUser({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            userable_id: brokerResponse.id,
            userable_type: 'App\\Models\\User\\Broker',
          });
        })
      );
  }

  createClient(data): Observable<any> {
    // TODO: Remove personal_phone_number and personal_address_id after refactor
    return this.addressService
      .create({
        street_address: data.street_address,
        city: data.city,
        zip_code: data.zip_code,
        country_id: 1,
      })
      .pipe(
        switchMap((addressResponse) => {
          return this.clientService.create({
            personal_phone_number: '324234234',
            personal_address_id: addressResponse.id,
            gender: 'M',
            beneficiary_business_name: 'patel beneficiary',
          });
        }),
        switchMap((clientResponse) => {
          return this.createUser({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            userable_id: clientResponse.id,
            userable_type: 'App\\Models\\User\\Client',
            activated: '',
          });
        })
      );
  }

  resetPassword(url: any, data = {}): Observable<any> {
    return this.apiService.postClientResetPassword(url, data);
  }

  forgetPassword(data = {}): Observable<any> {
    return this.apiService.post('/auth/password/email', data);
  }

  getInfo(userId: number): Observable<any> {
    return this.apiService.get(`/user/${userId}`);
  }

  update(userId: number, data = {}): Observable<any> {
    return this.apiService.put(`/user/${userId}`, data);
  }

  delete(userId: number): Observable<any> {
    return this.apiService.delete(`/user/${userId}`);
  }
  searchUser(email: any): Observable<any> {
    return this.apiService
      .get(`/user/search?email=${email}`)
      .pipe(map((res) => res.data));
  }

  deleteStoreManager(storManagerId: string, userId: number): Observable<any> {
    return this.delete(userId).pipe(
      switchMap((userResponse) => {
        return this.apiService.delete(`/store-manager/${storManagerId}`);
      })
    );
  }
}
