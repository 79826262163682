import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
// rxjs
import {
  map,
  startWith,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { Industry } from 'src/app/core/models';
import { orderBy } from 'lodash';
@Component({
  selector: 'app-business-industry-select',
  templateUrl: './business-industry-select.component.html',
  styleUrls: ['./business-industry-select.component.scss'],
})
export class BusinessIndustrySelectComponent implements OnInit {
  @Input() selectedValue;
  @Input() industryData;
  @Output() industrySelect = new EventEmitter<any>();
  @Output() filterdPreselectedInd = new EventEmitter<any>();

  businessIndustry = new FormControl();
  industryList: any[] = [];
  filteredOptions: Industry[] = [];

  constructor() {}

  ngOnInit(): void {
    this.businessIndustry.setValue({ value: this.selectedValue });
    this.filterdPreselectedInd.emit(
      this.industryData.filter((item) => item.name === this.selectedValue)[0]
    );
    this.fetchIndustryList();
    this.businessIndustry.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        map((value) => {
          return this.filterBusinessIndustry(value || '');
        })
      )
      .subscribe((res) => {
        this.filteredOptions = res;
      });
  }

  fetchIndustryList(): void {
    this.industryList = orderBy(this.industryData, ['name']);
  }

  displayFn(industry: any): any {
    return industry && industry.value ? industry.value : '';
  }

  filterBusinessIndustry(value: any): Industry[] {
    if (typeof value === 'string') {
      const searchTerm = value.toLowerCase();
      return this.industryList.filter(
        (option) => option.name.toLowerCase().indexOf(searchTerm) === 0
      );
    }
  }

  optionSelected(option): void {
    this.industrySelect.emit(option || this.filterdPreselectedInd[0]);
  }
}
