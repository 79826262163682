import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.scss'],
})
export class BrokerComponent implements OnInit {
  applicationId: number;
  businessId: number;
  isQuoteRequest = true;
  // @Output() businessNumber = new EventEmitter<number>();
  isProduct = false;
  isClientCreating = false;
  clientId: number | null = null;
  backTOClientSearchField: true;
  toShow: true;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  clientCreated(clientId: number): void {
    this.clientId = clientId;
  }

  clientStatus(isClient: boolean): void {
    this.isClientCreating = isClient;
  }

  getBusinessId(businessId: number): void {
    this.isQuoteRequest = false;
    this.businessId = businessId;
    this.isProduct = true;
  }

  product(productSelected: boolean): void {
    this.isProduct = productSelected;
  }

  getApplicationId(applicationId: number): void {
    this.applicationId = applicationId;
  }

  buttonClick(event: Event): void {
    this.isClientCreating = true;
  }
}
