import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
// models
import { InsurancePolicy } from 'src/app/core/models';
// service
import {
  NotificationService,
  QuoteService,
  ErrorHandlingService,
} from 'src/app/core/services';

// components
import { QuoteStatusDialogComponent } from '../../shared/components/quote-status-dialog/quote-status-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.scss'],
})
export class QuoteListComponent implements OnInit {
  @Input() clientData: any;
  @Output() quoteData = new EventEmitter<any>();
  policies: InsurancePolicy;
  displayedPolicyColumns = [
    'product_number',
    'insurance_type',
    'quote_status',
    'quote_status_id',
    'change_status',
    'select',
  ];
  constructor(
    private quoteService: QuoteService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.insurancePolicyListBasedOnClientId();
  }

  insurancePolicyListBasedOnClientId(): void {
    this.quoteService
      .getQuoteListByclient(this.clientData.userable_id)
      .subscribe(
        (response) => {
          this.policies = response;
        },
        (err) => {
          this.notificationService.error(
            '',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  openQuoteStatusChangeDialog(data): void {
    const dialogRef = this.dialog.open(QuoteStatusDialogComponent, {
      data: {
        title: `Are you sure you want to chnage quote status from Non-Binding to Binding for client ${
          data?.application?.client?.first_name +
          data?.application?.client?.last_name
        } and quote id${data?.id}`,
        result: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.quoteService
        .updateQuoteStatus(result, { quote_status_id: 2 })
        .subscribe(
          (res) => {
            // this.quoteStatusChange.emit(res);
            this.insurancePolicyListBasedOnClientId();
          },
          (err) => {
            this.notificationService.error(
              '',
              this.errorHandlingService.getErrors(err)
            );
          }
        );
    });
  }

  onClick(data: any): any {
    this.quoteData.emit(data);
  }
}
// data?.insurance_product?.id
