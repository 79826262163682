import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable()
export class FormValidatorService {
  constructor() {}

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  markFormAsUntouched(form: FormGroup): void {
    form[`_touched`] = false;
    form[`_pristine`] = true;
    for (const i in form.controls) {
      if (form.controls.hasOwnProperty(i)) {
        (<any>form.controls[i])._touched = false;
        (<any>form.controls[i])._pristine = true;
      }
    }
  }
}
