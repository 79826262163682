import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-boolean-selection',
  templateUrl: './user-boolean-selection.component.html',
  styleUrls: ['./user-boolean-selection.component.scss'],
})
export class UserBooleanSelectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
