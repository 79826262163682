import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// service
import {
  UserService,
  NotificationService,
  ErrorHandlingService,
} from '../../core/services';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss'],
})
export class AdminSettingsComponent implements OnInit {
  adminInformation: any;
  isEditingMode = false;
  userUpdatedFlag = false;
  buttonFlag = false;
  adminCreateForm: FormGroup;
  public currentUserInfo: any;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.currentUserInfo = this.userService.getCurrentUser();
    this.fetchUserInfo();
  }
  updateAdminFirm(): void {
    this.adminCreateForm = this.formBuilder.group({
      first_name: [
        this.adminInformation?.first_name,
        Validators.compose([Validators.required]),
      ],
      last_name: [
        this.adminInformation?.last_name,
        Validators.compose([Validators.required]),
      ],
    });
  }

  fetchUserInfo(): void {
    const currentUser = this.userService.getCurrentUser();
    this.userService.getInfo(currentUser.id).subscribe(
      (response) => {
        this.adminInformation = response.data;
        this.updateAdminFirm();
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching user info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
  onUpdate(): void {
    this.userUpdatedFlag = true;
    this.buttonFlag = false;
  }
  onBackToAdmin(): void {
    this.userUpdatedFlag = false;
  }

  submitForm(): void {
    this.userService
      .update(this.currentUserInfo?.id, {
        first_name: this.adminCreateForm.value.first_name,
        last_name: this.adminCreateForm.value.last_name,
      })
      .subscribe(
        (res) => {
          this.fetchUserInfo();
          this.userUpdatedFlag = false;
        },
        (err) => {
          this.notificationService.error(
            'Error while updating user info',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }
}
