import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import axios from 'axios';
import {
  InsurancePolicyService,
  MediaService,
  NotificationService,
} from 'src/app/core/services';

@Component({
  selector: 'app-policy-wording-template',
  templateUrl: './policy-wording-template.component.html',
  styleUrls: ['./policy-wording-template.component.scss'],
})
export class PolicyWordingTemplateComponent implements OnInit {
  @Output() updatePolicyTemplates = new EventEmitter();
  @Output() closePolicyBuilder = new EventEmitter();
  @Input() policyWordingData;
  @Input() productId;

  insertionFieldsList: any[] = [
    {
      field_header: 'Store Info',
      list: [
        {
          label: 'Store Name',
          value: 'store_name',
        },
        { label: 'Firm Name', value: 'firm_name' },
        {
          label: 'Firm Address',
          value: 'frim_address',
        },
        { label: 'Firm Logo', value: 'firm_media_id' },
        { label: 'Store Admin First Name', value: 'storeadmin_first_name' },
        {
          label: 'Store Admin Last Name',
          value: 'storeadmin_last_name',
        },
        { label: 'Store Admin e-mail', value: 'storeadmin_email' },
        { label: 'Store Admin Phone Number', value: 'storeadmin_phone_number' },
      ],
    },
    {
      field_header: 'Product Info',
      list: [
        { label: 'Product Name', value: 'insuranceproduct_name' },
        {
          label: 'Product Category',
          value: 'category',
        },
        { label: 'Product Logo', value: 'product_media_id' },
        { label: 'Product Description', value: 'insuranceproduct_description' },
        {
          label: 'Product Terms & Condition',
          value: 'insuranceproduct_terms_and_conditions',
        },
      ],
    },
    {
      field_header: 'Insured Info',
      list: [
        { label: 'Client First Name', value: 'client_first_name' },
        { label: 'Client Last Name', value: 'client_last_name' },
        { label: 'Client E-mail', value: 'client_email' },
        { label: 'Company Name', value: 'company_name' },
        { label: 'Business Number', value: 'business_number' },
        {
          label: 'Insurance Address',
          value: 'address',
        },
        { label: 'Industry', value: 'industry' },
        { label: 'Years of Operation', value: 'years_of_operation' },
        { label: 'Num of Employees', value: ' number_of_employees' },
        {
          label: 'Annual Revenue',
          value: 'annual_revenue',
        },
      ],
    },
    {
      field_header: 'Policy Info',
      list: [
        { label: 'Policy Number', value: 'policy_number' },
        { label: 'Inception Date', value: 'inception_date' },
        { label: 'Expiration Date', value: 'expiration_date' },
        { label: 'Limit', value: 'limit_percentage' },
        { label: 'Deductible', value: 'deductible_amount' },
        { label: 'Coverage', value: 'coverage' },
        { label: 'Premium', value: 'premium_amount' },
        {
          label: 'Premium Frequency',
          value: 'premium_frequency',
        },
      ],
    },
    {
      field_header: 'Broker Info',
      list: [
        { label: 'Broker First Name', value: 'broker_first_name' },
        { label: 'Broker Last Name', value: 'broker_last_name' },
        { label: 'Broker E-mail', value: 'broker_email ' },
        { label: 'Broker Phone Number', value: 'broker_phone_number' },
        { label: 'Broker Firm Name', value: 'broker_firm_name' },
        {
          label: 'Broker Firm Address',
          value: 'broker_firmaddress',
        },
      ],
    },
  ];
  public options = {
    charCounterCount: true,
    height: 750,
    toolbarButtons: [
      'fullscreen',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      '|',
      'fontFamily',
      'fontSize',
      'color',
      'inlineStyle',
      'paragraphStyle',
      '|',
      'paragraphFormat',
      'align',
      'formatOL',
      'formatUL',
      'outdent',
      'indent',
      'quote',
      '-',
      'insertLink',
      'insertImage',
      // 'insertVideo',
      // 'insertFile',
      'insertTable',
      '|',
      // 'emoticons',
      // 'specialCharacters',
      'insertHR',
      'selectAll',
      'clearFormatting',
      '|',
      'print',
      'help',
      'html',
      '|',
      'undo',
      'redo',
      'trackChanges',
      'markdown',
    ],
    events: {
      'image.inserted': (images): boolean => {
        return false;
      },
    },
  };
  mediaList: any[] = [];
  uploadedImagesList: any[] = [];
  wordingDescription = '';
  selectedStatus = 'Draft';
  wordingForm;

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private mediaService: MediaService,
    private insurancePolicyService: InsurancePolicyService
  ) {}

  ngOnInit(): void {
    console.log("inside the policy wording")
    if (this.policyWordingData) {
    }
    this.initializeWordingFormBuilder();
    this.overrideFroalaImageInsertedEvent();
  }

  initializeWordingFormBuilder(): void {
    this.wordingForm = this.formBuilder.group({
      title: [
        this.policyWordingData ? this.policyWordingData.title : '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_ ]*$'),
        ]),
      ],
    });
    if (this.policyWordingData) {
      this.selectedStatus = this.policyWordingData.status;
      this.wordingDescription = this.policyWordingData.content;
    }
  }

  overrideFroalaImageInsertedEvent(): void {
    this.options.events['image.inserted'] = (images) => {
      if (images.length) {
        this.uploadedImagesList.push(images[0]);
      }
      return false;
    };
  }

  onFieldCardDrag(event, field): void {
    event.dataTransfer.setData('Text', `[${field.value}] `);
  }

  textChanged(data: any): void {
    this.wordingDescription = data.html;
  }

  async getUploadImages(): Promise<any> {
    const promises = this.uploadedImagesList.map((image) => {
      return axios.get(image.src, { responseType: 'blob' });
    });
    await Promise.all(promises).then((response) => {
      response.forEach((blobObject) => {
        this.mediaList.push(new File([blobObject.data], 'test'));
      });
    });
  }

  updatePolicyDocument(): void {
    if (!this.wordingDescription) {
      this.notificationService.warning(
        'Wording Template Error',
        'Please enter a description'
      );
    } else {
      if (this.wordingForm.status === 'VALID') {
        this.getUploadImages().then(() => {
          if (this.mediaList?.length > 0) {
            this.mediaService
              .createMany([...this.mediaList])
              .subscribe((response) => {
                this.uploadedImagesList.forEach((image, index) => {
                  this.wordingDescription = this.wordingDescription.replace(
                    image.src,
                    response.data[index].url
                  );
                });
                const policyWordingData = {
                  title: this.wordingForm.get('title').value,
                  content: this.wordingDescription,
                  status: this.selectedStatus,
                  active: 1,
                  insurance_products_id: 1,
                };

                if (this.policyWordingData) {
                  this.insurancePolicyService
                    .updatePolicyWordingTemplate(
                      this.policyWordingData.id,
                      policyWordingData
                    )
                    .subscribe(() => {
                      this.notificationService.success(
                        'Policy Template Updated Successfully'
                      );
                    });
                } else {
                  this.insurancePolicyService
                    .saveWordingTemplate(policyWordingData)
                    .subscribe(() => {
                      this.notificationService.success(
                        'Policy Template Saved Successfully'
                      );
                    });
                }
                this.updatePolicyTemplates.emit();
              });
          } else {
            const policyWordingData = {
              title: this.wordingForm.get('title').value,
              content: this.wordingDescription,
              status: this.selectedStatus,
              active: 1,
              insurance_products_id: this.productId,
            };

            if (this.policyWordingData) {
              this.insurancePolicyService
                .updatePolicyWordingTemplate(
                  this.policyWordingData.id,
                  policyWordingData
                )
                .subscribe(() => {
                  this.notificationService.success(
                    'Policy Template Updated Successfully'
                  );
                });
            } else {
              this.insurancePolicyService
                .saveWordingTemplate(policyWordingData)
                .subscribe(() => {
                  this.notificationService.success(
                    'Policy Template Saved Successfully'
                  );
                });
            }
            this.updatePolicyTemplates.emit();
          }
        });
      } else {
        this.notificationService.error(
          'Form Error',
          'Please enter a valid title'
        );
      }
    }
  }

  closePolicyDocument(): void {
    this.closePolicyBuilder.emit();
  }
}
