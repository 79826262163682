import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// service
import {
  NotificationService,
  ProductService,
  ErrorHandlingService,
} from 'src/app/core/services';
import { MatDialog } from '@angular/material/dialog';
import { ProductTermsAndConditionComponent } from './product-terms-and-condition/product-terms-and-condition.component';
import { Location } from '@angular/common';
@Component({
  selector: 'app-product-request',
  templateUrl: './product-request.component.html',
  styleUrls: ['./product-request.component.scss'],
})
export class ProductRequestComponent implements OnInit {
  displayedColumns = [
    'product_logo',
    'product_name',
    'description',
    'region',
    'store',
    'view',
  ];
  categoryList: any[] = [];
  selectedCategory: any;
  productsList: any;
  selectedProduct: any;
  productCategory = '';

  constructor(
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    public dialog: MatDialog,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.selectedCategory = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.selectedCategory = this.selectedCategory === 'professinal-liability' ? null : this.selectedCategory;
    this.categoryList.push(this.selectedCategory);
    this.getProductList();
  }

  getProductList(): void {
    this.productService.searchByCategoryIds(this.categoryList).subscribe(
      (res) => {
        this.productsList = res[0];
        this.productCategory = this.productsList[0].insurance_product_category?.name;
        console.log(this.categoryList[0]);
        if(this.categoryList[0] == null){
          this.productCategory = 'all categories';
        }
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching products list',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  selectProductToBuy(product: any): void {
    this.selectedProduct = product;
  }

  showTermsAndConditions(productTermsAndConditions): void {
    const dialogRef = this.dialog.open(ProductTermsAndConditionComponent, {
      data: {
        widh: '100px',
        termsAndConditions: productTermsAndConditions,
      },
    });
  }

  back(): void {
    this.location.back();
  }
}
