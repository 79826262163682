import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
// place result type
import PlaceResult = google.maps.places.PlaceResult;

export interface IAddressResult {
  street_address: string;
  city: string;
  zip_code: string;
  business_address?: string;
}
@Component({
  selector: 'app-google-address-input',
  templateUrl: './google-address-input.component.html',
  styleUrls: ['./google-address-input.component.scss'],
})
export class GoogleAddressInputComponent implements OnInit {
  @Output() addressChange = new EventEmitter<IAddressResult>();
  @Input() preSelectedAddress: any;

  street_address = new FormControl();
  city = new FormControl();
  zip_code = new FormControl();

  constructor() {}

  ngOnInit(): void {}

  onAutocompleteSelected(result): void {
    this.street_address.setValue(result.name || 'test');
    this.city.setValue(this.getCityFromResult(result) || 'test2');
    this.zip_code.setValue(this.getPostalCodeFromResult(result) || 'test3');
    this.addressChange.emit({
      street_address: this.street_address.value,
      city: this.city.value,
      zip_code: this.zip_code.value,
    });
  }

  getCityFromResult(result: PlaceResult): string {
    const cities = result.address_components.filter((item) => {
      if (item.types.includes('locality')) {
        return item.long_name;
      }
    });
    return cities.length > 0 ? cities[0].long_name : 'N/A';
  }

  getPostalCodeFromResult(result: PlaceResult): string {
    const postalCodes = result.address_components.filter((item) => {
      if (item.types.includes('postal_code')) {
        return true;
      }
    });
    return postalCodes.length > 0 ? postalCodes[0].long_name : 'N/A';
  }
}
