import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
// service
import {
  UserService,
  FirmService,
  BrokerService,
  ProductService,
  NotificationService,
} from 'src/app/core/services';

import moment from 'moment';
@Component({
  selector: 'app-broker-list',
  templateUrl: './broker-list.component.html',
  styleUrls: ['./broker-list.component.scss'],
})
export class BrokerListComponent implements OnInit {
  productId: any;
  productInfo: any;
  displayAllColumns = ['id', 'first_name', 'select'];
  currentUser: any;
  finalDataList = [];
  finalFinalList: any[] = [];
  brokerData: any;
  brokerList: any;
  addBrokers: any;
  updateBrokerList: any;
  // brokerList = [
  //   {
  //     id: 1,
  //     first_name: 'Parth',
  //   },
  //   {
  //     id: 2,
  //     first_name: 'Parth',
  //   },
  //   {
  //     id: 3,
  //     first_name: 'Parth',
  //   },
  // ];
  constructor(
    private userService: UserService,
    private brokerService: BrokerService,
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.productId = parseInt(
      this.activatedRoute.snapshot?.paramMap?.get('productId'),
      10
    );
    this.currentUser = this.userService.getCurrentUser();
    this.fetchBrokerList();
    this.fetchProductInfo();
  }

  fetchBrokerList(): void {
    this.brokerService
      .getBrokerListByMgaFirmId(this.currentUser.userable.mga_firm_id)
      .subscribe(
        (res) => {
          this.brokerData = res;
          this.brokerList = this.brokerData.map((item) => {
            return item.user;
          });
        },
        (err) => {}
      );
  }
  onSelectChange(data: any): void {}
  checkboxModelChange(selectedBroker: any): void {
    if (selectedBroker.selected === true) {
      this.finalDataList.push(selectedBroker);
    } else {
      this.finalDataList = this.finalDataList.filter((item) => {
        return item.id !== selectedBroker.id;
      });
    }
  }

  addBrokerToProduct(): void {
    this.addBrokers = this.finalDataList.map((item) => item.userable_id);
    this.getProductUpdate();
  }

  fetchProductInfo(): void {
    this.productService.getInfo(this.productId,'').subscribe(
      (res) => {
        this.productInfo = res;
        if (this.productInfo.brokers.length > 0) {
          this.updateBrokerList = this.productInfo.brokers.map((item) => {
            return item.id;
          });
        } else {
          this.updateBrokerList = this.productInfo.brokers;
        }
      },
      (err) => {}
    );
  }

  getProductUpdate(): void {
    this.productService
      .update(this.productInfo.id, {
        name: this.productInfo.name,
        product_number: this.productInfo.product_number,
        region: this.productInfo.region,
        aggregate_limit: this.productInfo.aggregate_limit,
        valid_from: (this.productInfo?.valid_from).substring(0, 10),
        valid_to: (this.productInfo?.valid_from).substring(0, 10),
        description: this.productInfo.description,
        terms_and_conditions: this.productInfo.terms_and_conditions,
        rating_table_id: this.productInfo.rating_table_id,
        logo_id: this.productInfo.logo_id,
        store_id: this.productInfo.store_id,
        brokers: [...new Set([...this.updateBrokerList, ...this.addBrokers])],
        insurance_product_category_id: this.productInfo
          .insurance_product_category_id,
      })
      .subscribe(
        (res) => {
          this.notificationService.success(
            '',
            'Brokers have been added to product'
          );
          this.router.navigate(['/mga/products/']);
        },
        (err) => {}
      );
  }
}
