import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multiple-product-category-selection',
  templateUrl: './multiple-product-category-selection.component.html',
  styleUrls: ['./multiple-product-category-selection.component.scss'],
})
export class MultipleProductCategorySelectionComponent implements OnInit {
  @Input() selectedProductCategory: any;
  @Input() formControl: string;
  productCategories: any[];
  preSelectedProductCategory: any;
  @Output() confirmProductCategory = new EventEmitter<any>();

  constructor() {
    this.productCategories = [
      {
        id: 1,
        label: 'Professional Liability',
        value: 'Professional Liability',
      },
      { id: 2, label: 'General Liability', value: 'General Liability' },
      { id: 3, label: 'D & O', value: 'D & O' },
      { id: 4, label: 'E & O', value: 'E & O' },
      {
        id: 5,
        label: 'Cyber',
        value: 'Cyber',
      },
      { id: 6, label: 'Other', value: 'Other' },
    ];
  }

  ngOnInit(): void {
    this.preSelectedProductCategory = this.selectedProductCategory.split(',');
  }

  onSelectChange(event: any): void {
    this.confirmProductCategory.emit(event.value);
  }

  onPanelOpen(event: any): void {}
}
