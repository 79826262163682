import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
// services
import {
  ErrorHandlingService,
  FirmService,
  NotificationService,
  UserService,
} from '../../../../core/services';
// models
import { User } from '../../../../core/models';

@Component({
  selector: 'app-main-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class MainLayoutHeaderComponent implements OnInit {
  currentUser: any;
  navbarIsOpen = false;
  showAuthed = false;
  public sampleBrokerFirmId: any;
  public mobile = false;

  @HostListener('document:click', ['$event']) onClick(ev): void {
    if (
      !ev.target.classList.contains('js-toggle') &&
      ev.target.offsetParent &&
      !ev.target.offsetParent.classList.contains('js-toggle') &&
      this.navbarIsOpen
    ) {
      this.navbarIsOpen = !this.navbarIsOpen;
    }
  }
  constructor(
    private userService: UserService,
    private router: Router,
    private firmService: FirmService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.fetchBrokerFirms();
    this.currentUser = this.userService.getCurrentUser();
    window.onresize = () => this.mobile = window.innerWidth <= 991;
    if(window.innerWidth <= 991){
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  fetchBrokerFirms(): void {
    this.firmService.getBrokerFirmList('NA').subscribe(
      (response) => {
        this.sampleBrokerFirmId = response[0].id;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching broker firms',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  goToLogin(): void {
    if (this.currentUser?.userable_type) {
      this.navigateToDashboard(this.currentUser.userable_type);
    } else {
      this.router.navigate([`/account/signin`]);
    }
  }

  navigateToDashboard(userType): void {
    if (this.userService.isAdmin(userType)) {
      this.router.navigate([`/admin/broker-firms`]);
    } else if (this.userService.isBroker(userType)) {
      this.router.navigate([`/broker`]);
    } else if (this.userService.isMga(userType)) {
      this.router.navigate([`/mga`]);
    } else if (this.userService.isClient(userType)) {
      this.router.navigate([`/client`]);
    }
  }

  logout(): void {
    this.userService.purgeAuth();
    this.goToLogin();
  }
}
