import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// services
import {
  ApiService,
  ErrorHandlingService,
  NotificationService,
  UserService,
} from '../../core/services';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  authForm: FormGroup;
  urlWithLink: any;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.authForm = this.fb.group({
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z, A-Z]+'),
        ]),
      ],
      password_confirmation: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z, A-Z]+'),
        ]),
      ],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.urlWithLink = params.queryURL;
    });
  }

  submitForm(): void {
    this.userService
      .resetPassword(this.urlWithLink, this.authForm.value)
      .subscribe(
        (response) => {
          if (
            response.data.user.userable_type === 'App\\Models\\User\\Broker'
          ) {
            this.apiService.addAuthToken(response.data.token.accessToken);
            this.userService.setAuth(response.data.user);
            this.router.navigate(['/broker']);
          } else if (
            response.data.user.userable_type === 'App\\Models\\User\\Client'
          ) {
            this.apiService.addAuthToken(response.data.token.accessToken);
            this.userService.setAuth(response.data.user);
            this.router.navigate(['/client']);
          } else if (
            response.data.user.userable_type ===
            'App\\Models\\User\\StoreManager'
          ) {
            this.apiService.addAuthToken(response.data.token.accessToken);
            this.userService.setAuth(response.data.user);
            this.router.navigate(['/mga']);
          }
        },
        (error) => {
          this.notificationService.error(
            'Error occurred while password reset',
            this.errorHandlingService.getErrors(error)
          );
        }
      );
  }
}
