import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class MainLayoutFooterComponent implements OnInit {
  searchForm: FormGroup;
  constructor(private fb: FormBuilder, private router: Router) {
    this.searchForm = fb.group({
      query: [''],
    });
  }

  onSearchSubmit() {
    this.router.navigate(['/products'], {
      queryParams: { search: this.searchForm.controls['query'].value },
    });
  }

  ngOnInit() {}
}
