import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-change-broker-account-status-dialog',
  templateUrl: './change-broker-account-status-dialog.component.html',
  styleUrls: ['./change-broker-account-status-dialog.component.scss'],
})
export class ChangeBrokerAccountStatusDialogComponent implements OnInit {
  currentStatus: any;
  finalStatus: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data.isAdmin) {
      this.currentStatus = {
        value: 'Broker Admin',
        badge: 'success',
      };
      this.finalStatus = {
        value: 'Broker',
        badge: 'warning',
      };
    } else {
      this.currentStatus = {
        value: 'Broker',
        badge: 'warning',
      };
      this.finalStatus = {
        value: 'Broker Admin',
        badge: 'success',
      };
    }
  }
}
