import { Injectable } from '@angular/core';
// api service
import { ApiService } from './api.service';

@Injectable()
export class DataFormatService {
  constructor(private apiService: ApiService) {}

  getIdsFromList(data: any[]): number[] {
    return data.map((item) => item.id);
  }

  sortBy(key: string, data: any[]): any[] {
    return data.sort((o1, o2) => {
      return o1[key] - o2[key];
    });
  }
}
