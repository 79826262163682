import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { InsurancePolicy } from '../models';
import { WordingTemplate } from '../models/wording-template.model';

@Injectable()
export class InsurancePolicyService {
  constructor(private apiService: ApiService) {}

  getList(): Observable<any> {
    return this.apiService
      .get(`/insurance-policy`)
      .pipe(map((res) => res.data));
  }

  getInfo(insurancePolicyId: number): Observable<InsurancePolicy> {
    return this.apiService
      .get(`/insurance-policy/${insurancePolicyId}`)
      .pipe(map((res) => res.data));
  }

  create(data = {}): Observable<any> {
    return this.apiService
      .post('/insurance-policy', data)
      .pipe(map((res) => res.data));
  }

  delete(insurancePolicyId: number): Observable<any> {
    return this.apiService.delete(`/insurance-policy/${insurancePolicyId}`);
  }

  update(insurancePolicyId: number, data = {}): Observable<any> {
    return this.apiService
      .put(`/insurance-policy/${insurancePolicyId}`, data)
      .pipe(map((res) => res.data));
  }

  getPolicyListByClientId(
    clientId: string,
    paramstr
  ): Observable<InsurancePolicy> {
    // PRAJAL3
    return (
      this.apiService
        // .get(`/insurance-policy/search?client_id=${clientId}`)
        .get(
          `/insurance-policy/search?client_id=${clientId}&requiredparms=${paramstr}`
        )
        .pipe(map((res) => res.data))
    );
  }

  saveWordingTemplate(data: any): Observable<WordingTemplate> {
    return this.apiService
      .post('/wordingtemplate', data)
      .pipe(map((res) => res.data));
  }

  searchByInsuranceProduct(data: any): Observable<any> {
    return this.apiService
      .get('/wordingtemplate/search', data)
      .pipe(map((res) => res.data));
  }

  updatePolicyWordingTemplate(
    templateId: number,
    templateData: any
  ): Observable<any> {
    return this.apiService
      .put(`/wordingtemplate/${templateId}`, templateData)
      .pipe(map((res) => res.data));
  }

  deletePolicyWordingTemplate(templateId: number): Observable<any> {
    return this.apiService.delete(`/wordingtemplate/${templateId}`);
  }

  getPolicyListByMgafirmId(
    mgaFirmId: string,
    paramstr
  ): Observable<InsurancePolicy> {
    // PRAJAL3
    return (
      this.apiService
        // .get(
        //   `/insurance-policy/search?insuranceproduct\\.mga_firm_id=${mgaFirmId}`
        // )
        .get(
          `/insurance-policy/search?insuranceproduct\\.mga_firm_id=${mgaFirmId}&requiredparms=${paramstr}`
        )
        .pipe(map((res) => res.data))
    );
  }

  getPolicyByBrokerId(brokerId: any, paramstr): Observable<any> {
    // PRAJAL3
    return this.apiService
      .get(
        // `/insurance-policy/search?quote\\.application\\.broker_id=${brokerId}`
        `/insurance-policy/search?quote\\.application\\.broker_id=${brokerId}&requiredparms=${paramstr}`
      )
      .pipe(map((res) => res.data));
  }
}
