import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-change-premium-amount',
  templateUrl: './change-premium-amount.component.html',
  styleUrls: ['./change-premium-amount.component.scss'],
})
export class ChangePremiumAmountComponent implements OnInit {
  premiumForm: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,

    private formBuilder: FormBuilder
  ) {
    this.premiumForm = this.formBuilder.group({
      premium_amount: [data?.result, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {}
}
