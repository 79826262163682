import {
  Component,
  OnInit,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
// service
import {
  NotificationService,
  ErrorHandlingService,
  FormValidatorService,
  PageDataService,
  UserService,
  AddressService,
} from '../../core/services';
// external
import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  @Output() userId = new EventEmitter<number>();
  searchedUser: any = [];
  isSubmitting = false;
  public textMask = {
    mask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: false,
  };
  signUpForm: FormGroup;
  sentMail: boolean;
  serverErrMsg = {
    email: [],
    response: null,
  };

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private formValidatorService: FormValidatorService,
    private _scrollToService: ScrollToService,
    private el: ElementRef,
    private errorHandlingService: ErrorHandlingService,
    private pageDataService: PageDataService,
    private notificationService: NotificationService
  ) {
    this.signUpForm = this.fb.group({
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.pageDataService.updateStepperState('createUser', {
      current: true,
      passed: false,
    });
  }

  isUserPresent(): void {
    if (this.signUpForm.valid) {
      this.signup(this.signUpForm.value);
    } else {
      this.formValidatorService.validateAllFormFields(this.signUpForm);
    }
  }

  submitForm(): void {
    this.isUserPresent();
  }

  scrollTop(): void {
    setTimeout(() => {
      const error = this.el.nativeElement.querySelector('div > .error');

      const config: ScrollToConfigOptions = {
        target: error.getAttribute('scroll-to') || 'scrollTop',
      };

      this._scrollToService.scrollTo(config);
    }, 1);
  }

  signup(signupFormData: FormGroup): any {
    this.userService.createClient(signupFormData).subscribe(
      (data) => {
        this.userId.emit(data?.data.userable_id);
      },
      (err) => {
        for (const error in this.errorHandlingService.getErrors(err)) {
          if (error) {
            this.notificationService.error(
              'Error occurred while creating user',
              this.errorHandlingService.getErrors(err)[error]
            );
          }
        }
      }
    );
  }
}
