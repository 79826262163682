import { Component, OnInit } from '@angular/core';
// models
import { Store } from 'src/app/core/models';
import { StoreService } from 'src/app/core/services/store.service';
// services
import { NotificationService, ErrorHandlingService } from '../../core/services';

@Component({
  selector: 'app-admin-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
})
export class StoresComponent implements OnInit {
  storeList: Store[] = [];
  displayedColumns = [
    'store_logo',
    'store_name',
    'firm_name',
    'products_count',
    'managers_count',
    'action',
  ];

  constructor(
    private storeService: StoreService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.fetchDataFromServer();
  }

  fetchDataFromServer(): void {
    this.storeService.getList().subscribe(
      (response) => {
        this.storeList = response;
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while fetching store list',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }
}
