import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import moment from 'moment';
// service
import {
  MediaService,
  InsurancePolicyService,
  NotificationService,
  ErrorHandlingService,
  ApplicationService,
} from 'src/app/core/services';
@Component({
  selector: 'app-policy-create',
  templateUrl: './policy-create.component.html',
  styleUrls: ['./policy-create.component.scss'],
})
export class PolicyCreateComponent implements OnInit {
  @Input() quoteData: any;
  @Input() clientData: any;
  policyForm: FormGroup;
  pendingLawSuitFiles: File[] = [];
  pendingLawSuiteMediaIds: string[] = [];
  isCreateButtonDisabled = false;
  premiumAmount: any;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private mediaService: MediaService,
    private insurancePolicyService: InsurancePolicyService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private applicationService: ApplicationService
  ) {}

  policyFormData(): void {
    this.policyForm = this.formBuilder.group({
      policy_number: ['', Validators.compose([Validators.required])],
      policy_description: ['', Validators.compose([Validators.required])],
      premium_amount: [this.quoteData?.application?.premium_amount],
      deductible_amount: [this.quoteData?.application?.deductible_amount],
      inception_date: [this.quoteData?.application?.inception_date],
      expiration_date: [this.quoteData?.application?.expiration_date],
    });
  }

  ngOnInit(): void {
    this.getPremiumAmount();
    this.policyFormData();
  }

  getPremiumAmount(): void {
    this.premiumAmount =
      this.quoteData.quote_coverage_variables[0].frozen_price +
      this.quoteData.quote_coverage_variables[1].frozen_price;
  }
  addEvent(data): void {
    const selectedDate = new Date(
      moment(data.target.value).format('YYYY-MM-DD')
    );
    this.policyForm.controls['inception_date'].setValue(
      selectedDate.toISOString().split('T')[0]
    );
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    const addedYear = new Date(year + 1, month, day);

    this.policyForm.controls['expiration_date'].setValue(
      addedYear.toISOString().split('T')[0]
    );
  }

  fetchApplicationInfo(): void {
    this.applicationService
      .getInfo(this.quoteData.application.id)
      .pipe(
        switchMap((applicationInfo) => {
          return this.applicationService.update(this.quoteData.application.id, {
            broker_id: applicationInfo?.broker?.userable_id,
            application_status_id: 9,
            client_id: applicationInfo?.client?.userable_id,
            business_id: applicationInfo?.business_id,
            submitted_to_mga_firm: true,
            medias: applicationInfo?.medias.map((item) => item.id),
            insurance_products: applicationInfo?.insurance_products?.map(
              (item) => item.id
            ),
            premium_amount: applicationInfo?.premium_amount,
            deductible_amount: applicationInfo?.deductible_amount,
            pending_lawsuit_medias: applicationInfo?.pending_lawsuit_medias
              ? applicationInfo?.pending_lawsuit_medias.map((item) => item.id)
              : [],
          });
        })
      )
      .subscribe(
        (res) => {
          this.notificationService.success(
            'Policy has been created successfully',
            ''
          );
          this.router.navigate([`/mga/policies`]);
        },
        (err) => {}
      );
  }
  onSelectLawSuitFiles(event): void {
    this.pendingLawSuitFiles.push(...event.addedFiles);
  }

  onRemoveLawSuitFiles(file): void {
    this.pendingLawSuitFiles.splice(this.pendingLawSuitFiles.indexOf(file), 1);
  }

  onChangeText(data: any): void {
    this.policyForm.get('policy_description').setValue(data);
  }

  savepolicyForm(): void {
    if (this.policyForm.valid) {
      this.isCreateButtonDisabled = true;
      this.mediaService
        .createMany(this.pendingLawSuitFiles)
        .pipe(
          switchMap((mediaCreateResponse) => {
            this.pendingLawSuiteMediaIds = mediaCreateResponse.data.map(
              (item) => item.id
            );
            return this.insurancePolicyService.create({
              policy_number: this.policyForm.value.policy_number,
              policy_description: this.policyForm.value.policy_description,
              premium_amount: this.quoteData?.application?.premium_amount,
              deductible_amount: this.quoteData?.application?.deductible_amount,
              inception_date: this.quoteData?.application?.inception_date,
              expiration_date: this.quoteData?.application?.expiration_date,
              insurance_product_id: this.quoteData?.insurance_product?.id,
              medias: this.pendingLawSuiteMediaIds,
              quote_id: this.quoteData.id,
              client_id: this.clientData.user.userable_id,
            });
          })
        )
        .subscribe(
          (policyCreateResponse) => {
            this.fetchApplicationInfo();
          },
          (err) => {
            this.isCreateButtonDisabled = false;
            this.notificationService.error(
              '',
              this.errorHandlingService.getErrors(err)
            );
          }
        );
    }
  }
}
