import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
// models
import { ProductCategory } from 'src/app/core/models';
// services
import {
  ErrorHandlingService,
  NotificationService,
  ProductService,
} from 'src/app/core/services';

@Component({
  selector: 'app-product-category-select',
  templateUrl: './product-category-select.component.html',
  styleUrls: ['./product-category-select.component.scss'],
})
export class ProductCategorySelectComponent implements OnInit {
  @Input() formControl: string;
  @Input() name: string;
  @Input() selectedProductCategory: any;
  @Output() selectedProdcutCategories = new EventEmitter<number>();

  productCategories: ProductCategory[];
  initialValue: string;

  constructor(
    private productService: ProductService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.fetchProductCategories();
    if (this.selectedProductCategory) {
      this.selectedProdcutCategories.emit(this.selectedProductCategory);
    }
  }

  fetchProductCategories(): void {
    this.productService.getCategoriesList().subscribe(
      (response) => {
        this.productCategories = response;
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while fetching product categories',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  onSelectChange(data: any): void {
    this.selectedProdcutCategories.emit(
      data.value || this.selectedProductCategory
    );
  }
}
