import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// types
import { IAddressResult } from 'src/app/shared/components/google-address-input/google-address-input.component';
// Service
import { IndustryService } from '../../../core/services/industry.service';
import { MediaService } from '../../../core/services/media.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ProfileService } from '../../../core/services/profile.service';
import { QuestionService } from '../../../core/services/question.service';
import { UserService } from '../../../core/services/user.service';
import { ApplicationService } from '../../../core/services/application.service';
import { BusinessService } from '../../../core/services/business.service';
import { AddressService } from '../../../core/services/address.service';
import { ErrorHandlingService } from '../../../core/services/error-handling.service';
// moment
import moment from 'moment';
// rxjs
import { forkJoin } from 'rxjs';

import { Router } from '@angular/router';
@Component({
  selector: 'app-application-create',
  templateUrl: './application-create.component.html',
  styleUrls: ['./application-create.component.scss'],
})
export class ApplicationCreateComponent implements OnInit {
  @Input() selectedCategories: any[] = [];
  @Input() selectedBrokerId: any;
  @Input() clientProfileData: any;
  @Input() questions: any;
  @Input() selectedClientDetails: any;
  @Output() moveBack = new EventEmitter<any>();
  applicationForm: FormGroup;
  pendingLawSuitFiles: any[] = [];
  additionalDocuments: any[] = [];
  uploadedMediaIds: string[] = [];
  ratingTable: any[] = [];
  preSelectedAddress: any;
  selectedCategoryId: any;
  ratingtableData: any;
  pendingLawSuites = false;
  selectedProductCategory;
  checkingCheckBox = { 5: 'pending_lawsuit', 6: 'is_operating_high_risk_area' };
  questionList: any;
  copyOfQuestionList = new Set();
  finalQuestionList: any;
  data: any[] = [];
  questionAndAnswerSet = new Set();
  retentionList = ['r1', 'r2', 'r3', 'r4', 'r5', 'r6', 'r7'];
  selectedRetention: {} = {};
  selectedLiabilityLimit: {} = {};
  submittedData: any;
  submitUpdatedData: any;
  currentUser: any;
  currentUserProfileInfo: any;
  profileInfoResponse: any;
  selectedLimit: any;
  questionMappingObj = {
    first_question: 'How many employees are within the company?',
    second_question: 'What is your annual projected revenue?',
    third_question: 'Are you currently under pending lawsuits?',
    forth_question: 'Date of incorporation',
    fifth_question: 'Are you operating in a high risk area?',
  };
  booleanMapping = { 0: false, 1: true };
  existingMediaIdsList: any[] = [];
  newUploadedFiles: any[] = [];
  preSelectedBusiness: any;
  userId: any = '';
  client_id = '';
  since_company_operation: any;
  pdfList: any[] = [];
  oldPdfFileInBlob: any[] = [];
  newPdfFiles: any[] = [];
  newMediaId: any[] = [];
  checkingStatus = false;
  highRiskAreaStatus = false;
  newAdditionalUploadedFiles: any[] = [];
  existingAdditionalMediaIdsList: any[] = [];
  uploadedAdditionalMediaIds: string[] = [];
  @Output() ratingVariableId = new EventEmitter<any>();

  //  @Output() moveBack = new EventEmitter<any>();
  @Output() saveApplicationInfo = new EventEmitter<any>();
  @Output() saveBusinessInfo = new EventEmitter<any>();
  @Output() saveUserInfo = new EventEmitter<any>();
  @Output() clientInfo = new EventEmitter<any>();
  //  @Output() ratingVariableId = new EventEmitter<any>();
  @Output() ilfData = new EventEmitter<any>();
  @Output() selectedRetantion = new EventEmitter<any>();
  // @Output() selectedLiabilityLimit = new EventEmitter<any>();
  // @ViewChild('nationalDropZone') componentRef?: any;
  dropzone: any;
  ilfValue: any;
  revenueAndLimitObj = {
    250: {
      '250k': 1.0,
      '500K': 1.5,
      '1M': 1.8,
      '2M': 2.0,
      '3M': 2.2,
      '5M': 2.5,
      '10M': 3.0,
    },
    500: {
      '250k': 0.7,
      '500K': 1.0,
      '1M': 1.4,
      '2M': 1.8,
      '3M': 2.0,
      '5M': 2.3,
      '10M': 2.8,
    },
    1000000: {
      '250k': 0.7,
      '500K': 1.0,
      '1M': 1.0,
      '2M': 1.35,
      '3M': 1.7,
      '5M': 2.1,
      '10M': 2.5,
    },
    3000000: {
      '250k': 0.3,
      '500K': 0.5,
      '1M': 0.6,
      '2M': 0.8,
      '3M': 1.0,
      '5M': 1.3,
      '10M': 2.0,
    },
    5000000: {
      '250k': 0.2,
      '500K': 0.3,
      '1M': 0.4,
      '2M': 0.5,
      '3M': 0.7,
      '5M': 1.0,
      '10M': 1.4,
    },
    10000000: {
      '250k': 0.2,
      '500K': 0.25,
      '1M': 0.5,
      '2M': 0.65,
      '3M': 0.7,
      '5M': 0.8,
      '10M': 1.0,
    },
  };
  constructor(
    private formBuilder: FormBuilder,
    private industryService: IndustryService,
    private questionService: QuestionService,
    private userService: UserService,
    private profileService: ProfileService,
    private mediaService: MediaService,
    private notificationService: NotificationService,
    private applicationService: ApplicationService,
    private businessService: BusinessService,
    private addressService: AddressService,
    private router: Router,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    if (this.clientProfileData?.medias) {
      this.pendingLawSuitFiles = this.clientProfileData?.medias;
    }
    if (this.clientProfileData?.pending_lawsuit_medias) {
      this.additionalDocuments = this.clientProfileData?.pending_lawsuit_medias;
    }
    this.questions?.forEach((element) => {
      if (
        element.value === 'How many years has the company been in operation?'
      ) {
        this.since_company_operation = element?.answer || '';
      }
      if (element.value === this.questionMappingObj['fifth_question']) {
        element['formControlName'] = 'high_risk_area';
        this.highRiskAreaStatus = this.booleanMapping[element?.answer];
      } else if (element.value === this.questionMappingObj['third_question']) {
        element['formControlName'] = 'pending_lawsuit';
        this.checkingStatus = this.booleanMapping[element?.answer];
      }
      delete element['id'];
      this.copyOfQuestionList.add(element);
    });
    this.preSelectedBusiness = this.clientProfileData?.business;

    this.client_id = this.clientProfileData?.client_id;
    this.showProfile();
    this.questionList = this.questions;

    this.preSelectedBusiness
      ? (this.preSelectedAddress =
          this.preSelectedBusiness?.address?.street_address +
          ', ' +
          this.preSelectedBusiness?.address?.city +
          ', ' +
          this.preSelectedBusiness?.address?.zip_code)
      : (this.preSelectedAddress = '');

    this.fetchCurrentUserInfo();
    this.ratingtableData = ['a', 'b', 'c', 'd', 'e'];
    // this.ratingVariableId.emit(this.clientProfileData?.business?.industry_id);
  }

  onPreselectedInd(industry): void {
    if (industry) {
      this.ratingVariableId.emit(industry);
      this.applicationForm.get('industry_id').setValue(industry?.id);
    }
  }
  showProfile(): void {
    this.applicationForm = this.formBuilder.group({
      company_name: [
        this.preSelectedBusiness?.name,
        Validators.compose([Validators.required]),
      ],
      business_number: [
        this.preSelectedBusiness?.business_number,
        Validators.compose([Validators.required]),
      ],
      industry_id: ['', Validators.compose([Validators.required])],
      street_address: [
        this.preSelectedBusiness
          ? this.preSelectedBusiness?.address?.street_address
          : '',
        Validators.compose([Validators.required]),
      ],
      number_of_employees: [
        this.preSelectedBusiness?.number_of_employees,
        Validators.compose([Validators.required]),
      ],
      projected_revenue: [
        this.preSelectedBusiness?.projected_revenue,
        Validators.compose([Validators.required]),
      ],
      city: [
        this.preSelectedBusiness ? this.preSelectedBusiness?.address?.city : '',
      ],
      zip_code: [
        this.preSelectedBusiness
          ? this.preSelectedBusiness?.address?.zip_code
          : '',
      ],
      country_id: [1],
      professional_certificate: [''],
      inception_date: ['', Validators.compose([Validators.required])],
      expiration_date: ['', Validators.compose([Validators.required])],
      business_inception_date: ['', Validators.compose([Validators.required])],
      pending_lawsuit: [false, Validators.compose([Validators.required])],
      high_risk_area: [],
      is_operating_high_risk_area: [''],
    });
    this.applicationForm.controls['business_inception_date'].setValue(
      this.since_company_operation ? this.since_company_operation : ''
    );
    this.applicationForm.controls['pending_lawsuit'].setValue(
      this.checkingStatus
    );
    this.applicationForm.controls['high_risk_area'].setValue(
      this.highRiskAreaStatus
    );
  }
  fetchCurrentUserInfo(): void {
    this.currentUser = this.userService.getCurrentUser();
  }

  addressChange(address: IAddressResult): void {
    this.applicationForm.get('street_address').setValue(address.street_address);
    this.applicationForm.get('city').setValue(address.city);
    this.applicationForm.get('zip_code').setValue(address.zip_code);
  }

  validateDateString(dateString: string): boolean {
    if (
      moment(dateString, 'MM/DD/YYYY', true).isValid() ||
      moment(dateString, 'MM/D/YYYY', true).isValid() ||
      moment(dateString, 'M/D/YYYY', true).isValid() ||
      moment(dateString, 'M/DD/YYYY', true).isValid()
    ) {
      return true;
    }
    return false;
  }

  addEvent(data): void {
    if (this.validateDateString(data.target.value)) {
      const selectedDate = new Date(
        moment(data.target.value).format('YYYY-MM-DD')
      );

      this.applicationForm.controls['inception_date'].setValue(
        selectedDate.toISOString().split('T')[0]
      );

      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      const day = selectedDate.getDate();
      const addedYear = new Date(year + 1, month, day);

      this.applicationForm.controls['expiration_date'].setValue(
        addedYear.toISOString().split('T')[0]
      );
    }
  }

  addExpirationEvent(data): void {
    if (this.validateDateString(data.target.value)) {
      const selectedDate = new Date(
        moment(data.target.value).format('YYYY-MM-DD')
      );
      this.applicationForm.controls['expiration_date'].setValue(
        selectedDate.toISOString().split('T')[0]
      );
    }
  }

  addBusinessInceptionEvent(data, question): void {
    if (this.validateDateString(data.target.value)) {
      const selectedDate = new Date(
        moment(data.target.value).format('YYYY-MM-DD')
      );
      this.applicationForm.controls['business_inception_date'].setValue(
        selectedDate.toISOString().split('T')[0]
      );
      question['answer'] = selectedDate.toISOString().split('T')[0];
      this.copyOfQuestionList.add(question);
    }
  }
  onSelectLawSuitFiles(event): void {
    this.pendingLawSuitFiles.push(...event.addedFiles);
  }

  onRemoveLawSuitFiles(file): void {
    this.pendingLawSuitFiles.splice(this.pendingLawSuitFiles.indexOf(file), 1);
  }

  onSelectAdditionalDocuments(event): void {
    this.additionalDocuments.push(...event.addedFiles);
  }

  onRemoveAdditionalDocuments(file): void {
    this.additionalDocuments.splice(this.additionalDocuments.indexOf(file), 1);
  }

  industrySelect(industry, question): void {
    question['answer'] = industry.name;
    question['question_id'] = question?.id
      ? question?.id
      : question?.question_id;
    this.copyOfQuestionList.add(question);
    this.ratingVariableId.emit(industry);
    this.applicationForm.get('industry_id').setValue(industry.id);
  }
  getSelectedProdcutCategories(id: any): void {
    this.ratingTable = [];
    this.selectedCategoryId = id;
    const filteredData = this.ratingtableData.filter((value) => {
      if (value.insurance_product_category_id === this.selectedCategoryId) {
        this.ratingTable.push(value);
      }
    });
  }

  // confirmProductCategory(data: any): void {}
  onChange(question, event): void {
    question['answer'] = event;
    question['question_id'] = question?.id
      ? question?.id
      : question?.question_id;
    if (this.questionMappingObj['third_question'] === question.value) {
      this.checkingStatus = event;
      this.applicationForm.get('pending_lawsuit').setValue(event);
    } else {
      this.highRiskAreaStatus = event;
      this.applicationForm.get('high_risk_area').setValue(event);
    }

    this.copyOfQuestionList.add(question);
    this.questionAndAnswerSet.add(question);
    this.pendingLawSuites = event;
  }

  setLimit(limit: any, question: any): void {
    question['answer'] = '';
    if (this.retentionList.includes(limit.value)) {
      this.selectedRetantion.emit(limit.value);
      // question['question_id'] = question?.id
      //   ? question?.id
      //   : question?.question_id;
      // question['answer'] = limit.value;
      // this.copyOfQuestionList.add(question);
    } else {
      // question['question_id'] = question?.id
      //   ? question?.id
      //   : question?.question_id;
      // question['answer'] = limit?.value ? limit?.value : '';
      this.selectedLimit = limit?.value ? limit?.value : '';
      // this.copyOfQuestionList.add(question);
    }
  }
  questionWithAnswerData(questionWithAnswer: any): void {
    if (
      questionWithAnswer.value === this.questionMappingObj['first_question']
    ) {
      this.applicationForm
        .get('number_of_employees')
        .setValue(questionWithAnswer?.answer);
    }
    if (
      questionWithAnswer.value === this.questionMappingObj['second_question']
    ) {
      this.applicationForm
        .get('projected_revenue')
        .setValue(questionWithAnswer?.answer);
    }
    if (
      questionWithAnswer.value === this.questionMappingObj['forth_question']
    ) {
      this.since_company_operation = questionWithAnswer?.answer;
    }
    this.copyOfQuestionList.add(questionWithAnswer);
  }

  async create(data): Promise<any> {
    this.pendingLawSuitFiles.map((media) => {
      if (media?.id) {
        this.existingMediaIdsList.push(media.id);
      } else {
        this.newUploadedFiles.push(media);
      }
    });
    // this.existingMediaIdsList;
    try {
      if (this.newUploadedFiles?.length > 0) {
        const mediaResponse = await this.mediaService
          .createMany([...this.newUploadedFiles])
          .toPromise();
        this.uploadedMediaIds = mediaResponse?.data?.map((item) => item.id);
        // TODO: Remove this later
        // setTimeout(() => {}, 500);
      }
      if (this.newAdditionalUploadedFiles?.length > 0) {
        const additionalMediaResponse = await this.mediaService
          .createMany([...this.newAdditionalUploadedFiles])
          .toPromise();
        this.uploadedAdditionalMediaIds = additionalMediaResponse?.data?.map(
          (item) => item.id
        );
      }
      this.additionalDocuments.map((media) => {
        if (media?.id) {
          this.existingAdditionalMediaIdsList.push(media.id);
        } else {
          this.newAdditionalUploadedFiles.push(media);
        }
      });
      // this.uploadedMediaIds = [];
      this.submittedData = {
        ...this.submittedData,
        medias: [...this.uploadedMediaIds, ...this.existingMediaIdsList],
        pending_lawsuit_medias: [
          ...this.uploadedAdditionalMediaIds,
          ...this.existingAdditionalMediaIdsList,
        ],
        // medias: this.uploadedMediaIds,
      };
      this.submitUpdatedData = {
        ...this.submitUpdatedData,
        medias: [...this.uploadedMediaIds, ...this.existingMediaIdsList],
        pending_lawsuit_medias: [
          ...this.uploadedAdditionalMediaIds,
          ...this.existingAdditionalMediaIdsList,
        ],
      };
      if (this.clientProfileData?.user_id) {
        // const date = new Date();
        // this.createApp(date, true);
        const profileResponse = await this.profileService
          .update(this.submitUpdatedData)
          .toPromise();
      } else {
        const profileResponse = await this.profileService
          .create(this.submittedData)
          .toPromise();
      }
    } catch {}
  }

  submitTestingApplicationForm(): void {
    this.submittedData = {
      user_id: this.selectedClientDetails?.user
        ? this.selectedClientDetails?.user?.id
        : this.selectedClientDetails?.id,
      client_id: this.selectedClientDetails?.user
        ? this.selectedClientDetails?.user?.userable_id
        : this.selectedClientDetails?.userable_id,
      version: 1,
      business: {
        name: this.applicationForm.value.company_name,
        business_number: this.applicationForm.value.business_number,
        number_of_employees: this.applicationForm.value.number_of_employees,
        projected_revenue: this.applicationForm.value.projected_revenue,
        owner_id: this.selectedClientDetails?.user
          ? this.selectedClientDetails?.user?.userable_id
          : this.selectedClientDetails?.userable_id,
        industry_id: this.applicationForm.value.industry_id
          ? this.applicationForm.value.industry_id
          : this.clientProfileData?.business?.industry_id,
        pending_lawsuit: this.applicationForm.value.pending_lawsuit
          ? this.applicationForm.value.pending_lawsuit
          : false,
        inception_date: this.applicationForm.value.business_inception_date,
      },
      address: {
        city: this.applicationForm.value.city,
        country_id: 1,
        street_address: this.applicationForm.value.street_address,
        zip_code: this.applicationForm.value.zip_code,
      },
      answers: [...this.copyOfQuestionList],
    };

    // Need to change the response. see index 0 and 1 object, both are different
    this.submitUpdatedData = {
      id: this.clientProfileData?.id,
      user_id: this.clientProfileData?.user_id,
      client_id: this.client_id,
      version: 1,
      business: {
        id: this.preSelectedBusiness?.id,
        name: this.applicationForm.value.company_name,
        business_number: this.applicationForm.value.business_number,
        number_of_employees: this.applicationForm.value.number_of_employees,
        projected_revenue: this.applicationForm.value.projected_revenue,
        owner_id: this.client_id,
        industry_id: this.applicationForm.value.industry_id
          ? this.applicationForm.value.industry_id
          : this.clientProfileData?.business?.industry_id,
        pending_lawsuit: this.applicationForm.value.pending_lawsuit
          ? this.applicationForm.value.pending_lawsuit
          : false,
        inception_date: this.applicationForm.value.business_inception_date,
      },
      address: {
        id: this.preSelectedBusiness?.address?.id,
        city: this.applicationForm.value.city,
        country_id: 1,
        street_address: this.applicationForm.value.street_address,
        zip_code: this.applicationForm.value.zip_code,
      },
      answers: [...this.copyOfQuestionList],
    };

    if (this.applicationForm.value.projected_revenue < 250000) {
      this.ilfValue = this.revenueAndLimitObj[250][this.selectedLimit];
      this.ilfData.emit(this.ilfValue);
    } else if (
      this.applicationForm.value.projected_revenue >= 250000 &&
      this.applicationForm.value.projected_revenue < 500000
    ) {
      this.ilfValue = this.revenueAndLimitObj[500][this.selectedLimit];
      this.ilfData.emit(this.ilfValue);
    } else if (
      this.applicationForm.value.projected_revenue >= 500000 &&
      this.applicationForm.value.projected_revenue < 1000000
    ) {
      this.ilfValue = this.revenueAndLimitObj[1000000][this.selectedLimit];
      this.ilfData.emit(this.ilfValue);
    } else if (
      this.applicationForm.value.projected_revenue >= 1000000 &&
      this.applicationForm.value.projected_revenue < 3000000
    ) {
      this.ilfValue = this.revenueAndLimitObj[3000000][this.selectedLimit];
      this.ilfData.emit(this.ilfValue);
    } else if (
      this.applicationForm.value.projected_revenue >= 3000000 &&
      this.applicationForm.value.projected_revenue < 5000000
    ) {
      this.ilfValue = this.revenueAndLimitObj[5000000][this.selectedLimit];
      this.ilfData.emit(this.ilfValue);
    } else if (
      this.applicationForm.value.projected_revenue >= 5000000 &&
      this.applicationForm.value.projected_revenue <= 10000000
    ) {
      this.ilfValue = this.revenueAndLimitObj[10000000][this.selectedLimit];
      this.ilfData.emit(this.ilfValue);
    }
    const date = new Date();
    if (this.clientProfileData?.user_id) {
      /* this.create(this.submitUpdatedData)
        .then((response) => {
          // this.notificationService.success(
          //   'Profile has been updated Successfully',
          //   ''
          // );
          //  this.router.navigate(['/client']);
        })
        .catch((error) => {
          this.notificationService.error(
            'Error occurred while updating Profile',
            this.errorHandlingService.getErrors(error)
          );
        }); */
      // Application create
      this.createApp(date, true).then(
        (applicationServiceResponse) => {
          this.notificationService.success(
            'Application created successfully',
            ''
          );
          //PRAJAL1
          // this.submittedData.application_id = applicationServiceResponse.id;
          // const applicationProfileResponse = this.profileService.create(this.submittedData)
          // .toPromise();
          this.saveApplicationInfo.emit(applicationServiceResponse);
          this.saveUserInfo.emit(applicationServiceResponse.client);
          this.saveBusinessInfo.emit(applicationServiceResponse.business);
          this.clientInfo.emit(applicationServiceResponse.client);
        },
        (err) => {}
      );
    } else {
      if (this.applicationForm.get('industry_id').value === '') {
        this.notificationService.error(
          'Form Error',
          'Please select an industry'
        );
      } else {
        /* this.create(this.submittedData)
          .then((response) => {
            this.notificationService.success(
              'Profile has been created Successfully',
              ''
            );
            // this.router.navigate(['/client']);
          })
          .catch((error) => {
            this.notificationService.error(
              'Error occurred while Creating Profile',
              this.errorHandlingService.getErrors(error)
            );
          }); */
        // Application create
        this.createApp(date, true).then(
          (applicationServiceResponse) => {
            this.notificationService.success(
              'Application created successfully',
              ''
            );
            //PRAJAL2
          // this.submittedData.application_id = applicationServiceResponse.id;
          // const applicationProfileResponse = this.profileService.create(this.submittedData)
          // .toPromise();
            this.saveApplicationInfo.emit(applicationServiceResponse);
            this.saveUserInfo.emit(applicationServiceResponse.client);
            this.saveBusinessInfo.emit(applicationServiceResponse.business);
            this.clientInfo.emit(applicationServiceResponse.client);
          },
          (err) => {}
        );
      }
    }
  }

  async createApp(date, isclientSearchedData): Promise<any> {
    // Media Upload
    this.pendingLawSuitFiles.map((media) => {
      if (media?.id) {
        this.existingMediaIdsList.push(media.id);
      } else {
        this.newUploadedFiles.push(media);
      }
    });
    // this.existingMediaIdsList;
    try {
      if (this.newUploadedFiles?.length > 0) {
        const mediaResponse = await this.mediaService
          .createMany([...this.newUploadedFiles])
          .toPromise();
        this.uploadedMediaIds = mediaResponse?.data?.map((item) => item.id);
        // TODO: Remove this later
        // setTimeout(() => {}, 500);
      }
      if (this.newAdditionalUploadedFiles?.length > 0) {
        const additionalMediaResponse = await this.mediaService
          .createMany([...this.newAdditionalUploadedFiles])
          .toPromise();
        this.uploadedAdditionalMediaIds = additionalMediaResponse?.data?.map(
          (item) => item.id
        );
      }
      this.additionalDocuments.map((media) => {
        if (media?.id) {
          this.existingAdditionalMediaIdsList.push(media.id);
        } else {
          this.newAdditionalUploadedFiles.push(media);
        }
      });
      // this.uploadedMediaIds = [];
      this.submittedData = {
        ...this.submittedData,
        medias: [...this.uploadedMediaIds, ...this.existingMediaIdsList],
        pending_lawsuit_medias: [
          ...this.uploadedAdditionalMediaIds,
          ...this.existingAdditionalMediaIdsList,
        ],
        // medias: this.uploadedMediaIds,
      };
      this.submitUpdatedData = {
        ...this.submitUpdatedData,
        medias: [...this.uploadedMediaIds, ...this.existingMediaIdsList],
        pending_lawsuit_medias: [
          ...this.uploadedAdditionalMediaIds,
          ...this.existingAdditionalMediaIdsList,
        ],
      };
      if (this.clientProfileData?.user_id) {
        // const date = new Date();
        // this.createApp(date, true);
        const profileResponse = await this.profileService
          .update(this.submitUpdatedData)
          .toPromise();
      } else {
        const profileResponse = await this.profileService
          .create(this.submittedData)
          .toPromise();
      }
    } catch {}

    // Application creation
    try {
      const addressCreateResponse = await this.addressService
        .create({
          street_address: this.applicationForm.value.street_address,
          city: this.applicationForm.value.city,
          zip_code: this.applicationForm.value.zip_code,
          country_id: 1,
        })
        .toPromise();
      // const selectedMonth = date.getMonth() + 1;

      // const monthData: any =
      //   selectedMonth <= 9 ? '0' + selectedMonth : selectedMonth;
      // const businessIncepitonDate =s
      //   date.getFullYear() - this.since_company_operation
      //     ? this.since_company_operation
      //     : '1990' + '-' + monthData + '-' + date.getDate();
      // console.log('createAppp:::::::::line 613:::::', businessIncepitonDate);
      let businessCreateResponse;
      // if (isclientSearchedData) {
      this.selectedClientDetails.id = this.selectedClientDetails.id
        ? this.selectedClientDetails?.id
        : (this.selectedClientDetails[
            'id'
          ] = this.selectedClientDetails?.user?.userable_id);

      businessCreateResponse = await this.businessService
        .create({
          name: this.applicationForm.value.company_name,
          business_number: this.applicationForm.value.business_number,
          inception_date: this.applicationForm.value.business_inception_date,
          number_of_employees: this.applicationForm.value.number_of_employees,
          projected_revenue: this.applicationForm.value.projected_revenue,
          business_address_id: addressCreateResponse.id,
          owner_id: this.client_id
            ? this.client_id
            : this.selectedClientDetails?.id,
          industry_id: this.applicationForm.value.industry_id
            ? this.applicationForm.value.industry_id
            : this.clientProfileData?.business?.industry_id,
          pending_lawsuit: this.applicationForm.value.pending_lawsuit
            ? this.applicationForm.value.pending_lawsuit
            : false,
          medias: [],
        })
        .toPromise();

      const applicationServiceResponse = await this.applicationService
        .create({
          broker_id: this.selectedBrokerId,
          application_status_id: 4,
          client_id: this.client_id
            ? this.client_id
            : this.selectedClientDetails?.id,
          business_id: businessCreateResponse.id,
          medias: [...this.uploadedMediaIds, ...this.existingMediaIdsList],
          pending_lawsuit_medias: [
            ...this.uploadedAdditionalMediaIds,
            ...this.existingAdditionalMediaIdsList,
          ],
          submitted_to_mga_firm: true,
          insurance_products: [],
          inception_date: this.applicationForm.value.inception_date,
          expiration_date: this.applicationForm.value.expiration_date,
          premium_amount: 1,
          deductible_amount: 2,
        })
        .toPromise();
        this.submittedData.application_id = applicationServiceResponse.id;
        const applicationProfileResponse = await this.profileService.create(this.submittedData)
        .toPromise();
      return applicationServiceResponse;
    } catch (error) {
      // this.isCreateButtonDisabled = false;
      this.notificationService.error(
        'Error occurred while submitting application',
        this.errorHandlingService.getErrors(error)
      );
    }
  }
}
