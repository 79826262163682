import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-profile-user-textbox',
  templateUrl: './profile-user-textbox.component.html',
  styleUrls: ['./profile-user-textbox.component.scss'],
})
export class ProfileUserTextboxComponent implements OnInit {
  @Input() question;
  @Input() preDefineValue;
  @Output() answer = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onChangeData(event: any, question: any): void {
    question['answer'] = event.target.value;
    question['question_id'] = question?.id
      ? question?.id
      : question?.question_id;
    delete question.id;
    // console.log('Event *********8:::', event.target.value);
    // console.log('question: with answer::', question);
    this.answer.emit(question);
  }
}
