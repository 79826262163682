import { Component, OnInit, Inject } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// form
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// file uploader
import { FileUploader } from 'ng2-file-upload';
// service
import {
  AddressService,
  BrokerService,
  MediaService,
  FirmService,
  NotificationService,
  ProductService,
  DataFormatService,
  UserService,
  ErrorHandlingService,
} from '../../core/services';

@Component({
  selector: 'app-broker-firm-create-dialog',
  templateUrl: './broker-firm-create-dialog.component.html',
  styleUrls: ['./broker-firm-create-dialog.component.scss'],
})
export class BrokerFirmCreateDialogComponent implements OnInit {
  brokerFirm: FormGroup;
  addressId: number;
  brokerFirmId: number;
  brokerId: number;
  brokerFirmName: string;
  productInformation: any;
  public uploader: FileUploader = new FileUploader({});

  constructor(
    private addressService: AddressService,
    private mediaService: MediaService,
    private brokerService: BrokerService,
    private firmService: FirmService,
    private userService: UserService,
    private productService: ProductService,
    private dataFormateService: DataFormatService,
    private notify: NotificationService,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<BrokerFirmCreateDialogComponent>,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.brokerFirm = this.fb.group({
      broker_first_name: ['', Validators.compose([Validators.required])],
      broker_last_name: ['', Validators.compose([Validators.required])],
      broker_firm_name: ['', Validators.compose([Validators.required])],
      beneficiary_business_name: [
        '',
        Validators.compose([Validators.required]),
      ],
      broker_firm_street_address: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z, A-Z]+'),
        ]),
      ],
      broker_email: ['', Validators.compose([Validators.required])],
      broker_phone_number: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z, A-Z]+'),
        ]),
      ],
      media: [null],
    });

    this.uploader.onAfterAddingFile = (f) => {
      this.brokerFirm.controls['media'].setValue(f._file);
    };
  }

  ngOnInit(): void {}

  submitForm(): void {
    this.firmService
      .createBrokerFirm({ broker_id: '' })
      .pipe(
        switchMap((brokerFirmResponse: any) => {
          this.brokerFirmId = brokerFirmResponse.id;
          return this.addressService.create({
            street_address: this.brokerFirm.value.broker_firm_street_address,
            city: 'Montreal',
            zip_code: 'K2b 123',
            country_id: 1,
          });
        }),
        switchMap((addressResponse: any) => {
          this.addressId = addressResponse.id;
          return this.mediaService.createMany([this.brokerFirm.value.media]);
        }),
        switchMap((mediaResponse: any) => {
          return this.firmService.create({
            firm_name: this.brokerFirm.value.broker_first_name,
            address_id: this.addressId,
            logo_id: mediaResponse.data[0].id,
            firmable_id: this.brokerFirmId,
            firmable_type: 'App\\Models\\Firm\\BrokerFirm',
          });
        }),
        switchMap((firmResponse: any) => {
          this.brokerFirmName = firmResponse.firm_name;
          return this.brokerService.create({
            phone_number: this.brokerFirm.value.broker_phone_number,
            broker_firm_id: this.brokerFirmId,
          });
        }),
        switchMap((brokerResponse: any) => {
          this.brokerId = brokerResponse.id;
          return this.userService.createUser({
            email: this.brokerFirm.value.broker_email,
            first_name: this.brokerFirm.value.broker_first_name,
            last_name: this.brokerFirm.value.broker_last_name,
            beneficiary_business_name: this.brokerFirm.value
              .beneficiary_business_name,
            business_address_id: 1,
            userable_id: brokerResponse.id,
            userable_type: 'App\\Models\\User\\Broker',
          });
        }),
        switchMap((userResponse) => {
          return this.productService.getInfo(1,'');
        }),
        switchMap((productResponse: any) => {
          return this.productService.update(this.data.productId, {
            name: productResponse.name,
            product_number: productResponse.product_number,
            company_name: productResponse.company_name,
            region: productResponse.region,
            aggregate_limit: productResponse.aggregate_limit,
            valid_from: '2020-12-12',
            valid_to: '2022-12-12',
            description: 'product description',
            logo_id: '1',
            mga_firm_id: '6',
            rating_table_id: 1,
            ratings_table_media_id: 1,
            brokers: [
              ...this.dataFormateService.getIdsFromList(
                productResponse.brokers
              ),
              this.brokerId,
            ],
            brokerfirms: [],
          });
        })
      )
      .subscribe(
        (response) => {
          this.close();
          this.notify.success(
            `Broker Firm : ${this.brokerFirmName}`,
            'Created successfully'
          );
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while submitting the form',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  close(): void {
    this.matDialogRef.close(false);
  }
}
