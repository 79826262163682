import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';

@Injectable()
export class RatingTableService {
  constructor(private apiService: ApiService) {}

  getList(): Observable<any> {
    return this.apiService.get(`/rating-table`).pipe(map((res) => res.data));
  }

  getInfo(ratingTableId: number): Observable<any> {
    return this.apiService
      .get(`/rating-table/${ratingTableId}`)
      .pipe(map((res) => res.data));
  }

  create(data = {}): Observable<any> {
    return this.apiService
      .post('/rating-table', data)
      .pipe(map((res) => res.data));
  }
  delete(ratingTableId: number): Observable<any> {
    return this.apiService.delete(`/rating-table/${ratingTableId}`);
  }
  update(ratingTableId: number, data = {}): Observable<any> {
    return this.apiService
      .put(`/rating-table/${ratingTableId}`, data)
      .pipe(map((res) => res.data));
  }

  searchRatingVariableRow(ratingTableId: number, data: {}): Observable<any> {
    return this.apiService
      .post(`/rating-variable-row/${ratingTableId}/search`, data)
      .pipe(map((res) => res.data));
  }
}
