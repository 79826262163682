import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
// service
import {
  EmailService,
  ApplicationService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
// rxjs
import { switchMap } from 'rxjs/operators';
const cardStyle = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

@Component({
  selector: 'app-quote-payment',
  templateUrl: './quote-payment.component.html',
  styleUrls: ['./quote-payment.component.scss'],
})
export class QuotePaymentComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @Input() productInfo: any;
  @Input() userInfo: any;
  @Input() applicationInfo: any;
  @Input() clientInfo: any;
  @Input() selectedRetantionClass: any;
  @Output() afterPayment = new EventEmitter<any>();
  productIdList: any;
  totalPaymentAmount: number;
  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string;
  mediaIds: any[] = [];
  retantion = [
    { id: 'r1', name: '500' },
    { id: 'r2', name: '750' },
    { id: 'r3', name: '1000' },
    { id: 'r4', name: '1500' },
    { id: 'r5', name: '2500' },
    { id: 'r6', name: '5000' },
    { id: 'r7', name: '7500' },
  ];
  selectedRetention: any;
  constructor(
    private cd: ChangeDetectorRef,
    private emailService: EmailService,
    private applicationService: ApplicationService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.applicationInfo?.medias?.map((item) => {
      this.mediaIds.push(item.id);
    });
    this.retantion.forEach((item) => {
      if (item.id === this.selectedRetantionClass) {
        this.selectedRetention = item.name;
      }
    });
    //  this.totalPaymentAmount;
    this.productIdList = this.productInfo.map((data) => {
      return data.id;
    });
  }
  ngAfterViewInit(): void {
    this.initiateCardElement();
  }

  ngOnDestroy(): void {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  initiateCardElement(): void {
    this.card = elements.create('card', { cardStyle });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  onChange({ error }): void {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }

  async createStripeToken(): Promise<any> {
    const { token, error } = await stripe.createToken(this.card);
    if (token) {
      this.onSuccess(token);
    } else {
      this.onError(error);
    }
  }

  onSuccess(token): void {
    this.productInfo.forEach((element) => {
      // need to loop prodculist and get procutlist ids and pass while updating applications
      this.emailService
        .sendQuoteSummaryEmail({
          client_email: this.userInfo.email,
          quote_id: element?.quote_id,
          quote_status: element?.quote_status?.status,
          quote_amount: element?.price,
          product_id: element?.id,
        })
        .pipe(
          switchMap(() => {
            return this.applicationService.update(this.applicationInfo?.id, {
              broker_id: this.applicationInfo?.broker?.userable_id,
              application_status_id: this.applicationInfo
                ?.application_status_id,
              client_id: this.applicationInfo?.client?.userable_id,
              business_id: this.applicationInfo?.business_id,
              submitted_to_mga_firm: true,
              medias: this.mediaIds,
              insurance_products: this.productIdList,
              premium_amount: element.price,
              deductible_amount: this.selectedRetention,
            });
          })
        )
        .subscribe(
          (updatedApplication) => {
            this.afterPayment.emit();
          },
          (error) => {
            this.notificationService.error(
              '',
              this.errorHandlingService.getErrors(error)
            );
          }
        );
    });
  }
  onError(error): void {
    if (error.message) {
      this.cardError = error.message;
    }
  }
}
