import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Router } from '@angular/router';
// service
import {
  ApplicationService,
  ErrorHandlingService,
  InsurancePolicyService,
  MediaService,
  NotificationService,
  QuoteService,
} from '../../../core/services';
// moment
import moment from 'moment';
// external
import * as FileSaver from 'file-saver';
// components
import { QuoteStatusDialogComponent } from 'src/app/shared/components/quote-status-dialog/quote-status-dialog.component';
import { ChangePremiumAmountComponent } from 'src/app/shared/components/change-premium-amount/change-premium-amount.component';
import { MatDialog } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-application-info',
  templateUrl: './application-info.component.html',
  styleUrls: ['./application-info.component.scss'],
})
export class ApplicationInfoComponent implements OnInit {
  id: any;
  application: any;
  quoteInfo: any;
  businessStartedDate: any;
  isBrokerInfoVisible = false;
  isProductInfoVisible = false;
  insuranceProductList: any[] = [];
  quoteByApplicationList: any;
  premiumValue: any;
  file: any;

  constructor(
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private quoteService: QuoteService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private insurancePolicyService: InsurancePolicyService,
    private mediaService: MediaService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = +params[`id`]; // (+) converts string 'id' to a number
    });

    this.getApplicationInfo();
    // this.fetchQuotesByApplicationId(); Not required removed by Prajal 16/06/2021
  }

  /* autoIssuePolicy(quoteId: number): void {
    const data = {
      insurance_products_id: this.id,
    };

    this.insurancePolicyService
      .searchByInsuranceProduct(data)
      .subscribe((response) => {
        if (
          response.length !== 0 &&
          this.application?.application_status.status === 'Paid'
        ) {
          let blob = null;
          const xhr = new XMLHttpRequest();
          xhr.open('GET', './assets/dummy.pdf');
          xhr.responseType = 'blob';
          xhr.onload = (): void => {
            blob = xhr.response;
          };

          this.mediaService
            .createMany([
              new File([blob], 'dummy.pdf', {
                type: 'image/png',
                lastModified: Date.now(),
              }),
            ])
            .pipe(
              switchMap((mediaCreateResponse) => {
                this.file = mediaCreateResponse.data.map((item) => item.id);
                return this.insurancePolicyService.create({
                  policy_number: '000000',
                  policy_description: 'test',
                  premium_amount: this.application?.premium_amount,
                  deductible_amount: this.application?.deductible_amount,
                  inception_date: this.application?.inception_date,
                  expiration_date: this.application?.expiration_date,
                  insurance_product_id: this.application?.insurance_products[0]
                    .id,
                  medias: this.file,
                  quote_id: quoteId,
                  client_id: this.application.client_id,
                });
              })
            )
            .subscribe(
              (policyCreateResponse) => {
                this.changeApplicationStatus();
              },
              (err) => {}
            );
        }
        // this.policyWordingTemplates = response;
      });
  } */

  getPdf(fileData: any): void {
    FileSaver.saveAs(fileData, `application.pdf`);
  }

  getApplicationInfo(): void {
    this.fetchApplicationInfo();
  }

  applicationUpdate(): void {}

  fetchApplicationInfo(): void {
    this.applicationService.getInfo(this.id).subscribe(
      (response) => {
        this.application = response;
        this.fetchQuoteInformation();
        const data = response?.insurance_products?.map((item) => {
          this.insuranceProductList.push(item.id);
        });
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while fetching application info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  // Not required removed by Prajal 16/06/2021
  // fetchQuotesByApplicationId(): void {
  //   this.quoteService.searchQuoteByApplicationId(this.id,'').subscribe(
  // application_status,medias,client,application_status,insurance_products,broker,business
  //     (res) => {
  //       this.quoteByApplicationList = res;
  //     },
  //     (err) => {}
  //   );
  // }

  fetchQuoteInformation(): void {
    this.quoteService
      .searchQuoteByApplicationIdAndProductId(
        this.id,
        this.application.insurance_products[0].id,
        'quote_status'
      )
      .subscribe(
        (res) => {
          this.quoteInfo = res[0];
          /* if (this.quoteInfo.quote_status.status === 'Paid') {
            this.autoIssuePolicy(this.quoteInfo.id);
          } */
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching quote info',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  changeQuoteStatus(): void {
    this.quoteService
      .updateQuoteStatus(this.quoteInfo?.id, { quote_status_id: 5 })
      .subscribe(
        (res) => {},
        (err) => {}
      );
  }
  openPrimumAmountChangeDialog(data): void {
    const dialogRef = this.dialog.open(ChangePremiumAmountComponent, {
      data: {
        result: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.premiumValue = result.value;
        this.applicationService
          .update(this.application?.id, {
            broker_id: this.application?.broker?.userable_id,
            application_status_id: this.application?.application_status_id,
            client_id: this.application?.client?.userable_id,
            business_id: this.application?.business_id,
            submitted_to_mga_firm: true,
            medias: this.application.medias.map((item) => item.id),
            insurance_products: this.insuranceProductList,
            premium_amount: result.value,
            deductible_amount: this.application.deductible_amount,
            pending_lawsuit_medias: this.application.pending_lawsuit_medias
              ? this.application.pending_lawsuit_medias.map((item) => item.id)
              : [],
          })
          .subscribe(
            (res) => {
              this.fetchApplicationInfo();
            },
            (err) => {}
          );
      }
    });
  }

  openQuoteStatusChangeDialog(data): void {
    const dialogRef = this.dialog.open(QuoteStatusDialogComponent, {
      data: {
        title: `Are you sure you want to change quote status from Non-Binding to Binding for client `,
        result: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.quoteService
          .updateQuoteStatus(result, { quote_status_id: 2 })
          .subscribe(
            (res) => {
              this.fetchQuoteInformation();
              this.updateEachApplication();
              this.changeQuoteStatus();
            },
            (err) => {
              this.notificationService.error(
                '',
                this.errorHandlingService.getErrors(err)
              );
            }
          );
      }
    });
  }

  updateEachApplication(): void {
    this.applicationService
      .getInfo(this.application?.id)
      .pipe(
        switchMap((applicationInfo) => {
          return this.applicationService.update(this.application?.id, {
            broker_id: applicationInfo?.broker?.userable_id,
            application_status_id: 5,
            client_id: applicationInfo?.client?.userable_id,
            business_id: applicationInfo?.business_id,
            submitted_to_mga_firm: true,
            medias: this.application.medias.map((item) => item.id),
            insurance_products: this.insuranceProductList,
            premium_amount: this.premiumValue || applicationInfo.premium_amount,
            deductible_amount: applicationInfo.deductible_amount,
            pending_lawsuit_medias: this.application.pending_lawsuit_medias
              ? this.application.pending_lawsuit_medias.map((item) => item.id)
              : [],
          });
        })
      )
      .subscribe(
        (res) => {
          this.fetchApplicationInfo();
        },
        (err) => {}
      );
  }

  handleBrokerInfoVisible(): void {
    this.isBrokerInfoVisible = !this.isBrokerInfoVisible;
  }

  handleProductInfoVisible(): void {
    this.isProductInfoVisible = !this.isProductInfoVisible;
  }

  goToAddPolicy(applicationInfo: any, quoteInfo: any): void {
    this.router.navigate(['/mga/policies/create'], {
      queryParams: {
        client_id: applicationInfo?.client_id,
        quote_id: quoteInfo?.id,
      },
    });
  }

  changeApplicationStatus(): void {
    this.applicationService
      .getInfo(this.id)
      .pipe(
        switchMap((applicationInfo) => {
          return this.applicationService.update(this.id, {
            broker_id: applicationInfo?.broker?.userable_id,
            application_status_id: 9,
            client_id: applicationInfo?.client?.userable_id,
            business_id: applicationInfo?.business_id,
            submitted_to_mga_firm: true,
            medias: applicationInfo?.medias.map((item) => item.id),
            insurance_products: applicationInfo?.insurance_products?.map(
              (item) => item.id
            ),
            premium_amount: applicationInfo?.premium_amount,
            deductible_amount: applicationInfo?.deductible_amount,
            pending_lawsuit_medias: applicationInfo?.pending_lawsuit_medias
              ? applicationInfo?.pending_lawsuit_medias.map((item) => item.id)
              : [],
          });
        })
      )
      .subscribe(
        (res) => {
          this.notificationService.success(
            'Policy has been created successfully',
            ''
          );
          // this.router.navigate([`/mga/policies`]);
        },
        (err) => {}
      );
  }
}
