import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PageDataService {
  private breadcrumbsSubject = new BehaviorSubject<Array<any>>([]);
  public breadcrumbs = this.breadcrumbsSubject.asObservable();
  private quoteStepperSubject = new BehaviorSubject<{
    chooseProduct: {
      current: boolean;
      passed: boolean;
    };
    createApplication: {
      current: boolean;
      passed: boolean;
    };
    policyDetails: {
      current: boolean;
      passed: boolean;
    };
    payment: {
      current: boolean;
      passed: boolean;
    };
  }>({
    chooseProduct: {
      current: true,
      passed: false,
    },
    createApplication: {
      current: false,
      passed: false,
    },
    policyDetails: {
      current: false,
      passed: false,
    },
    payment: {
      current: false,
      passed: false,
    },
  });
  public quoteStepper = this.quoteStepperSubject.asObservable();
  constructor() {
    this.setDefaultStateStepper();
  }

  setBreadCrumbs(breadcrumbs: Array<any>): void {
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  setDefaultStateStepper(): void {
    this.quoteStepperSubject.next({
      chooseProduct: {
        current: false,
        passed: false,
      },
      createApplication: {
        current: false,
        passed: false,
      },
      policyDetails: {
        current: false,
        passed: false,
      },
      payment: {
        current: false,
        passed: false,
      },
    });
  }

  updateStepperState(
    section: string,
    state: { current: boolean; passed: boolean }
  ): void {
    const stepperState = this.quoteStepperSubject.value;
    stepperState[section] = state;
    this.quoteStepperSubject.next(stepperState);
  }
}
