import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// external
import { FileUploadModule } from 'ng2-file-upload';
import { QuillModule } from 'ngx-quill';
import { BarRatingModule } from 'ngx-bar-rating';
import { TextMaskModule } from 'angular2-text-mask';
import { NgpImagePickerModule } from 'ngp-image-picker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

// Directives
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { StickyDirective } from './directives/sticky.directive';
import { CustomEmailValidator } from './directives/custom-email.validator';

// modules
import { MaterialModule } from '../material/material.module';

// layouts
import {
  AccountLayoutComponent,
  AdminLayoutComponent,
  BrokerLayoutComponent,
  ClientLayoutComponent,
  CustomHeaderComponent,
  MainLayoutComponent,
  MainLayoutHeaderComponent,
  MainLayoutFooterComponent,
  MgaLayoutComponent,
  QuoteHeaderComponent,
  QuoteLayoutComponent,
} from './layouts';

// components
import { ProductsListTableComponent } from './components/products-list-table/products-list-table.component';
import { DropDownMenuComponent } from './components/drop-down-menu/drop-down-menu.component';
import { ImagePickerComponent } from './components/image-picker/image-picker.component';
import { ProductsCardListComponent } from './components/lists/products-card-list/products-card-list.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { StoreManagersListComponent } from './components/lists/store-managers-list/store-managers-list.component';
import { RatingTableSelectComponent } from './components/lists/rating-table-select/rating-table-select.component';
import { RatingTableFormComponent } from './components/forms/rating-table-form/rating-table-form.component';
import { ProductCategorySelectComponent } from './components/product-category-select/product-category-select.component';
import { ProductCategoryCheckListComponent } from './components/product-category-check-list/product-category-check-list.component';
import { ApplicationsListTableComponent } from './components/lists/applications-list-table/applications-list-table.component';
import { RegionSelectComponent } from './components/lists/region-select/region-select.component';
import { ClientSearchComponent } from './components/client-search/client-search.component';
import { BusinessIndustrySelectComponent } from './components/business-industry-select/business-industry-select.component';
import { GoogleAddressInputComponent } from './components/google-address-input/google-address-input.component';
import { PolicyListTableComponent } from './components/lists/policy-list-table/policy-list-table.component';
import { QuoteListTableComponent } from './components/lists/quote-list-table/quote-list-table.component';
import { QuillEditorComponent } from './components/quill-editor/quill-editor.component';
import { ClientCreateComponent } from './components/client-create/client-create.component';
import { BrokerClientSearchComponent } from './components/broker-client-search/broker-client-search.component';
import { questionList } from './components/lists/questions-list/questions-list';
import { QuestionRangeComponent } from './components/question-range/question-range.component';
import { UserTextboxComponent } from './components/user-textbox/user-textbox.component';
import { UserBooleanSelectionComponent } from './components/user-boolean-selection/user-boolean-selection.component';
import { ClientCreateDialogComponent } from './dialog/client-create-dialog/client-create-dialog.component';
import { BrokerFirmCardListComponent } from './components/lists/broker-firm-card-list/broker-firm-card-list.component';
import { ChangeBrokerAccountStatusDialogComponent } from './components/change-broker-account-status-dialog/change-broker-account-status-dialog.component';
import { QuoteStatusDialogComponent } from './components/quote-status-dialog/quote-status-dialog.component';
import { BrokerListComponent } from './components/lists/broker-list/broker-list.component';
import { ChangePremiumAmountComponent } from './components/change-premium-amount/change-premium-amount.component';
import { MultipleProductCategorySelectionComponent } from './components/lists/multiple-product-category-selection/multiple-product-category-selection.component';
import { ProfileIndustrySelectComponent } from './components/profile-industry-select/profile-industry-select.component';
import { ProfileUserTextboxComponent } from './components/profile-user-textbox/profile-user-textbox.component';
import { ContactBrokerFormHomeComponent } from './components/forms/contact-broker-form-home/contact-broker-form-home.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    FileUploadModule,
    QuillModule,
    BarRatingModule,
    TextMaskModule,
    NgpImagePickerModule,
    NgxDropzoneModule,
    MatGoogleMapsAutocompleteModule,
  ],
  declarations: [
    // directives
    ClickOutsideDirective,
    StickyDirective,
    CustomEmailValidator,
    // layouts
    AccountLayoutComponent,
    AdminLayoutComponent,
    BrokerLayoutComponent,
    ClientLayoutComponent,
    CustomHeaderComponent,
    MainLayoutComponent,
    MainLayoutHeaderComponent,
    MainLayoutFooterComponent,
    MgaLayoutComponent,
    QuoteLayoutComponent,
    QuoteHeaderComponent,
    // components
    ProductsListTableComponent,
    DropDownMenuComponent,
    ImagePickerComponent,
    ProductsCardListComponent,
    DeleteDialogComponent,
    StoreManagersListComponent,
    RatingTableSelectComponent,
    RatingTableFormComponent,
    ProductCategorySelectComponent,
    ProductCategoryCheckListComponent,
    ApplicationsListTableComponent,
    RegionSelectComponent,
    ClientSearchComponent,
    BusinessIndustrySelectComponent,
    GoogleAddressInputComponent,
    PolicyListTableComponent,
    QuoteListTableComponent,
    QuillEditorComponent,
    ClientCreateComponent,
    BrokerClientSearchComponent,
    QuestionRangeComponent,
    UserTextboxComponent,
    UserBooleanSelectionComponent,
    ClientCreateDialogComponent,
    BrokerFirmCardListComponent,
    ChangeBrokerAccountStatusDialogComponent,
    QuoteStatusDialogComponent,
    BrokerListComponent,
    ChangePremiumAmountComponent,
    MultipleProductCategorySelectionComponent,
    ProfileIndustrySelectComponent,
    ProfileUserTextboxComponent,
    ContactBrokerFormHomeComponent,
  ],
  exports: [
    CommonModule,
    // directives
    ClickOutsideDirective,
    StickyDirective,
    CustomEmailValidator,
    // modules
    MaterialModule,
    FileUploadModule,
    QuillModule,
    BarRatingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TextMaskModule,
    // layouts
    AccountLayoutComponent,
    AdminLayoutComponent,
    BrokerLayoutComponent,
    ClientLayoutComponent,
    CustomHeaderComponent,
    MainLayoutComponent,
    MainLayoutHeaderComponent,
    MainLayoutFooterComponent,
    MgaLayoutComponent,
    QuoteLayoutComponent,
    QuoteHeaderComponent,
    MgaLayoutComponent,
    // components
    ProductsListTableComponent,
    DropDownMenuComponent,
    ImagePickerComponent,
    ProductsCardListComponent,
    StoreManagersListComponent,
    RatingTableSelectComponent,
    ProductCategorySelectComponent,
    ProductCategoryCheckListComponent,
    RegionSelectComponent,
    ClientSearchComponent,
    BusinessIndustrySelectComponent,
    GoogleAddressInputComponent,
    PolicyListTableComponent,
    QuoteListTableComponent,
    QuillEditorComponent,
    ApplicationsListTableComponent,
    ClientCreateComponent,
    BrokerClientSearchComponent,
    questionList,
    UserTextboxComponent,
    UserBooleanSelectionComponent,
    ClientCreateDialogComponent,
    BrokerFirmCardListComponent,
    ChangeBrokerAccountStatusDialogComponent,
    BrokerListComponent,
    ChangePremiumAmountComponent,
    MultipleProductCategorySelectionComponent,
    ProfileIndustrySelectComponent,
    ProfileUserTextboxComponent,
  ],
})
export class SharedModule {}
