import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
// service
import { EmailService } from 'src/app/core/services';

const cardStyle = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

@Component({
  selector: 'app-quote-payment',
  templateUrl: './quote-payment.component.html',
  styleUrls: ['./quote-payment.component.scss'],
})
export class QuotePaymentComponent implements AfterViewInit, OnDestroy {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @Input() productInfo: any;
  @Input() userInfo: any;
  @Output() afterPayment = new EventEmitter<any>();

  totalPaymentAmount: number;
  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string;

  constructor(
    private cd: ChangeDetectorRef,
    private emailService: EmailService
  ) {}

  ngAfterViewInit(): void {
    this.totalPaymentAmount = this.productInfo?.price;
    this.initiateCardElement();
  }

  ngOnDestroy(): void {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  initiateCardElement(): void {
    this.card = elements.create('card', { cardStyle });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  onChange({ error }): void {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }

  async createStripeToken(): Promise<any> {
    const { token, error } = await stripe.createToken(this.card);
    if (token) {
      this.onSuccess(token);
    } else {
      this.onError(error);
    }
  }
  onSuccess(token): void {
    this.emailService
      .sendQuoteSummaryEmail({
        client_email: this.userInfo.email,
        quote_id: this.productInfo?.quote_id,
        quote_status: this.productInfo?.quote_status?.status,
        quote_amount: this.productInfo?.price,
        product_id: this.productInfo?.id,
      })
      .subscribe(() => {
        this.afterPayment.emit();
      });
  }
  onError(error): void {
    if (error.message) {
      this.cardError = error.message;
    }
  }
}
