import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
// models
import { InsurancePolicy, User } from 'src/app/core/models';
// service
import {
  InsurancePolicyService,
  NotificationService,
  UserService,
  ErrorHandlingService,
} from 'src/app/core/services';
@Component({
  selector: 'app-mga-policy-list',
  templateUrl: './mga-policy-list.component.html',
  styleUrls: ['./mga-policy-list.component.scss'],
})
export class MgaPolicyListComponent implements OnInit {
  currentUserInfo: User;
  policies: any;
  displayedPolicyColumns = [
    'policy_number',
    'insurance_type',
    'Inception_date',
    'Expiration_date',
    'actions',
  ];
  constructor(
    private insurancePolicyService: InsurancePolicyService,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.currentUserInfo = this.userService.getCurrentUser();
    this.getInsurancePolicyList();
  }

  getInsurancePolicyList(): void {
    this.insurancePolicyService
      .getPolicyListByMgafirmId(
        this.currentUserInfo.userable.mga_firm_id,
        'insurance_product'
      )
      .subscribe(
        (response) => {
          this.policies = response;
        },
        (err) => {
          this.notificationService.error(
            'Error while fetching insurance policy list',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }
}
