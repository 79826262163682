import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// services
import {
  PageDataService,
  FirmService,
  QuestionService,
  NotificationService,
  ErrorHandlingService,
} from '../../core/services';

@Component({
  selector: 'app-quote-request',
  templateUrl: './quote-request.component.html',
  styleUrls: ['./quote-request.component.scss'],
})
export class QuoteRequestComponent implements OnInit {
  selectedCategories: any[] = [];
  hasProducts = false;
  hasQuote = false;
  applicationId: number;
  isSuccess = false;
  selectedBrokerId: string;
  applicationInfo: any;
  businessInfo: any;
  userInfo: any;
  selectedProduct: any;
  brokerFirmId: any;
  brokerList: any;
  questions: any;

  constructor(
    private router: Router,
    private pageDataService: PageDataService,
    private firmService: FirmService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private questionService: QuestionService
  ) {
    this.pageDataService.updateStepperState('chooseProduct', {
      current: true,
      passed: false,
    });
  }

  ngOnInit(): void {
    this.brokerFirmId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.fetchBrokerFirmInfo();
  }

  fetchBrokerFirmInfo(): void {
    this.firmService.getBrokerFirmInfo(this.brokerFirmId).subscribe(
      (response) => {
        this.brokerList = response.brokers;
        this.selectedBrokerId = this.brokerList[
          Math.floor(Math.random() * (this.brokerList.length - 0) + 0)
        ].id;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching broker firm info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  selectedCategoriesChange(selectedCategories): void {
    this.selectedCategories = selectedCategories;
    const selectedProductIds = this.selectedCategories.map((item) => item.id);
    this.questionService
      .byCategories({
        insurance_product_category_id: selectedProductIds,
      })
      .subscribe((response) => {
        this.questions = response;
      });
  }

  moveToApplicationCreate(): void {
    if (this.selectedCategories.length > 0) {
      this.pageDataService.updateStepperState('chooseProduct', {
        current: true,
        passed: true,
      });
      this.pageDataService.updateStepperState('createApplication', {
        current: true,
        passed: false,
      });
      this.hasProducts = true;
    }
  }

  moveToProducts(): void {
    this.pageDataService.updateStepperState('chooseProduct', {
      current: true,
      passed: false,
    });
    this.pageDataService.updateStepperState('createApplication', {
      current: false,
      passed: false,
    });
    this.pageDataService.updateStepperState('policyDetails', {
      current: false,
      passed: false,
    });
    this.pageDataService.updateStepperState('payment', {
      current: false,
      passed: false,
    });
    this.hasProducts = false;
  }

  moveToQuotePolicy(applicationId): void {
    this.applicationId = applicationId;
    this.pageDataService.updateStepperState('chooseProduct', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('createApplication', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('policyDetails', {
      current: true,
      passed: false,
    });
  }

  moveToPayment(): void {
    this.hasQuote = true;
    this.pageDataService.updateStepperState('chooseProduct', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('createApplication', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('policyDetails', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('payment', {
      current: true,
      passed: false,
    });
  }

  moveToSummary(): void {
    this.isSuccess = true;
    this.pageDataService.updateStepperState('chooseProduct', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('createApplication', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('policyDetails', {
      current: true,
      passed: true,
    });
    this.pageDataService.updateStepperState('payment', {
      current: true,
      passed: true,
    });
  }

  saveApplicationInfo(applicationInfo): void {
    this.applicationInfo = applicationInfo;
    this.moveToQuotePolicy(applicationInfo.id);
  }

  saveBusinessInfo(businessInfo): void {
    this.businessInfo = businessInfo;
  }

  saveUserInfo(userInfo): void {
    this.userInfo = userInfo;
  }

  saveSelectedProduct(product): void {
    this.selectedProduct = product;
  }
}
