import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// api service
import { ApiService, UserService } from 'src/app/core/services';
// models
import { Store } from '../models';

/**
 * This service contains all the services related to stores
 */
@Injectable()
export class StoreService {
  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {}

  getList(): Observable<Store[]> {
    return this.apiService.get(`/store`);
  }

  getInfo(storeId: number, paramstr: any): Observable<Store> { //PRAJAL
    if(paramstr) {
        return this.apiService
        .get(`/store/${storeId}?requiredparms=${paramstr}`)
        .pipe(map((res) => res.data));
      } else {
        return this.apiService
        .get(`/store/${storeId}`)
        .pipe(map((res) => res.data));
      }
  }

  create(storeData = {}, firmData = {}, storeManagerObj: any): Observable<any> {
    let mgaFirmId: any;
    return this.apiService.post(`/mga-firm`, {}).pipe(
      switchMap((mgaResponse) => {
        mgaFirmId = mgaResponse.data.id;

        return this.apiService.post('/firm', {
          ...firmData,
          firmable_id: mgaFirmId,
          firmable_type: 'App\\Models\\Firm\\MgaFirm',
        });
      }),
      switchMap((firmResponse) => {
        return this.userService.createStoreManager(
          {
            email: storeManagerObj.email,
            first_name: storeManagerObj.first_name,
            last_name: storeManagerObj.last_name,
            beneficiary_business_name: 'PeerCircle',
          },
          storeManagerObj.phone_number,
          mgaFirmId,
          1
        );
      }),
      switchMap((storeMangerResponse) => {
        return this.apiService.post('/store', {
          ...storeData,
          mga_firm_id: mgaFirmId,
          store_managers: [storeMangerResponse.data.userable_id],
        });
      })
    );
  }

  update(storeId: number, data = {}): Observable<Store> {
    return this.apiService
      .put(`/store/${storeId}`, data)
      .pipe(map((res) => res.data));
  }

  delete(storeId: string): Observable<any> {
    return this.apiService.delete(`/store/${storeId}`);
  }
}
