import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
// shared
import { QuoteLayoutComponent, SharedModule } from '../shared';
// components
import { QuoteRequestComponent } from './quote-request/quote-request.component';
import { QuoteProductComponent } from './quote-product/quote-product.component';
import { QuoteSuccessComponent } from './quote-success/quote-success.component';
import { QuoteApplicationComponent } from './quote-application/quote-application.component';
import { QuotePaymentComponent } from './quote-payment/quote-payment.component';
import { QuotePolicyComponent } from './quote-policy/quote-policy.component';
// external
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
// module
import { AccountModule } from '../account/account.module';

const quoteRouting: ModuleWithProviders<any> = RouterModule.forRoot([
  {
    path: 'get-quote',
    component: QuoteLayoutComponent,
    children: [
      {
        path: 'broker-firm/:id',
        component: QuoteRequestComponent,
        pathMatch: 'full',
        // resolve: {
        //   singleItem: QuoteRequestComponent,
        // },
      },
    ],
  },
]);

@NgModule({
  declarations: [
    QuoteRequestComponent,
    QuoteProductComponent,
    QuoteSuccessComponent,
    QuoteApplicationComponent,
    QuotePaymentComponent,
    QuotePolicyComponent,
  ],
  imports: [
    SharedModule,
    quoteRouting,
    NgxDropzoneModule,
    MatGoogleMapsAutocompleteModule,
    AccountModule,
  ],
})
export class QuoteModule {}
