import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// api service
import { ApiService, UserService } from 'src/app/core/services';
// models
import { Store } from '../models';

/**
 * This service contains all the services related to stores
 */
@Injectable()
export class StripeService {
  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {}

  create(data: any): Observable<any> {
    return this.apiService.post(`/stripe`, data);
  }

  fetchPaymentMethod(): Observable<Store> {
    return this.apiService
      .get(`/stripe/payment-methods`)
      .pipe(map((res) => res.data));
  }

  /// how to add payment id. in data obj or in URL
  payment(data: any): Observable<any> {
    return this.apiService.post(`/stripe/charge`, data);
  }

  findPaymentMethodUsingPaymentId(paymentId: any): Observable<any> {
    return this.apiService
      .get(`/stripe/${paymentId}`)
      .pipe(map((res) => res.data));
  }
}
