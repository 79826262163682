import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// service
import { UserService } from '../../../core/services/user.service';
@Component({
  selector: 'app-client-layout',
  templateUrl: './client-layout.component.html',
  styleUrls: ['./client-layout.component.scss'],
})
export class ClientLayoutComponent implements OnInit {
  currentUser: any;
  userInfo: any;
  constructor(private router: Router, private userService: UserService) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.fetchUserInformation();
  }

  handleLogout(): void {
    this.userService.purgeAuth();
    this.router.navigate(['/account/signin']);
  }

  handleProfile(): void {
    this.router.navigate(['/client/profile']);
  }

  handleSetting(): void {
    this.router.navigate(['/client/information']);
  }

  fetchUserInformation(): void {
    this.userService.getInfo(this.currentUser.id).subscribe(
      (res) => {
        this.userInfo = res.data;
      },
      (err) => {}
    );
  }
}
