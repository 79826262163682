import { Component, OnInit, Output, EventEmitter } from '@angular/core';
// services
import { ProductService } from 'src/app/core/services';
@Component({
  selector: 'app-product-category-check-list',
  templateUrl: './product-category-check-list.component.html',
  styleUrls: ['./product-category-check-list.component.scss'],
})
export class ProductCategoryCheckListComponent implements OnInit {
  @Output() selectedCategories = new EventEmitter<any>();

  productCategories: any[];

  constructor(private productService: ProductService) {}

  ngOnInit(): void {
    this.fetchProductCategories();
  }

  fetchProductCategories(): void {
    this.productService.getCategoriesList().subscribe((response) => {
      this.productCategories = response.map((item) => {
        return { ...item, selected: false };
      });
    });
  }

  checkboxModelChange(): void {
    const selected = this.productCategories.filter((item) => item.selected);
    this.selectedCategories.emit(selected);
  }
}
