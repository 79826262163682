import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';

@Injectable()
export class PaymentService {
  constructor(private https: HttpClient, private apiService: ApiService) {}

  paymentTransaction(data): Observable<any> {
    return this.apiService.post(`/payment`, data);
  }
}
