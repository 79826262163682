import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
// service
import {
  ErrorHandlingService,
  InsurancePolicyService,
  MediaService,
  NotificationService,
} from 'src/app/core/services';

import moment from 'moment';
import { switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
@Component({
  selector: 'app-policy-info',
  templateUrl: './policy-info.component.html',
  styleUrls: ['./policy-info.component.scss'],
})
export class PolicyInfoComponent implements OnInit {
  policyUpdateForm: FormGroup;
  isEditingMode = false;
  policyId: any;
  policyDeatail: any;
  pendingLawSuitFiles: File[] = [];
  pendingLawSuiteMediaIds: string[] = [];
  buttonFlag = false;
  preSelectedMedia: number;
  preSelectedMediaId: any;
  testNewMedia: any[] = [];

  testNewMedia2: any[] = [];
  // preSelectedMediaList: any[] = [];
  constructor(
    private router: Router,
    private insurancePolicyService: InsurancePolicyService,
    private mediaService: MediaService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private errorHandlingService: ErrorHandlingService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.policyId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.fetchPolicyDetails();
  }

  onSelectLawSuitFiles(event): void {
    this.testNewMedia.push(...event.addedFiles);
    this.preSelectedMedia = 1;
    this.pendingLawSuitFiles.push(...event.addedFiles);
  }

  onRemoveLawSuitFiles(file): void {
    this.pendingLawSuitFiles.splice(this.pendingLawSuitFiles.indexOf(file), 1);
    this.testNewMedia.splice(this.testNewMedia.indexOf(file), 1);
    this.testNewMedia2.splice(this.testNewMedia2.indexOf(file), 1);
  }

  toggleEditingMode(): void {
    this.isEditingMode = !this.isEditingMode;
  }

  getPdf(fileData: any): void {
    FileSaver.saveAs(fileData, `application.pdf`);
  }

  fetchPolicyDetails(): void {
    this.insurancePolicyService.getInfo(this.policyId).subscribe(
      (response) => {
        this.policyDeatail = response;

        this.createPolicyFormGroup();

        this.policyDeatail?.medias?.forEach((element) => {
          this.pendingLawSuitFiles.push(element);
          this.testNewMedia2.push(element);
        });
        this.preSelectedMediaId = this.policyDeatail?.media?.id;
        this.preSelectedMedia = 2;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching policy details',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  createPolicyFormGroup(): void {
    this.policyUpdateForm = this.formBuilder.group({
      policy_number: [
        this.policyDeatail?.policy_number,
        Validators.compose([Validators.required]),
      ],
      policy_description: [
        this.policyDeatail?.policy_description,
        Validators.compose([Validators.required]),
      ],
      premium_amount: [
        this.policyDeatail?.premium_amount,
        Validators.compose([Validators.required]),
      ],
      deductible_amount: [
        this.policyDeatail?.deductible_amount,
        Validators.compose([Validators.required]),
      ],
      inception_date: [
        moment(this.policyDeatail?.inception_date),
        Validators.compose([Validators.required]),
      ],
      expiration_date: [
        moment(this.policyDeatail?.expiration_date),
        Validators.compose([Validators.required]),
      ],
    });
  }

  onChangeText(data: any): void {
    this.policyUpdateForm.get('policy_description').setValue(data);
  }

  policyUpdateFormSubmit(): void {
    const preMediaIds = this.testNewMedia2?.map((item) => {
      return item.id;
    });
    if (this.policyUpdateForm.valid && this.testNewMedia.length > 0) {
      this.buttonFlag = true;
      this.mediaService
        .createMany(this.testNewMedia)
        .pipe(
          switchMap((mediaCreateResponse) => {
            this.pendingLawSuiteMediaIds = mediaCreateResponse.data.map(
              (item) => item.id
            );
            return this.insurancePolicyService.update(this.policyId, {
              policy_number: this.policyUpdateForm.value.policy_number,
              policy_description: this.policyUpdateForm.value
                .policy_description,
              premium_amount: this.policyUpdateForm.value.premium_amount,
              deductible_amount: this.policyUpdateForm.value.deductible_amount,
              inception_date: this.policyUpdateForm.value.inception_date.format(
                'YYYY-MM-DD'
              ),
              expiration_date: this.policyUpdateForm.value.expiration_date.format(
                'YYYY-MM-DD'
              ),
              insurance_product_id: 1,
              medias: [...preMediaIds, ...this.pendingLawSuiteMediaIds],
              quote_id: this.policyDeatail?.quote_id,
              client_id: this.policyDeatail?.client_id,
            });
          })
        )
        .subscribe(
          (policyUpdateResponse) => {
            this.notificationService.success(
              'Policy has been updated successfully',
              ''
            );
            this.router.navigate(['/mga/policies']);
          },
          (err) => {
            this.buttonFlag = false;
            this.notificationService.error(
              'Error occurred while submitting form',
              this.errorHandlingService.getErrors(err)
            );
          }
        );
    } else if (this.policyUpdateForm.valid && this.testNewMedia.length === 0) {
      this.buttonFlag = true;
      this.insurancePolicyService
        .update(this.policyId, {
          policy_number: this.policyUpdateForm.value.policy_number,
          policy_description: this.policyUpdateForm.value.policy_description,
          premium_amount: this.policyUpdateForm.value.premium_amount,
          deductible_amount: this.policyUpdateForm.value.deductible_amount,
          inception_date: this.policyUpdateForm.value.inception_date.format(
            'YYYY-MM-DD'
          ),
          expiration_date: this.policyUpdateForm.value.expiration_date.format(
            'YYYY-MM-DD'
          ),
          insurance_product_id: 1,
          medias: preMediaIds,
          quote_id: this.policyDeatail?.quote_id,
          client_id: this.policyDeatail?.client_id,
        })
        .subscribe(
          (policyUpdateResponse) => {
            this.notificationService.success(
              'Policy has been updated successfully',
              ''
            );
            this.router.navigate(['/mga/policies']);
          },
          (err) => {
            this.buttonFlag = false;
            this.notificationService.error(
              'Error occurred while submitting form',
              this.errorHandlingService.getErrors(err)
            );
          }
        );
    }
  }
}
