import { Component, OnInit } from '@angular/core';
// service
import {
  NotificationService,
  ProductService,
  ErrorHandlingService,
  UserService,
} from '../../core/services';
// dialog
import { MatDialog } from '@angular/material/dialog';
// components
import { DeleteDialogComponent } from '../../shared/components/delete-dialog/delete-dialog.component';
// models
import { Product } from 'src/app/core/models';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  displayedColumns = [
    'name',
    'product_number',
    'number_of_broker_firms',
    'region',
    'rating_table',
    'valid_from',
    'valid_to',
    'actions',
  ];
  products: any;
  userInfo: any;
  constructor(
    public dialog: MatDialog,
    private productService: ProductService,
    private errorHandlingService: ErrorHandlingService,
    private notificationService: NotificationService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.userService.getCurrentUser();
    this.fetchProductList();
  }

  fetchProductList(): void {
    this.productService
      .searchProductsByMgaFirmId(
        this.userInfo.userable.mga_firm_id,
        'brokerfirms,rating_table'
      )
      .subscribe(
        (response) => {
          this.products = response;
        },
        (error) => {
          this.notificationService.error(
            '',
            this.errorHandlingService.getErrors(error)
          );
        }
      );
  }

  openDeleteDialog(data): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'are you sure you want to delete this product?',
        result: data.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.productService.delete(result).subscribe((res) => {
          this.notificationService.success(
            'Product has been Deleted',
            data.name
          );
          this.fetchProductList();
        });
      }
    });
  }
}
