import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// shared
import { CustomEmailValidator } from 'src/app/shared';
// services
import {
  FormValidatorService,
  StoreService,
  UserService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
// models
import { Store } from 'src/app/core/models';

@Component({
  selector: 'app-store-manager-create',
  templateUrl: './store-manager-create.component.html',
  styleUrls: ['./store-manager-create.component.scss'],
})
export class StoreManagerCreateComponent implements OnInit {
  storeManagerForm: FormGroup;
  storeId: number;
  storeInfo: Store;
  buttonFlag = false;
  phoneNumber: any;
  selectedOption = 0;
  storeManagerType: any[] = [
    { name: 'Yes', id: 1 },
    { name: 'No', id: 0 },
  ];
  storeManageList: any;
  newStoreManagerId: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private formValidatorService: FormValidatorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.createStoreManagerFormGroup();
  }

  ngOnInit(): void {
    this.storeId = parseInt(
      this.activatedRoute.snapshot?.paramMap?.get('storeId'),
      10
    );
    this.fetchStoreInfo();
  }

  fetchStoreInfo(): void {
    this.storeService.getInfo(this.storeId,null).subscribe(
      (response) => {
        this.storeInfo = response;
        this.storeManageList = response.mga_firm.store_managers.map(
          (item) => item.id
        );
      },
      (error) => {
        this.notificationService.error(
          '',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  createStoreManagerFormGroup(): void {
    this.storeManagerForm = this.formBuilder.group({
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      phone_number: [
        '',
        Validators.compose([Validators.required, Validators.pattern('[0-9]+')]),
      ],
      store_manager_type: [''],
    });
  }
  formatPhoneNumber(event: any): string {
    const cleaned = ('' + event).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      this.phoneNumber =
        '(' + match[1] + ') ' + '- ' + match[2] + '- ' + match[3];
    }
    return null;
  }

  updateStoreInfo(): void {
    this.storeService
      .update(this.storeInfo.id, {
        name: this.storeInfo?.name,
        cover_media_id: this.storeInfo?.cover_media_id,
        mga_firm_id: this.storeInfo?.mga_firm_id,
        store_managers: [...this.storeManageList, ...this.newStoreManagerId],
      })
      .subscribe(
        () => {},
        (err) => {
          this.notificationService.error(
            '',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }
  createStoreManager(): void {
    if (this.storeManagerForm.valid) {
      this.buttonFlag = true;
      this.userService
        .createStoreManager(
          {
            email: this.storeManagerForm.get('email').value,
            first_name: this.storeManagerForm.get('first_name').value,
            last_name: this.storeManagerForm.get('last_name').value,
            beneficiary_business_name: 'PeerCircle',
          },
          this.storeManagerForm.get('phone_number').value,
          this.storeInfo.mga_firm_id,
          this.storeManagerForm.get('store_manager_type').value
        )
        .subscribe(
          (response) => {
            this.newStoreManagerId.push(response?.data?.userable_id);
            this.updateStoreInfo();

            this.notificationService.success(
              '',
              'Store Manager has been created SuccessFully'
            );
            this.router.navigate(['/mga/information/']);
          },
          (error) => {
            this.buttonFlag = false;
            this.notificationService.error(
              '',
              this.errorHandlingService.getErrors(error)
            );
          }
        );
    } else {
      this.buttonFlag = false;
      this.formValidatorService.validateAllFormFields(this.storeManagerForm);
    }
  }
}
