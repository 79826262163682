import { Component, OnInit, Output, EventEmitter } from '@angular/core';
// services
import { ProductService, QuestionService } from '../../../core/services';
// lodash
import { find } from 'lodash';

import moment from 'moment';
@Component({
  selector: 'app-quote-product',
  templateUrl: './quote-product.component.html',
  styleUrls: ['./quote-product.component.scss'],
})
export class QuoteProductComponent implements OnInit {
  insuranceProductCategory: [] = [];
  public displayAllColumns = ['product_category_name', 'select'];
  @Output() selectedCategories = new EventEmitter<any>();
  insuranceCategories: any[];
  constructor(
    private productService: ProductService,
    private questionService: QuestionService
  ) {}

  ngOnInit(): void {
    this.fetchProducts();
  }

  fetchProducts(): void {
    this.productService.getCategoriesList().subscribe((response) => {
      this.insuranceCategories = response;
    });
  }

  checkboxModelChange(): void {
    const selected = this.insuranceCategories.filter((item) => item.selected);
    this.selectedCategories.emit(selected);
  }
}
