import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// service
import {
  StripeService,
  EftPaymentService,
  ApplicationService,
  NotificationService,
  UserService,
  PaymentService,
} from '../../../core/services';
@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss'],
})
export class ChargeComponent implements OnInit {
  voPayForm: FormGroup;
  applicationId: any;
  productId: any;
  premiumAmount: any;
  finalData = {};
  token: any;
  applicationInfo: any;
  loginUser: any;
  voPayTransactionId: any;
  isSuccess = false;
  paymentTransactionData: any;
  constructor(
    private eftPaymentService: EftPaymentService,
    private activatedRoute: ActivatedRoute,
    private applicationService: ApplicationService,
    private fb: FormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private paymentService: PaymentService,
    private userService: UserService
  ) {}

  voPayFoum(): void {
    this.voPayForm = this.fb.group({
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      amount: [this.premiumAmount],
    });
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.applicationId = id;
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params['Token'];
    });
    this.fetchApplicationInfo();
    this.voPayFoum();
    //  this.fetchEtfData(formdata);
    this.loginUser = this.userService.getCurrentUser();
  }
  fetchApplicationInfo(): void {
    this.applicationService.getInfo(this.applicationId).subscribe(
      (res) => {
        this.applicationInfo = res;
        this.productId = res?.insurance_products[0].id;
        this.premiumAmount = res?.premium_amount;
        this.voPayForm.controls.amount.setValue(res?.premium_amount);
        //  this.voPayFoum();
      },
      (err) => {}
    );
  }
  fetchEtfData(data): void {
    this.eftPaymentService.postEtfPayment(data).subscribe(
      (res) => {
        this.voPayTransactionId = res?.TransactionID;
        this.notificationService.success(
          'Your payment has been processed successfully. A policy will be issued to you shortly!'
        );
        this.changeApplicationStatus();
      },
      (err) => {}
    );
  }
  changeApplicationStatus(): void {
    const data = {
      statusId: 10,
      id: this.applicationId,
    };
    this.applicationService.changeApplicationStatus(data).subscribe(
      (res) => {
        this.storePaymentTransaction();
        // this.router.navigate([
        //   '/client/applications/',
        //   this.applicationId,
        //   'payment',
        //   'success',
        // ]);
      },
      (err) => {
        this.notificationService.error(
          'Application status does not change. Please contact admin',
          ''
        );
      }
    );
  }

  storePaymentTransaction(): void {
    const paymentTransactionObject = {};
    paymentTransactionObject['status'] = 'Paid';
    paymentTransactionObject['insurance_products_id'] = this.productId;
    paymentTransactionObject['user_id'] = this.loginUser?.id;
    paymentTransactionObject['application_id'] = this.applicationInfo.id;
    paymentTransactionObject['external_payment_id'] = this.voPayTransactionId;
    paymentTransactionObject['payment_type'] = 'VOPAY';
    this.paymentService.paymentTransaction(paymentTransactionObject).subscribe(
      (res) => {
        this.paymentTransactionData = res.data;
        this.isSuccess = true;
      },
      (err) => {}
    );
  }

  submitForm(): void {
    if (this.voPayForm.valid) {
      // this.finalData = this.voPayForm.value;
      this.finalData['AccountID'] = 'wealthtab';
      this.finalData['Token'] = this.token;
      this.finalData['FirstName'] = this.voPayForm.value.first_name;
      this.finalData['LastName'] = this.voPayForm.value.last_name;
      this.finalData['EmailAddress'] = this.voPayForm.value.email;
      this.finalData['Amount'] = 100;
      this.notificationService.info('Please wait', 'Payment in process', {
        toastId: 999,
        timeOut: 100000,
        // preventDuplicates:true,
        // resetTimeoutOnDuplicate:true,
        positionClass: 'toast-bottom-right',
      });
      // TODO: Once user allow to pay more than $10,000 uncomment bottom line
      // this.finalData['Amount'] = this.voPayForm.value.amount;
      this.fetchEtfData(this.finalData);
    }
  }
}
