import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
// image picker
import { ImagePickerConf } from 'ngp-image-picker';
@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
})
export class ImagePickerComponent implements OnInit {
  @Input() imagePickerConfig: ImagePickerConf;
  @Input() initialImage: string;
  @Output() imageChanged = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    // this.imageChanged.emit(this.initialImage);
  }

  onImageChanged(event): void {
    this.imageChanged.emit(event);
  }
}
