import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating-table-create',
  templateUrl: './rating-table-create.component.html',
  styleUrls: ['./rating-table-create.component.scss'],
})
export class RatingTableCreateComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
