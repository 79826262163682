import { Component, OnInit } from '@angular/core';
// service
import {
  ClientService,
  UserService,
  NotificationService,
  ErrorHandlingService,
} from '../../core/services';
@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ApplicationsComponent implements OnInit {
  currentUserInfo: any;
  displayedColumns: any;
  applicationList: any;

  constructor(
    private userService: UserService,
    private clientService: ClientService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.displayedColumns = [
      'business_name',
      'business_email',
      'date_submitted',
      'application_status',
      'payment_status',
    ];

    this.getUserInfo();
    this.fetchApplicationsList();
  }

  getUserInfo(): void {
    this.currentUserInfo = this.userService.getCurrentUser();
  }

  fetchApplicationsList(): void {
    this.clientService
      .getApplicationList(
        this.currentUserInfo.userable_id,
        'application_status,client,business'
      )
      .subscribe(
        (response) => {
          this.applicationList = response.data;
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching applications list',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }
}
