import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
// external
import { ImagePickerConf } from 'ngp-image-picker';
import { switchMap } from 'rxjs/operators';
// services
import {
  FormValidatorService,
  MediaService,
  ProductService,
  StoreService,
  NotificationService,
  ErrorHandlingService,
  UserService,
} from 'src/app/core/services';
// models
import { Store } from 'src/app/core/models';
// components
import { RatingTableFormComponent } from 'src/app/shared/components/forms/rating-table-form/rating-table-form.component';

import moment from 'moment';
@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss'],
})
export class ProductCreateComponent implements OnInit {
  productForm: FormGroup;
  storeId: number;
  storeInfo: Store;
  ratingtableData: any;
  selectedCategoryId: any;
  ratingTable: any[] = [];
  buttonFlag = false;
  setValueTo: any;
  userInfo: any;
  // image picker config
  imagePickerConfig: ImagePickerConf = {
    borderRadius: '4px',
    language: 'en',
    width: '100%',
    height: '172px',
  };
  /* isUnderwriterReviewOptions: [

  ] */

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private mediaService: MediaService,
    private formValidatorService: FormValidatorService,
    private storeService: StoreService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private userService: UserService
  ) {
    this.productForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],
      product_logo: ['', Validators.compose([Validators.required])],
      product_number: ['', Validators.compose([Validators.required])],
      region: ['', Validators.compose([Validators.required])],
      valid_from: ['', Validators.compose([Validators.required])],
      is_underwriter_review: [1, Validators.compose([Validators.required])],
      valid_to: ['', Validators.compose([Validators.required])],
      rating_table_id: ['', Validators.compose([Validators.required])],
      terms_and_conditions: [''],
      insurance_product_category_id: [
        '',
        Validators.compose([Validators.required]),
      ],
    });
  }

  ngOnInit(): void {
    this.userInfo = this.userService.getCurrentUser();
    this.storeId = this.userInfo?.stores?.id;
    this.fetchStoreInfo();
  }

  addEvent(data): void {
    const selectedDate = new Date(
      moment(data.target.value).format('YYYY-MM-DD')
    );
    this.productForm.controls['valid_from'].setValue(
      selectedDate.toISOString().split('T')[0]
    );
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    const addedYear = new Date(year + 1, month, day);

    this.productForm.controls['valid_to'].setValue(
      addedYear.toISOString().split('T')[0]
    );
  }

  fetchStoreInfo(): void {
    this.storeService.getInfo(this.storeId,null).subscribe(
      (response) => {
        this.storeInfo = response;
        this.ratingtableData = response?.mga_firm?.rating_tables;
        this.ratingTable = this.ratingtableData.filter(
          (data) =>
            data.insurance_product_category_id === this.selectedCategoryId
        );
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while fetching store info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  onChangeText(data: any): void {
    this.productForm.get('description').setValue(data);
  }

  onImageChanged(file): void {
    this.productForm.get('product_logo').setValue(file);
  }

  handleRatingTableSelect(ratingTableId): void {
    this.productForm.get('rating_table_id').setValue(ratingTableId);
  }

  getSelectedProdcutCategories(id: any): void {
    this.selectedCategoryId = id;
    this.ratingTable = this.ratingtableData.filter(
      (data) => data.insurance_product_category_id === this.selectedCategoryId
    );
  }

  openRatingTableDialog(): void {
    const dialogRef = this.dialog.open(RatingTableFormComponent, {
      width: '50%',
      data: { ...this.storeInfo, selectedCategoryId: this.selectedCategoryId },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchStoreInfo();
      }
    });
  }

  showSuccessNotification(data): void {
    this.notificationService.success(
      data.name,
      'product has been created successfully'
    );
    this.router.navigate(['/mga/products/']);
  }

  saveProductForm(): void {
    if (this.productForm.valid) {
      this.buttonFlag = true;
      this.mediaService
        .create(this.productForm.get('product_logo').value)
        .pipe(
          switchMap((mediaResponse) => {
            return this.productService.create({
              name: this.productForm.value.name,
              description: this.productForm.value.description,
              product_logo: this.productForm.value.product_logo,
              product_number: this.productForm.value.product_number,
              region: this.productForm.value.region.map((x) => x).join(','),
              aggregate_limit: 1,
              rating_table_id: this.productForm.value.rating_table_id,
              terms_and_conditions: this.productForm.value.terms_and_conditions,
              insurance_product_category_id: this.productForm.value
                .insurance_product_category_id,
              store_id: this.storeId,
              logo_id: mediaResponse.id,
              valid_from: this.productForm.value.valid_from,
              valid_to: new Date(
                moment(this.productForm.value.valid_to).format('YYYY-MM-DD')
              )
                .toISOString()
                .split('T')[0],
              brokerfirms: [],
              brokers: [],
              is_underwriting_review_req: this.productForm.value
                .is_underwriter_review,
            });
          })
        )
        .subscribe(
          (response) => {
            this.showSuccessNotification(response);
          },
          (error) => {
            this.notificationService.error(
              'Error occurred while submitting product form',
              this.errorHandlingService.getErrors(error)
            );
            this.buttonFlag = false;
          }
        );
    } else {
      this.formValidatorService.validateAllFormFields(this.productForm);
    }
  }
}
