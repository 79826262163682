import { Injectable } from '@angular/core';
// rxjs
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { Product, ProductCategory } from '../models';

@Injectable()
export class ProductService {
  constructor(private apiService: ApiService) {}

  getList(): Observable<any> {
    return this.apiService
      .get(`/insurance-product`)
      .pipe(map((res) => res.data));
  }

  getCategoriesList(): Observable<ProductCategory[]> {
    return this.apiService
      .get(`/insurance-product-category`)
      .pipe(map((res) => res.data));
  }

  getInfo(productId: number, paramstr: any): Observable<Product> {
    return this.apiService
      //.get(`/insurance-product/${productId}`)
      .get(`/insurance-product/${productId}?requiredparms=${paramstr}`)
      .pipe(map((res) => res.data));
  }

  searchByTerm(data: any): Observable<any> {
    return this.apiService
      .get(`/insurance-product/searchText`, data)
      .pipe(map((res) => res.data));
  }

  create(data = {}): Observable<any> {
    return this.apiService
      .post('/insurance-product', data)
      .pipe(map((res) => res.data));
  }

  delete(productId: number): Observable<any> {
    return this.apiService.delete(`/insurance-product/${productId}`);
  }

  update(productId: number, data = {}): Observable<any> {
    return this.apiService
      .put(`/insurance-product/${productId}`, data)
      .pipe(map((res) => res.data));
  }

  updateAll(data = {}): Observable<any> {
    return this.apiService
      .put(`/insurance-product`, data)
      .pipe(map((res) => res.data));
  }

  searchProductsByCreatorId(id: any): Observable<any> {
    // not used so far
    return this.apiService
      .get(`/insurance-product/search?creator_id=${id}&like[]=creator_id`)
      .pipe(map((res) => res.data));
  }
  searchProductsByBrokerId(id: any, paramstr: any): Observable<any> {
    // PRAJAL TODO
    return (
      this.apiService
        // .get(`/insurance-product/search?brokers\\.broker_id=${id}`)
        .get(
          `/insurance-product/search?brokers\\.broker_id=${id}&requiredparms=${paramstr}`
        )
        .pipe(map((res) => res.data))
    );
  }
  searchProductsByBrokerFirmId(id: any, paramstr: any): Observable<any> {
    return (
      this.apiService
        // .get(`/insurance-product/search?brokers\\.broker_id=${id}`)
        .get(
          // `/insurance-product/search?brokers\\.broker_id=${id}&requiredparms=${paramstr}`
          `/insurance-product/search?brokerfirms\\.broker_firm_id=${id}&requiredparms=${paramstr}`
        )
        .pipe(map((res) => res.data))
    );
  }
  searchProductsByMgaFirmId(id: any, parmstr): Observable<any> {
    return (
      this.apiService
        // .get(`/insurance-product/search?mga_firm_id=${id}`) insurance_product_category,brokerfirms,rating_table
        .get(
          `/insurance-product/search?mga_firm_id=${id}&requiredparms=${parmstr}`
        )
        .pipe(map((res) => res.data))
    );
  }

  searchProductsByBrokerFirmList(id: any): Observable<any> {
    // not used so far
    return this.apiService
      .get(`/insurance-product/search?brokers\.broker_firm_id=${id}`)
      .pipe(map((res) => res.data));
  }

  searchByCategoryIds(categoryIds: number[]): Observable<any> {
    // Didn't get where we are calling.
    const promiseList = [];
    var parmstr = 'store,logo,insurance_product_category';
    if(categoryIds[0] == null){
      promiseList.push(
        this.apiService
          .get(
            `/insurance-product?requiredparms=${parmstr}`
          )
          .pipe(map((res) => res.data))
      );
    } else {
      categoryIds.forEach((id) => {
        promiseList.push(
          this.apiService
            .get(
              `/insurance-product/search?insurance_product_category_id=${id}&like[]=insurance_product_category_id&requiredparms=${parmstr}`
            )
            .pipe(map((res) => res.data))
        );
      });
    }

    return forkJoin(promiseList);
  }

  searchByBrokerFirmId(brokerFirmId: any): Observable<any> {
    return (
      this.apiService
        // .get(`/insurance-product/search?brokers\\.broker_firm_id=${brokerFirmId}`) PRAJAL2
        .get(
          // `/insurance-product/search?brokers\\.broker_firm_id=${brokerFirmId}&requiredparms=NA`
          `/insurance-product/search?brokerfirms\\.broker_firm_id=${brokerFirmId}&requiredparms=NA`
        )
        .pipe(map((res) => res.data))
    );
  }
}
