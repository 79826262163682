import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { Business } from '../models';

@Injectable()
export class BusinessService {
  constructor(private apiService: ApiService) {}

  getList(): Observable<Business[]> {
    return this.apiService.get(`/business`).pipe(map((res) => res.data));
  }

  getInfo(businessId: number): Observable<Business> {
    return this.apiService
      .get(`/business/${businessId}`)
      .pipe(map((res) => res.data));
  }

  create(data = {}): Observable<any> {
    return this.apiService.post(`/business`, data).pipe(map((res) => res.data));
  }

  update(businessId: number, data = {}): Observable<Business> {
    return this.apiService
      .put(`/business/${businessId}`, data)
      .pipe(map((res) => res.data));
  }

  delete(businessId: number): Observable<any> {
    return this.apiService.delete(`/business/${businessId}`);
  }
}
