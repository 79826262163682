import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
// rxjs
import { Observable } from 'rxjs';
// services
import { ProductService, UserService } from '../core/services';
// models
import { ProductListConfig } from '../core/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Output() selectedCategory = new EventEmitter<any>();

  insuranceCategories: any[];
  selected: any;
  categories = [
    {
      image: '/assets/images/errors-professional.png',
      name: 'Professional Liability',
      companies: 120,
      minValue: 15,
      id: 1,
    },
    {
      image: '/assets/images/cyber.png',
      name: 'Cyber Insurance',
      companies: 42,
      minValue: 15,
      id: 2,
    },
    {
      image: '/assets/images/general-liability.png',
      name: 'General Liability',
      companies: 31,
      minValue: 15,
      id: 3,
    },
    {
      image: '/assets/images/directors.png',
      name: 'Directors & Officers',
      companies: 31,
      minValue: 15,
      id: 4,
    },
    {
      image: '/assets/images/errors-professional.png',
      name: 'Errors & Omissions',
      companies: 23,
      minValue: 15,
      id: 5,
    },
  ];

  displayAllColumns = false;

  public products: Array<any> = [];
  public listConfig: ProductListConfig = new ProductListConfig();

  searchControl: FormControl = new FormControl();

  filteredOptions: Observable<any[]>;
  searchTerm = '';

  constructor(
    private router: Router,
    private userService: UserService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.getCategoriesList();
  }

  getCategoriesList(): void {
    this.productService.getCategoriesList().subscribe((response) => {
      this.insuranceCategories = response;
    });
  }

  checkboxModelChange(): void {
    this.selected = this.insuranceCategories.filter((item) => item.selected);
  }

  onProductSelect(): void {
    this.selectedCategory.emit(this.selected);
  }

  filter(val: string): any {
    return this.products.filter((product) => {
      return product.productName.toLowerCase().indexOf(val.toLowerCase()) !== 1;
    });
  }

  searchProducts(): void {
    this.router.navigate(['/product-search'], {
      queryParams: { search: this.searchControl.value },
    });
  }

  goToReferral(): void {
    const user: any = this.userService.getCurrentUser();
    if (user.id && user.roles[0].name === 'ROLE_USER') {
      this.router.navigate(['/account/referal']);
    } else {
      this.router.navigate(['/signin']);
    }
  }

  toggleDisplayAllBtn(): void {
    this.displayAllColumns = !this.displayAllColumns;
  }

  selectProduct(product): void {
    this.router.navigate(['/product', 'policy-details']);
  }
}
