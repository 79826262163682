import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { Address } from '../models';

@Injectable()
export class AddressService {
  constructor(private apiService: ApiService) {}

  getInfo(addressId: number): Observable<Address> {
    return this.apiService
      .get(`/address/${addressId}`)
      .pipe(map((res) => res.data));
  }

  create(data = {}): Observable<Address> {
    return this.apiService.post(`/address`, data).pipe(map((res) => res.data));
  }

  update(addressId: string, data = {}): Observable<Address> {
    return this.apiService
      .put(`/address/${addressId}`, data)
      .pipe(map((res) => res.data));
  }

  delete(addressId: number): Observable<any> {
    return this.apiService.delete(`/address/${addressId}`);
  }
}
