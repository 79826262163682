import {
  Directive,
  ElementRef,
  Input,
  HostListener,
  Renderer2,
  AfterViewInit,
} from '@angular/core';

@Directive({
  /* tslint:disable-next-line:directive-selector */
  selector: '[sticky-header]',
})
export class StickyDirective implements AfterViewInit {
  private sticked = true;
  private selectedOffset = 0;
  private windowOffsetTop = 0;
  private headerEl = null;

  @Input() addClass = 'fixed-header';
  @Input() offSet = 0;

  constructor(private el: ElementRef, private render: Renderer2) {
    this.selectedOffset = this.el.nativeElement.offsetTop;
  }

  ngAfterViewInit() {
    this.headerEl = this.el.nativeElement.querySelector('.header');
  }

  private addSticky() {
    this.sticked = true;
    this.headerEl.style.position = 'fixed';
    this.headerEl.style.top = this.offSet + 'px';
    this.el.nativeElement.style.display = 'block';
    this.el.nativeElement.style.height = this.headerEl.offsetHeight + 'px';
    this.render.addClass(this.headerEl, this.addClass);
  }

  private removeSticky() {
    this.sticked = false;
    this.headerEl.style.position = '';
    this.render.removeClass(this.headerEl, this.addClass);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset: number = this.el.nativeElement.offsetTop;
    this.windowOffsetTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (this.selectedOffset === 0) {
      this.selectedOffset = offset;
    }

    if (this.sticked === false) {
      this.selectedOffset = offset;
    }

    if (this.windowOffsetTop + this.offSet > this.selectedOffset) {
      this.addSticky();
    } else {
      this.removeSticky();
    }
  }
}
