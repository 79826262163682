import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export const options = {
  positionClass: 'toast-bottom-right',
  timeOut: 5000,
  closeButton: true,
};

@Injectable()
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  success(title: string, content = '', opts = {}): void {
    this.toastr.success(content, title, Object.assign({}, options, opts));
  }

  error(title: string, content: string, opts = {}): void {
    this.toastr.error(content, title, Object.assign({}, options, opts));
  }

  warning(title: string, content: string, opts = {}): void {
    this.toastr.warning(content, title, Object.assign({}, options, opts));
  }

  info(title: string, content: string, opts = {}): void {
    this.toastr.info(content, title, opts);
  }
}
