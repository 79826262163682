import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import axios from 'axios';
import {
  InsurancePolicyService,
  MediaService,
  NotificationService,
} from 'src/app/core/services';

@Component({
  selector: 'app-edit-policy-wording-template',
  templateUrl: './edit-policy-wording-template.component.html',
  styleUrls: ['./edit-policy-wording-template.component.scss'],
})
export class EditPolicyWordingTemplateComponent implements OnInit {
  insertionFieldsList: any[] = [
    {
      field_header: 'Store Info',
      list: [
        {
          label: 'Store Name',
          value: 'store_name',
        },
        { label: 'Firm Name', value: 'firm_name' },
        {
          label: 'Firm Address',
          value: 'frim_address',
        },
        { label: 'Firm Logo', value: 'firm_media_id' },
        { label: 'Store Admin First Name', value: 'storeadmin_first_name' },
        {
          label: 'Store Admin Last Name',
          value: 'storeadmin_last_name',
        },
        { label: 'Store Admin e-mail', value: 'storeadmin_email' },
        { label: 'Store Admin Phone Number', value: 'storeadmin_phone_number' },
      ],
    },
    {
      field_header: 'Product Info',
      list: [
        { label: 'Product Name', value: 'insuranceproduct_name' },
        {
          label: 'Product Category',
          value: 'category',
        },
        { label: 'Product Logo', value: 'product_media_id' },
        { label: 'Product Description', value: 'insuranceproduct_description' },
        {
          label: 'Product Terms & Condition',
          value: 'insuranceproduct_terms_and_conditions',
        },
      ],
    },
    {
      field_header: 'Insured Info',
      list: [
        { label: 'Client First Name', value: 'client_first_name' },
        { label: 'Client Last Name', value: 'client_last_name' },
        { label: 'Client E-mail', value: 'client_email' },
        { label: 'Company Name', value: 'company_name' },
        { label: 'Business Number', value: 'business_number' },
        {
          label: 'Insurance Address',
          value: 'address',
        },
        { label: 'Industry', value: 'industry' },
        { label: 'Years of Operation', value: 'years_of_operation' },
        { label: 'Num of Employees', value: ' number_of_employees' },
        {
          label: 'Annual Revenue',
          value: 'annual_revenue',
        },
      ],
    },
    {
      field_header: 'Policy Info',
      list: [
        { label: 'Policy Number', value: 'policy_number' },
        { label: 'Inception Date', value: 'inception_date' },
        { label: 'Expiration Date', value: 'expiration_date' },
        { label: 'Limit', value: 'limit_percentage' },
        { label: 'Deductible', value: 'deductible_amount' },
        { label: 'Coverage', value: 'coverage' },
        { label: 'Premium', value: 'premium_amount' },
        {
          label: 'Premium Frequency',
          value: 'premium_frequency',
        },
      ],
    },
    {
      field_header: 'Broker Info',
      list: [
        { label: 'Broker First Name', value: 'broker_first_name' },
        { label: 'Broker Last Name', value: 'broker_last_name' },
        { label: 'Broker E-mail', value: 'broker_email ' },
        { label: 'Broker Phone Number', value: 'broker_phone_number' },
        { label: 'Broker Firm Name', value: 'broker_firm_name' },
        {
          label: 'Broker Firm Address',
          value: 'broker_firmaddress',
        },
      ],
    },
  ];
  public options = {
    charCounterCount: true,
    height: window.innerHeight - 550,
    toolbarButtons: [
      'fullscreen',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      '|',
      'fontFamily',
      'fontSize',
      'color',
      'inlineStyle',
      'paragraphStyle',
      '|',
      'paragraphFormat',
      'align',
      'formatOL',
      'formatUL',
      'outdent',
      'indent',
      'quote',
      '-',
      'insertLink',
      'insertImage',
      // 'insertVideo',
      // 'insertFile',
      'insertTable',
      '|',
      // 'emoticons',
      // 'specialCharacters',
      'insertHR',
      'selectAll',
      'clearFormatting',
      '|',
      'print',
      'help',
      'html',
      '|',
      'undo',
      'redo',
      'trackChanges',
      'markdown',
    ],
    events: {
      'image.inserted': (images): boolean => {
        return false;
      },
    },
  };
  mediaList: any[] = [];
  uploadedImagesList: any[] = [];
  private wordingDescription = '';
  private selectedStatus = '';
  wordingForm;

  @ViewChild('quillEditorRef') quillEditorRef: any;

  constructor(
    private dialogRef: MatDialogRef<EditPolicyWordingTemplateComponent>,
    private notificationService: NotificationService,
    private insurancePolicyService: InsurancePolicyService,
    private formBuilder: FormBuilder,
    private mediaService: MediaService,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {}

  ngOnInit(): void {
    this.overrideFroalaImageInsertedEvent();
    this.wordingDescription = this.dialogData.templateData.content;
    this.selectedStatus = this.dialogData.templateData.status;
    this.wordingForm = this.formBuilder.group({
      title: [
        this.dialogData.templateData.title,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_ ]*$'),
        ]),
      ],
    });
  }

  overrideFroalaImageInsertedEvent(): void {
    this.options.events['image.inserted'] = (images) => {
      if (images.length) {
        this.uploadedImagesList.push(images[0]);
      }
      return false;
    };
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onFieldCardDrag(event, field): void {
    event.dataTransfer.setData('Text', `[${field.value}] `);
  }

  /* insertField(fieldObj: any): void {
    const selection = this.quillEditorRef.quillEditor.getSelection(true);
    if (selection === null) {
      this.notificationService.warning(
        'Insert Error',
        'Please place the cursor inside the editor'
      );
    } else {
      this.quillEditorRef.quillEditor.insertText(
        selection.index,
        ` [${fieldObj.value}] `
      );
    }
  } */

  textChanged(data: any): void {
    this.wordingDescription = data.html;
  }

  async getUploadImages(): Promise<any> {
    const promises = this.uploadedImagesList.map((image) => {
      return axios.get(image.src, { responseType: 'blob' });
    });
    await Promise.all(promises).then((response) => {
      response.forEach((blobObject) => {
        this.mediaList.push(new File([blobObject.data], 'test'));
      });
    });
  }

  updatePolicyDocument(): void {
    if (!this.wordingDescription) {
      this.notificationService.warning(
        'Wording Template Error',
        'Please enter a description'
      );
    } else {
      if (this.wordingForm.status === 'VALID') {
        this.getUploadImages().then(() => {
          if (this.mediaList?.length > 0) {
            this.mediaService
              .createMany([...this.mediaList])
              .subscribe((response) => {
                this.uploadedImagesList.forEach((image, index) => {
                  this.wordingDescription = this.wordingDescription.replace(
                    image.src,
                    response.data[index].url
                  );
                });
                const policyWordingData = {
                  title: this.wordingForm.get('title').value,
                  content: this.wordingDescription,
                  status: this.selectedStatus,
                  active: 1,
                  insurance_products_id: this.dialogData?.insuranceProductInfo
                    ?.id,
                };

                this.insurancePolicyService
                  .saveWordingTemplate(policyWordingData)
                  .subscribe(() => {
                    this.notificationService.success(
                      'Policy Template Saved Successfully'
                    );
                    this.closeDialog();
                  });
              });
          } else {
            const policyWordingData = {
              title: this.wordingForm.get('title').value,
              content: this.wordingDescription,
              status: this.selectedStatus,
              active: 1,
              insurance_products_id: this.dialogData.templateData
                .insurance_products_id,
            };

            this.insurancePolicyService
              .updatePolicyWordingTemplate(
                this.dialogData.templateData.id,
                policyWordingData
              )
              .subscribe(() => {
                this.notificationService.success(
                  'Policy Template Updated Successfully'
                );
                this.closeDialog();
              });
          }
        });

        /* const policyWordingData = {
          title: this.wordingForm.get('title').value,
          content: this.wordingDescription,
          status: this.selectedStatus,
          active: 1,
          insurance_products_id: this.dialogData.templateData
            .insurance_products_id,
        };

        this.insurancePolicyService
          .updatePolicyWordingTemplate(
            this.dialogData.templateData.id,
            policyWordingData
          )
          .subscribe(() => {
            this.notificationService.success(
              'Policy Template Updated Successfully'
            );
            this.closeDialog();
          }); */
      } else {
        this.notificationService.error(
          'Form Error',
          'Please enter a valid title'
        );
      }
    }
    /* const policyWordingData = {
      title: this.dialogData.templateData.title,
      content: this.wordingDescription,
      status: this.selectedStatus,
      active: 1,
      insurance_products_id: this.dialogData.templateData.insurance_products_id,
    };

    this.insurancePolicyService
      .updatePolicyWordingTemplate(
        this.dialogData.templateData.id,
        policyWordingData
      )
      .subscribe(() => {
        this.notificationService.success(
          'Policy Template Updated Successfully'
        );
        this.closeDialog();
      }); */
  }
}
