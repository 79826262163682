import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// service
import {
  UserService,
  NotificationService,
  ErrorHandlingService,
} from '../../../core/services';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  currentUser;
  updatedUserInfo: any;
  constructor(
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.fetchCurrentUserInfo();
  }

  handleLogout(): void {
    this.userService.purgeAuth();
    this.router.navigate(['/account/signin']);
  }

  handleSetting(): void {
    this.router.navigate(['/admin/information']);
  }

  gotoHome(): void {
    // this.userService.purgeAuth();
    this.router.navigate(['/']);
  }

  fetchCurrentUserInfo(): void {
    this.userService.getInfo(this.currentUser?.id).subscribe(
      (res) => {
        this.updatedUserInfo = res.data;
      },
      (err) => {
        this.notificationService.error(
          '',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
}
