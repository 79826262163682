import { Component, OnInit } from '@angular/core';
// services
import { PageDataService } from '../../../../core/services';
@Component({
  selector: 'app-quote-header',
  templateUrl: './quote-header.component.html',
  styleUrls: ['./quote-header.component.scss'],
})
export class QuoteHeaderComponent implements OnInit {
  public quoteStepper;
  constructor(private pageDataService: PageDataService) {}

  ngOnInit(): void {
    this.pageDataService.quoteStepper.subscribe((stepper) => {
      this.quoteStepper = stepper;
    });
  }
}
