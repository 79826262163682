import {
  NgModule,
  ModuleWithProviders,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { BrowserModule } from '@angular/platform-browser';
// shared
import { SharedModule, BrokerLayoutComponent } from '../shared';
import { QuoteModule } from '../quote/quote.module';
// external library
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSpinnerModule } from 'ngx-spinner';

// Application components
import { ApplicationComponent } from './application/application.component';
import { ApplicationInfoComponent } from './application/application-info/application-info.component';
// broker components
import { BrokerComponent } from './broker.component';
// Client components
import { ClientComponent } from './client/client.component';
import { ClientInfoComponent } from './client/client-info/client-info.component';
import { ClientUpdateComponent } from './client/client-update/client-update.component';
// Components
import { UserComponent } from './user/user.component';
import { PolicyComponent } from './policy/policy.component';
import { ApplicationCreateComponent } from './application/application-create/application-create.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductsComponent } from './products/products.component';
import { ProductInfoComponent } from './products/product-info/product-info.component';
import { QuoteProductComponent } from './application/quote-product/quote-product.component';
import { ApplicationListComponent } from './application/application-list/application-list.component';
import { QuotePolicyComponent } from './application/quote-policy/quote-policy.component';
import { BrokerInformationComponent } from './broker-information/broker-information.component';
import { QuotePaymentComponent } from './application/quote-payment/quote-payment.component';
import { QuoteSuccessComponent } from './application/quote-success/quote-success.component';
import { PolicyInfoComponent } from './policy/policy-info/policy-info.component';

const brokerRouting: ModuleWithProviders<any> = RouterModule.forRoot([
  {
    path: 'broker',
    component: BrokerLayoutComponent,
    children: [
      {
        path: '',
        component: ApplicationListComponent,
      },
      {
        path: 'information',
        children: [{ path: '', component: BrokerInformationComponent }],
      },
      {
        path: 'applications',
        children: [
          { path: '', component: ApplicationListComponent },
          { path: 'create', component: ApplicationComponent },
          { path: ':id', component: ApplicationInfoComponent },
        ],
      },
      {
        path: 'policies',
        children: [
          { path: '', component: PolicyComponent },
          { path: ':id', component: PolicyInfoComponent },
        ],
        // children: [{ path: ':id', component: ApplicationInfoComponent }],
      },
      {
        path: 'clients',
        children: [
          { path: '', component: ClientComponent },
          { path: ':id', component: ClientInfoComponent },
          { path: ':id/update', component: ClientUpdateComponent },
          { path: ':id/create-policy', component: PolicyComponent },
        ],
      },
      {
        path: 'products',
        children: [
          { path: '', component: ProductsComponent },
          { path: ':id', component: ProductInfoComponent },
        ],
      },
    ],
  },
]);

@NgModule({
  declarations: [
    BrokerComponent,
    ApplicationComponent,
    UserComponent,
    ApplicationInfoComponent,
    ClientComponent,
    ProductListComponent,
    ProductInfoComponent,
    ProductsComponent,
    ClientInfoComponent,
    ClientUpdateComponent,
    PolicyComponent,
    ApplicationCreateComponent,
    QuoteProductComponent,
    ApplicationListComponent,
    QuotePolicyComponent,
    BrokerInformationComponent,
    QuotePaymentComponent,
    QuoteSuccessComponent,
    PolicyInfoComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    brokerRouting,
    SharedModule,
    QuoteModule,
    MatTableModule,
    CdkTableModule,
    NgxDropzoneModule,
    NgxSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BrokerModule {}
