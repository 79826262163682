import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-range',
  templateUrl: './question-range.component.html',
  styleUrls: ['./question-range.component.scss'],
})
export class QuestionRangeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
