import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  ClientService,
  ErrorHandlingService,
  NotificationService,
} from 'src/app/core/services';
import { CustomEmailValidator } from '../../directives';
@Component({
  selector: 'app-client-create-dialog',
  templateUrl: './client-create-dialog.component.html',
  styleUrls: ['./client-create-dialog.component.scss'],
})
export class ClientCreateDialogComponent implements OnInit {
  @Output() isClientCreate = new EventEmitter<any>();
  @Output() clientData = new EventEmitter<any>();

  clientCreateForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.clientCreateForm = this.formBuilder.group({
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      email: [
        '',
        Validators.compose([
          Validators.required,
          CustomEmailValidator.validate,
        ]),
      ],
    });
  }

  ngOnInit(): void {}

  clientSubmitForm(): void {
    if (this.clientCreateForm.valid) {
      this.clientService.create(this.clientCreateForm.value).subscribe(
        (response) => {
          this.clientData.emit(response.data);
        },
        (err) => {
          this.notificationService.error(
            'Error while submitting client form',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
    }
  }

  bacKToClientSearch(): void {
    this.isClientCreate.emit(false);
  }
}
