import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from 'src/app/core/services';
import { Location } from '@angular/common';
import { ProductTermsAndConditionComponent } from '../product-request/product-terms-and-condition/product-terms-and-condition.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent implements OnInit {
  productsList = [];
  displayedColumns = [
    'product_logo',
    'product_name',
    'description',
    'region',
    'store',
    'view',
  ];
  searchControl: FormControl = new FormControl();
  searchTerm = '';

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private location: Location,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.search) {
        this.searchControl.setValue(params.search);
        this.searchProduct(params.search);
      }
    });
  }

  searchProduct(searchTerm: string): void {
    const getData = {
      text: searchTerm,
    };

    this.productService.searchByTerm(getData).subscribe((response) => {
      this.productsList = response;
    });
  }

  searchProducts(): void {
    if (this.searchControl.value) {
      this.searchProduct(this.searchControl.value);
    }
  }

  goBack(): void {
    this.location.back();
  }

  showTermsAndConditions(productTermsAndConditions): void {
    const dialogRef = this.dialog.open(ProductTermsAndConditionComponent, {
      data: {
        widh: '100px',
        termsAndConditions: productTermsAndConditions,
      },
    });
  }
}
