export class User {
  first_name: string;
  last_name: string;
  gender: string;
  beneficiary_business_name: string;
  email: string;
  email_verified_at: Date;
  created_at: Date;
  updated_at: Date;
  userable_type:
    | 'App\\Models\\User\\Client'
    | 'App\\Models\\User\\Admin'
    | 'App\\Models\\User\\Broker';
  userable_id?: number;
  deleted_at?: null | Date;
  business_address_id?: number;
  userable?: any;
  id?: any;
}

export class ChangePassword {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
}
