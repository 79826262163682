import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent implements OnInit {
  @Input() clients: any[];
  @Output() clientClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onClientClick(event): void {
    this.clientClick.emit(event);
  }
}
