import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// models
import { Product } from 'src/app/core/models';
// services
import {
  ProductService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  products: Product[] = [];

  constructor(
    private router: Router,
    private productService: ProductService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.fetchProducts();
  }

  fetchProducts(): void {
    this.productService.getList().subscribe(
      (response) => {
        this.products = response;
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while fetching products',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  onViewProduct(productId: number): void {
    this.router.navigateByUrl(`/broker/products/${productId}`);
  }
}
