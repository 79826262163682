import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss'],
})
export class QuillEditorComponent implements OnInit {
  @Output() changedText = new EventEmitter<any>();
  @Input()
  convertToText: any;
  content: any;

  constructor() {}

  ngOnInit(): void {
    this.content = this.convertToText;
  }

  textChanged(data: any): void {
    this.changedText.emit(data.html);
  }

  onSelectionChanged(data: any): void {}
}
