import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';

@Injectable()
export class QuoteService {
  constructor(private apiService: ApiService) {}

  getQuoteListByclient(clientId: string): Observable<any> {
    // Don't know how to reach through UI PENDING
    return this.apiService
      .get(`/quote/search?client_id=${clientId}`)
      .pipe(map((res) => res.data));
  }

  getList(): Observable<any> {
    return this.apiService.get(`/quote`).pipe(map((res) => res.data));
  }

  getInfo(quoteId: any): Observable<any> {
    return this.apiService
      .get(`/quote/${quoteId}`)
      .pipe(map((res) => res.data));
  }

  searchQuoteByApplicationIdAndProductId(
    applicationId: any,
    productId: any,
    paramstr
  ): Observable<any> {
    return this.apiService
      .get(
        //  `/quote/search?application_id=${applicationId}&insurance_product_id=${productId}`
        `/quote/search?application_id=${applicationId}&insurance_product_id=${productId}&requiredparms=${paramstr}`
      )
      .pipe(map((res) => res.data));
  }
  searchQuoteByApplicationId(applicationId: any, paramstr): Observable<any> {
    // This method is not used
    return (
      this.apiService
        // .get(`/quote/search?application_id=${applicationId}`)
        .get(
          `/quote/search?application_id=${applicationId}&requiredparms=${paramstr}`
        )
        .pipe(map((res) => res.data))
    );
  }
  searchQuoteByMgaFirmId(mgaFirmId: any, paramstr): Observable<any> {
    return (
      this.apiService
        // .get(`/quote/search?insuranceProduct\\.mga_firm_id=${mgaFirmId}`)
        .get(
          `/quote/search?insuranceProduct\\.mga_firm_id=${mgaFirmId}&requiredparms=${paramstr}`
        )
        .pipe(map((res) => res.data))
    );
  }

  updateQuoteStatus(quoteId: any, data: any): Observable<any> {
    return this.apiService
      .put(`/quote/${quoteId}`, data)
      .pipe(map((res) => res.data));
  }
}
