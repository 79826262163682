import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// components
import { DeleteDialogComponent } from '../../../shared/components/delete-dialog/delete-dialog.component';
import { ChangeBrokerAccountStatusDialogComponent } from '../../../shared/components/change-broker-account-status-dialog/change-broker-account-status-dialog.component';
// services
import {
  FirmService,
  BrokerService,
  NotificationService,
  ErrorHandlingService,
  ProductService,
} from '../../../core/services';
// models
import { BrokerFirm, Product } from '../../../core/models';
import { of } from 'rxjs';

import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-broker-firm-info',
  templateUrl: './broker-firm-info.component.html',
  styleUrls: ['./broker-firm-info.component.scss'],
})
export class BrokerFirmInfoComponent implements OnInit {
  brokerFirmInfo: BrokerFirm;
  brokerFirmId: string;
  displayedColumns = ['full_name', 'email', 'type', 'actions', 'show'];
  displayProductsColumns = [
    'name',
    'product_number',
    'region',
    'valid_from',
    'valid_to',
    'actions',
  ];
  products: Product[];

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private firmService: FirmService,
    private brokerService: BrokerService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.brokerFirmId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.fetchBrokerFirmInfo();
    this.fetchProducts();
    // this.fetchProductInfo();
  }

  fetchProducts(): void {
    this.productService.searchByBrokerFirmId(this.brokerFirmId).subscribe(
      (response) => {
        this.products = response;
      },
      (error) => {
        this.notificationService.error(
          'Error while fetching products',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }
  // fetchProductInfo(): void {
  //   of(1, 2, 3, 4)
  //     .pipe(
  //       mergeMap((x) => {
  //         return this.productService.getInfo(x);
  //       })
  //     )
  //     .subscribe(
  //       (res) => {
  //         console.log(' mefge map response', res);
  //       },
  //       (err) => {
  //         console.log('err', err);
  //       }
  //     );
  // }
  fetchBrokerFirmInfo(): void {
    this.firmService.getBrokerFirmInfo(this.brokerFirmId).subscribe(
      (response) => {
        this.brokerFirmInfo = response;
      },
      (error) => {
        this.notificationService.error(
          'Error while fetching broker firm info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  openChangeBrokerAccountStatusDialog(brokerInfo: any): void {
    const dialogRef = this.dialog.open(
      ChangeBrokerAccountStatusDialogComponent,
      {
        data: {
          title: 'Are you sure you want to change the account status?',
          isAdmin: brokerInfo.admin,
          result: brokerInfo,
        },
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const postData = {
          phone_number: result.phone_number,
          broker_firm_id: result.broker_firm_id,
          admin: !result.admin,
        };

        this.brokerService.update(result.id, postData).subscribe(
          () => {
            this.fetchBrokerFirmInfo();
            this.notificationService.success('Broker account status updated');
          },
          (error) => {
            this.notificationService.error(
              'Error while updating broker account status',
              this.errorHandlingService.getErrors(error)
            );
          }
        );
      }
    });
  }

  openDeleteDialog(brokerId: any): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'Are you sure you want to delete this broker?',
        result: brokerId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.brokerService.delete(result).subscribe((res) => {
          this.notificationService.success(
            'Broker has been deleted Successfully',
            ''
          );
          this.fetchBrokerFirmInfo();
        });
      }
    });
  }
}
