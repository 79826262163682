import {
  NgModule,
  ModuleWithProviders,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// currency mask
import { CurrencyMaskModule } from 'ng2-currency-mask';
// components
import { MgaComponent } from './mga.component';
// shared
import { SharedModule } from '../shared';
// layout
import { MgaLayoutComponent } from '../shared/layouts/mga-layout/mga-layout.component';
// module
import { AdminModule } from '../admin/admin.module';
import { ClientModule } from '../client/client.module';
// components
import { ProductsComponent } from './products/products.component';
import { ProductCreateComponent } from './products/product-create/product-create.component';
import { ProductUpdateComponent } from './product-update/product-update.component';
import { DialogComponent } from './products/dialog/dialog.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { BrokerFirmCreateDialogComponent } from './broker-firm-create-dialog/broker-firm-create-dialog.component';
import { MgaInformationComponent } from './mga-information/mga-information.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ApplicationInfoComponent } from './applications/application-info/application-info.component';
// external library
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { StoreInfoComponent } from './stores/store-info/store-info.component';
import { ProductInfoComponent } from './products/product-info/product-info.component';
import { StoreUpdateComponent } from './mga-information/store-update/store-update.component';
import { BrokerFirmsComponent } from './broker-firms/broker-firms.component';
import { BrokerFirmCreateComponent } from './broker-firms/broker-firm-create/broker-firm-create.component';
import { BrokerFirmInfoComponent } from './broker-firms/broker-firm-info/broker-firm-info.component';
import { BrokerFirmUpdateComponent } from './broker-firms/broker-firm-update/broker-firm-update.component';
import { BrokerCreateComponent } from './broker-firms/broker-create/broker-create.component';
import { StoreManagerCreateComponent } from './mga-information/store-manager-create/store-manager-create.component';
import { PolicyComponent } from './policy/policy.component';
import { PolicyInfoComponent } from './policy/policy-info/policy-info.component';
import { ClientSearchComponent } from './client-search/client-search.component';
import { PolicyCreateComponent } from './policy/policy-create/policy-create.component';
import { ClientListComponent } from './client-list/client-list.component';
import { PolicyListComponent } from './policy-list/policy-list.component';
import { QuoteListComponent } from './quote-list/quote-list.component';
import { MgaPolicyListComponent } from './mga-policy-list/mga-policy-list.component';
import { QuoteViewComponent } from './quote-view/quote-view.component';
import { BrokerFirmProductInfoComponent } from './broker-firms/broker-firm-product-info/broker-firm-product-info.component';
import { BrokerInfoComponent } from './broker-firms/broker-info/broker-info.component';
import { BrokerListComponent } from '../shared/components/lists/broker-list/broker-list.component';
import { QuotesComponent } from './quotes/quotes.component';
import { ProductListComponent } from './broker-firms/product-list/product-list.component';
import { StoreManagerUpdateComponent } from './mga-information/store-manager-update/store-manager-update.component';
import { QuillModule } from 'ngx-quill';
import { EditPolicyWordingTemplateComponent } from './products/product-info/edit-policy-wording-template/edit-policy-wording-template.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/image.min.js';
import { PolicyWordingTemplateComponent } from './products/product-info/policy-wording-template/policy-wording-template.component';

const MgaRouting: ModuleWithProviders<any> = RouterModule.forRoot([
  {
    path: 'mga',
    component: MgaLayoutComponent,
    children: [
      {
        path: '',
        component: QuotesComponent,
      },
      {
        path: 'broker-firms',
        children: [
          { path: '', component: BrokerFirmsComponent },
          { path: 'create', component: BrokerFirmCreateComponent },
          { path: ':id', component: BrokerFirmInfoComponent },
          { path: ':id/broker-create', component: BrokerCreateComponent },
          { path: ':id/products', component: ProductListComponent },
          { path: ':id/update', component: BrokerFirmUpdateComponent },
          {
            path: ':brokerFirmId/products/:productId',
            component: BrokerFirmProductInfoComponent,
          },
          {
            path: ':brokerFirmId/broker/:brokerId',
            component: BrokerInfoComponent,
          },
        ],
      },
      {
        path: 'information',
        children: [
          { path: '', component: MgaInformationComponent },
          {
            path: 'store-manager/:id/update',
            component: StoreManagerUpdateComponent,
          },
          {
            path: 'store/:storeId/update',
            component: StoreUpdateComponent,
          },
          {
            path: 'store/:storeId/store-manager-create',
            component: StoreManagerCreateComponent,
          },
        ],
      },
      {
        path: 'applications',
        children: [
          { path: '', component: ApplicationsComponent },
          { path: ':id', component: ApplicationInfoComponent },
        ],
      },
      {
        path: 'quote',
        children: [{ path: ':id', component: QuoteViewComponent }],
      },
      {
        path: 'clients',
        children: [
          { path: '', component: ApplicationsComponent },
          { path: ':id', component: ApplicationInfoComponent },
        ],
      },
      {
        path: 'policies',
        children: [
          { path: '', component: MgaPolicyListComponent },
          { path: 'create', component: PolicyComponent },
          { path: ':id', component: PolicyInfoComponent },
        ],
      },
      {
        path: 'products',
        children: [
          { path: '', component: ProductsComponent },
          { path: 'product-create', component: ProductCreateComponent },
          {
            path: ':id',
            component: ProductInfoComponent,
          },
          {
            path: ':productId/broker',
            component: BrokerListComponent,
          },
        ],
      },
      {
        path: 'stores',
        children: [
          {
            path: ':id',
            children: [
              { path: '', component: StoreInfoComponent },
              { path: 'update', component: StoreUpdateComponent },
              {
                path: 'store-manager-create',
                component: StoreManagerCreateComponent,
              },
              { path: 'product-create', component: ProductCreateComponent },
            ],
          },
          {
            path: ':storeId/products/:productId',
            component: ProductInfoComponent,
          },
          {
            path: ':storeId/products/:productId/broker',
            component: BrokerListComponent,
          },
        ],
      },
    ],
  },
]);
@NgModule({
  declarations: [
    MgaComponent,
    ProductsComponent,
    ProductUpdateComponent,
    ProductCreateComponent,
    DialogComponent,
    ProductViewComponent,
    BrokerFirmCreateDialogComponent,
    MgaInformationComponent,
    ApplicationsComponent,
    ApplicationInfoComponent,
    StoreInfoComponent,
    ProductInfoComponent,
    StoreUpdateComponent,
    BrokerFirmsComponent,
    BrokerFirmCreateComponent,
    BrokerFirmInfoComponent,
    BrokerFirmUpdateComponent,
    BrokerCreateComponent,
    StoreManagerCreateComponent,
    PolicyComponent,
    PolicyInfoComponent,
    ClientSearchComponent,
    PolicyCreateComponent,
    ClientListComponent,
    PolicyListComponent,
    QuoteListComponent,
    MgaPolicyListComponent,
    QuoteViewComponent,
    BrokerFirmProductInfoComponent,
    BrokerInfoComponent,
    QuotesComponent,
    ProductListComponent,
    StoreManagerUpdateComponent,
    EditPolicyWordingTemplateComponent,
    PolicyWordingTemplateComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MgaRouting,
    SharedModule,
    AdminModule,
    ClientModule,
    NgxDropzoneModule,
    MatGoogleMapsAutocompleteModule,
    CurrencyMaskModule,
    QuillModule,
    MatExpansionModule,
    MatCheckboxModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  exports: [
    EditPolicyWordingTemplateComponent,
    PolicyWordingTemplateComponent,
],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MgaModule {}
