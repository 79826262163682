import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// external
import * as FileSaver from 'file-saver';
// services
import {
  ApplicationService,
  QuoteService,
  NotificationService,
  ErrorHandlingService,
} from '../../../core/services';

@Component({
  selector: 'app-application-info',
  templateUrl: './application-info.component.html',
  styleUrls: ['./application-info.component.scss'],
})
export class ApplicationInfoComponent implements OnInit {
  public id: any;
  public application: any;
  public currentUserInfo: any;
  public quoteInfo: any;
  public quotePrice: any;
  @Input() applicationDetails: any;
  constructor(
    private applicationService: ApplicationService,
    private quoteService: QuoteService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = +params[`id`]; // (+) converts string 'id' to a number
    });
    this.fetchApplicationInfo();
  }

  fetchApplicationInfo(): void {
    this.applicationService.getInfo(this.id).subscribe(
      (response) => {
        this.application = response;
        this.fetchQuoteInfoByApplicationId();
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while fetching application info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  fetchQuoteInfoByApplicationId(): void {
    this.quoteService
      .searchQuoteByApplicationIdAndProductId(
        this.id,
        this.application.insurance_products[0].id,
        'quote_status'
      )
      .subscribe(
        (res) => {
          this.quoteInfo = res[0];
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching application info',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  getPdf(fileData: any): void {
    FileSaver.saveAs(fileData, `application.pdf`);
  }

  submitForm(): void {
    this.router.navigate(['/application']);
  }

  iterateOver(mediaData: []): [] {
    const toReturn: [] = [];
    for (const media of mediaData) {
      toReturn.push(media);
    }
    return toReturn;
  }
}
