import { Broker } from './broker.model';

export class BrokerFirm {
  id?: string;
  firm?: any;
  created_at?: any;
  updated_at?: any;
  brokers: Broker[];
  brokerfirms?: any;
}
