import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-policy-list-table',
  templateUrl: './policy-list-table.component.html',
  styleUrls: ['./policy-list-table.component.scss'],
})
export class PolicyListTableComponent implements OnInit {
  @Input() policies: Array<any>;
  @Input() displayAllColumns: boolean;
  @Output() selectedPolicy = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onPolicySelect(selectedPolicyData: any): void {
    this.selectedPolicy.emit(selectedPolicyData);
  }
}
