import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
// components
import { ClientComponent } from './client.component';
// shared
import { SharedModule, ClientLayoutComponent } from '../shared';
import { ApplicationsComponent } from './applications/applications.component';
import { ApplicationInfoComponent } from './applications/application-info/application-info.component';
import { ProductInfoDialogComponent } from './product-info-dialog/product-info-dialog.component';
import { BrokerInfoDialogComponent } from './broker-info-dialog/broker-info-dialog.component';
import { ClientInformationComponent } from './client-information/client-information.component';
import { PolicyComponent } from './policy/policy.component';
import { PolicyInfoComponent } from './policy/policy-info/policy-info.component';
import { ProfileComponent } from './profile/profile.component';
// eternal library

import { NgxDropzoneModule } from 'ngx-dropzone';
import { PaymentComponent } from './payment/payment.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { ChargeComponent } from './payment/charge/charge.component';
const clientRouting: ModuleWithProviders<any> = RouterModule.forRoot([
  {
    path: 'client',
    component: ClientLayoutComponent,
    children: [
      {
        path: '',
        component: ClientComponent,
      },
      {
        path: 'applications',
        children: [
          { path: '', component: ApplicationsComponent },
          { path: ':id', component: ApplicationInfoComponent },
          { path: ':id/payment', component: PaymentComponent },
          { path: ':id/charge', component: ChargeComponent },
          { path: ':id/payment/success', component: PaymentSuccessComponent },
        ],
      },
      {
        path: 'policy',
        children: [
          { path: '', component: PolicyComponent },
          { path: ':id', component: PolicyInfoComponent },
        ],
      },
      {
        path: 'information',
        children: [{ path: '', component: ClientInformationComponent }],
      },
      {
        path: 'profile',
        children: [{ path: '', component: ProfileComponent }],
      },
    ],
  },
]);

@NgModule({
  declarations: [
    ClientComponent,
    ApplicationsComponent,
    ApplicationInfoComponent,
    ProductInfoDialogComponent,
    BrokerInfoDialogComponent,
    ClientInformationComponent,
    PolicyComponent,
    PolicyInfoComponent,
    ProfileComponent,
    PaymentComponent,
    PaymentSuccessComponent,
    ChargeComponent,
  ],
  imports: [
    CommonModule,
    clientRouting,
    SharedModule,
    NgxDropzoneModule,
    MatTableModule,
    CdkTableModule,
  ],
  exports: [ProductInfoDialogComponent, BrokerInfoDialogComponent],
})
export class ClientModule {}
