import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-rating-table-select',
  templateUrl: './rating-table-select.component.html',
  styleUrls: ['./rating-table-select.component.scss'],
})
export class RatingTableSelectComponent implements OnInit, OnChanges {
  @Input() preselect: any;
  @Input() dataSource: any[];
  @Input() parentFormGroup: FormGroup;
  @Input() selectedCategoryId: any;
  @Output() selectedRatingTableId = new EventEmitter<number>();

  updatedDataSource: any[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource'].currentValue) {
      this.updatedDataSource = this.dataSource;
    }
  }

  ngOnInit(): void {}

  getPreSelected(): void {
    this.updatedDataSource.forEach((element) => {
      if (element.insurance_product_category_id === this.selectedCategoryId) {
        this.selectedRatingTableId.emit(element.id);
      }
    });
  }

  radioChange(id): void {
    this.selectedRatingTableId.emit(id);
  }
}
