import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// external
import { FileUploader } from 'ng2-file-upload';
import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';
import * as moment from 'moment';
// services
import {
  NotificationService,
  FormValidatorService,
  ProductService,
  ErrorHandlingService,
} from '../../core/services';

@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.scss'],
})
export class ProductUpdateComponent implements OnInit, OnDestroy {
  productId: number;
  productInformation: any;
  productForm: FormGroup;
  wordingForm: FormGroup;
  page: string;
  title: string;
  categories: Array<any>;
  subCategories: Array<any>;
  regions: Array<any>;
  product: any;
  storeList: Array<any> = [];
  modules = {};
  editorStyle = { height: '500px' }; // 788px
  public uploader: FileUploader = new FileUploader({});
  public hasBaseDropZoneOver = false;
  public stage = 'info'; // editor || info || pricing
  public cursorLastPos;
  public placeholders = [];
  public sizeError: boolean;
  serverErrMsg = {
    response: null,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private productService: ProductService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private formService: FormValidatorService,
    private el: ElementRef,
    private _scrollToService: ScrollToService
  ) {
    this.categories = [{ id: 'corp', name: 'Professional Liability' }];
    this.subCategories = [{ id: 'general', name: 'General liability' }];
    this.regions = [
      { id: 'Ontario', name: 'Ontario' },
      { id: 'AB', name: 'Alberta' },
      { id: 'BC', name: 'British Columbia' },
      { id: 'MB', name: 'Manitoba' },
      { id: 'NB', name: 'New Brunswick' },
      { id: 'NL', name: 'Newfoundland and Labrador' },
      { id: 'NS', name: 'Nova Scotia' },
      { id: 'PE', name: 'Prince Edward Island' },
      { id: 'QC', name: 'Quebec' },
      { id: 'SK', name: 'Saskatchewan' },
      { id: 'NT', name: 'Northwest Territories' },
      { id: 'NU', name: 'Nunavut' },
      { id: 'YT', name: 'Yukon' },
    ];
    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'],
        ['link', 'image', 'video'],
      ],
    };

    this.productForm = this.fb.group({
      productId: [null],
      productName: ['', Validators.compose([Validators.required])],
      category: ['', Validators.compose([Validators.required])],
      subCategory: ['', Validators.compose([Validators.required])],
      region: ['', Validators.compose([Validators.required])],
      storeId: ['', Validators.required],
      logo: [null],
      inceptionDate: [null, Validators.required],
      expirationDate: [null, Validators.required],
      limit: [
        0,
        Validators.compose([Validators.required, Validators.pattern('[0-9]+')]),
      ],
      description: ['', Validators.required],
      terms: ['', Validators.required],
    });
    this.wordingForm = this.fb.group({
      wording: ['', Validators.required],
    });
    this.placeholders = [
      { label: 'valid from', placeholder: '[Inception.ValidFrom]' },
      { label: 'valid to', placeholder: '[Inception.ValidTo]' },
      { label: 'liability limit', placeholder: '[Inception.LibilityLimit]' },
      { label: 'policy number', placeholder: '[Inception.PolicyNumber]' },
      { label: 'insured', placeholder: '[Inception.Insured]' },
      { label: 'address', placeholder: '[Inception.Address]' },
      { label: 'postal code', placeholder: '[Inception.PostalCode]' },
      { label: 'province / state', placeholder: '[Inception.ProvinceState]' },
      { label: 'province', placeholder: '[Inception.Province]' },
      { label: 'phone number', placeholder: '[Inception.Phone]' },
    ];
    this.uploader.onAfterAddingFile = (f) => {
      if (this.uploader.queue.length > 1) {
        this.uploader.queue.splice(0, 1);
      }
      this.sizeError = f._file.size / 1024 > 500;
      if (!this.sizeError) {
        this.productForm.controls['logo'].setValue(f._file);
      }
    };
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.productId = +params['id']; // (+) converts string 'id' to a number
    });
    this.fetchProductInfo();
  }

  fetchProductInfo(): void {
    this.productService.getInfo(this.productId,'').subscribe(
      (response) => {
        this.productInformation = response;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching product info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  save(): void {
    if (this.productForm.valid && this.wordingForm.valid) {
      const wording = this.wordingForm.controls['wording'].value.replace(
        /<br>/g,
        '<br></br>'
      );
      const product = Object.assign({}, this.productForm.value, { wording });

      product.inceptionDate = Date.parse(product.inceptionDate);
      product.expirationDate = Date.parse(product.expirationDate);
    } else {
      this.formService.validateAllFormFields(this.productForm);
      this.formService.validateAllFormFields(this.wordingForm);
      this.stage = 'info';
    }
  }

  ngOnDestroy(): void {}

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  submitProductForm(): void {
    const formData = this.productForm.value;
    this.productService
      .update(this.productId, {
        product_name: formData.productName,
        product_number: 7,
        company_name: 'Patel Finance',
        region: formData.region,
        estimated_price: '$100M',
        product_description: 'Product Description',
        rating_table_id: 1,
        ratings_table_media_id: 1,
      })
      .subscribe(
        (response) => {
          this.router.navigate(['/mga/products']);
        },
        (err) => {
          this.stage = 'info';
          this.serverErrMsg = err.error;
          if (err.status === 412) {
            this.serverErrMsg.response = err.error.message;
          }
        }
      );
    if (this.productForm.valid) {
      this.goToEditor();
    } else {
      this.scrollTop();
    }
  }

  scrollTop(): void {
    setTimeout(() => {
      const error = this.el.nativeElement.querySelector('div > .error');

      const config: ScrollToConfigOptions = {
        target: error.getAttribute('scroll-to') || 'scrollTop',
      };

      this._scrollToService.scrollTo(config);
    }, 1);
  }

  setStore(store: { name: string; value: number }): void {
    this.productForm.controls['storeId'].setValue(store.value);
  }

  setCategory(category: { name: string; value: number }): void {
    this.productForm.controls['category'].setValue(category.value);
  }

  setSubCategories(subCategory: { name: string; value: number }): void {
    this.productForm.controls['subCategory'].setValue(subCategory.value);
  }

  setRegions(region: { name: string; value: number }): void {
    this.productForm.controls['region'].setValue(region.value);
  }

  goToPriceEngine(): void {
    if (this.wordingForm.valid) {
      this.stage = 'pricing';
      window.scroll(0, 0);
      // this.accountComponent.productStepper.builder.done = true;
    }
  }

  goToInfoStep(): void {
    this.stage = 'info';
    window.scroll(0, 0);
  }

  goToEditor(): void {
    this.stage = 'editor';
    window.scroll(0, 0);
    // this.accountComponent.setProductStepper('editor');
  }

  inceptionFilter(date: moment.Moment): boolean {
    if (
      this.productForm.controls['expirationDate'].value == null ||
      date.isBefore(moment(this.productForm.controls['expirationDate'].value))
    ) {
      return true;
    }
    return false;
  }

  expirationFilter(date: moment.Moment): boolean {
    if (
      this.productForm.controls['inceptionDate'].value == null ||
      date.isAfter(moment(this.productForm.controls['inceptionDate'].value))
    ) {
      return true;
    }
    return false;
  }
}
