import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.scss'],
})
export class DropDownMenuComponent implements OnChanges, OnInit {
  @Input() options: any[];
  @Input() value: any;
  @Input() name: string;
  @Input() defaultVal: any;
  @Output() select = new EventEmitter<any>();
  showItems: boolean;
  selectedOption: any;

  ngOnInit(): void {
    this.name = this.value;

    if (this.options.length) {
      this.selectedOption = this.options.filter(
        (item) => item.id === this.value
      )[0];
    }
    this.selectItem(this.selectedOption);
  }
  /**
   * @summary On data change listener
   */
  ngOnChanges(changes): void {}

  /**
   * Select item from list of options
   * @param selectOption selected option
   */
  selectItem(selectOption): void {
    this.select.emit({ name: this.value, value: selectOption?.id });
  }
}
