import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// image picker
import { ImagePickerConf } from 'ngp-image-picker';
// external
import {
  Location,
  Appearance,
} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
// custom validation
import {
  AddressService,
  ErrorHandlingService,
  FirmService,
  FormValidatorService,
  MediaService,
  NotificationService,
} from 'src/app/core/services';
import { Router } from '@angular/router';

import moment from 'moment';
@Component({
  selector: 'app-broker-firm-create',
  templateUrl: './broker-firm-create.component.html',
  styleUrls: ['./broker-firm-create.component.scss'],
})
export class BrokerFirmCreateComponent implements OnInit {
  brokerFirmForm: FormGroup;
  buttonFlag = false;
  // google maps
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  // image picker config
  imagePickerConfig: ImagePickerConf = {
    borderRadius: '4px',
    language: 'en',
    width: '100%',
    height: '170px',
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private firmService: FirmService,
    private addressService: AddressService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.brokerFirmForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      logo: ['', Validators.required],
      firm_address: ['', Validators.required],
      city: [''],
      zip_code: [''],
      date_of_incorporation: [''],
    });
  }

  ngOnInit(): void {}

  addEvent(data): void {
    const selectedDate = new Date(
      moment(data.target.value).format('YYYY-MM-DD')
    );
    this.brokerFirmForm.controls['date_of_incorporation'].setValue(
      selectedDate.toISOString().split('T')[0]
    );
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    const addedYear = new Date(year + 1, month, day);
  }
  onImageChanged(file): void {
    this.brokerFirmForm.get('logo').setValue(file);
  }

  getCityFromResult(result: PlaceResult): string {
    const cities = result.address_components.filter((item) => {
      if (item.types.includes('locality')) {
        return item.long_name;
      }
    });
    return cities.length > 0 ? cities[0].long_name : 'N/A';
  }

  getPostalCodeFromResult(result: PlaceResult): string {
    const postalCodes = result.address_components.filter((item) => {
      if (item.types.includes('postal_code')) {
        return true;
      }
    });
    return postalCodes.length > 0 ? postalCodes[0].long_name : 'N/A';
  }

  onAutocompleteSelected(result: PlaceResult): void {
    this.brokerFirmForm.get('firm_address').setValue(result.name);
    this.brokerFirmForm.get('city').setValue(this.getCityFromResult(result));
    this.brokerFirmForm
      .get('zip_code')
      .setValue(this.getPostalCodeFromResult(result));
  }

  submitForm(): void {
    if (this.brokerFirmForm.valid) {
      this.buttonFlag = true;
      this.createNewBrokerFirm()
        .then((response) => {
          this.notificationService.success(
            'Broker Firm has been created successfully',
            ''
          );
          this.router.navigate(['/mga/broker-firms']);
        })
        .catch((error) => {
          this.notificationService.error(
            'error occurred while submitting form',
            this.errorHandlingService.getErrors(error)
          );
        });
    } else {
      this.formValidatorService.validateAllFormFields(this.brokerFirmForm);
    }
  }

  async createNewBrokerFirm(): Promise<any> {
    try {
      const logoResponse = await this.mediaService
        .create(this.brokerFirmForm.get('logo').value)
        .toPromise();
      const addressResponse = await this.addressService
        .create({
          street_address: this.brokerFirmForm.get('firm_address').value,
          city: this.brokerFirmForm.get('city').value,
          zip_code: this.brokerFirmForm.get('zip_code').value,
          country_id: 1,
        })
        .toPromise();
      const firmResponse = await this.firmService
        .createBrokerFirm({
          date_of_incorporation: this.brokerFirmForm.get(
            'date_of_incorporation'
          ).value,
          name: this.brokerFirmForm.get('name').value,
          address_id: addressResponse.id,
          logo_id: logoResponse.id,
        })
        .toPromise();
      return firmResponse;
    } catch (error) {
      this.buttonFlag = false;
      this.formValidatorService.validateAllFormFields(this.brokerFirmForm);
      this.notificationService.error(
        'Error occurred while creating new broker firm',
        this.errorHandlingService.getErrors(error)
      );
    }
  }
}
