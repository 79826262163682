import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// components
import { QuoteStatusDialogComponent } from '../../../components/quote-status-dialog/quote-status-dialog.component';
import { MatDialog } from '@angular/material/dialog';
// loader
import { NgxSpinnerService } from 'ngx-spinner';
// service
import {
  QuoteService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
@Component({
  selector: 'app-quote-list-table',
  templateUrl: './quote-list-table.component.html',
  styleUrls: ['./quote-list-table.component.scss'],
})
export class QuoteListTableComponent implements OnInit, OnChanges {
  @Input() quotes: Array<any>;
  @Input() displayAllColumns: boolean;
  @Input() quotePrice: any;
  @Input() checkData: any;
  @Output() selectedQuote = new EventEmitter<any>();
  @Output() quoteStatusChange = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<any>();
  isWait = true;

  constructor(
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private quoteService: QuoteService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['quotes'].currentValue) {
      this.dataSource.data = this.quotes;
      this.fetchingQuotePrice();
    }
  }

  ngOnInit(): void {
    this.spinner.show();
  }

  openQuoteStatusChangeDialog(data): void {
    const dialogRef = this.dialog.open(QuoteStatusDialogComponent, {
      data: {
        title: `Are you sure you want to change quote status from Non-Binding to Binding for client ${
          data?.application?.client?.first_name +
          data?.application?.client?.last_name
        } and quote id${data?.id}`,
        result: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.quoteService
          .updateQuoteStatus(result, { quote_status_id: 2 })
          .subscribe(
            (res) => {
              this.quoteStatusChange.emit(res);
            },
            (err) => {
              this.notificationService.error(
                'Error occurred while updating quote status',
                this.errorHandlingService.getErrors(err)
              );
            }
          );
      }
    });
  }

  fetchingQuotePrice(): any {
    this.dataSource.data = this.quotes;
    this.dataSource.paginator = this.paginator;
  }

  onQuoteSelect(selectedQuoteData: any): void {
    this.selectedQuote.emit(selectedQuoteData);
  }
}
