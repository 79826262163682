import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// services
import {
  ErrorHandlingService,
  NotificationService,
  QuoteService,
} from 'src/app/core/services';
@Component({
  selector: 'app-quote-view',
  templateUrl: './quote-view.component.html',
  styleUrls: ['./quote-view.component.scss'],
})
export class QuoteViewComponent implements OnInit {
  quoteId: any;
  quoteDetails: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private quoteService: QuoteService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.quoteId = this.activatedRoute.snapshot?.paramMap?.get('id');

    this.getQuoteInformation();
  }

  getQuoteInformation(): void {
    this.quoteService.getInfo(this.quoteId).subscribe(
      (response) => {
        this.quoteDetails = response;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching quote information',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
}
