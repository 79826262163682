import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
// service
import {
  InsurancePolicyService,
  MediaService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';

import moment from 'moment';
import { switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-policy-info',
  templateUrl: './policy-info.component.html',
  styleUrls: ['./policy-info.component.scss'],
})
export class PolicyInfoComponent implements OnInit {
  policyUpdateForm: FormGroup;
  clientId: any;
  isEditingMode = false;
  policyId: any;
  policyDeatail: any;
  pendingLawSuitFiles: File[] = [];
  pendingLawSuiteMediaIds: string[] = [];

  constructor(
    private router: Router,
    private insurancePolicyService: InsurancePolicyService,
    private mediaService: MediaService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private errorHandlingService: ErrorHandlingService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.policyId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.fetchPolicyDetails();
  }

  fetchPolicyDetails(): void {
    this.insurancePolicyService.getInfo(this.policyId).subscribe(
      (response) => {
        this.policyDeatail = response;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching policy details',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  getPdf(fileData: any): void {
    FileSaver.saveAs(fileData, `application.pdf`);
  }

  backToPolicy(): void {
    this.router.navigate(['/broker/policies']);
  }
}
