import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// service
import {
  UserService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  currentUser: any;
  currentUserToken: any;
  isSuccess = false;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.createReserPasswordForm();
  }

  createReserPasswordForm(): void {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required])],
    });
  }

  handleResetPassword(resetPasswordObj: any): void {
    this.userService.forgetPassword(resetPasswordObj).subscribe(
      (res) => {
        this.isSuccess = true;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while password reset',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  resetPassword(): void {
    if (this.resetPasswordForm.valid) {
      this.handleResetPassword(this.resetPasswordForm.value);
    } else {
      this.notificationService.error('', 'Please enter email addres');
    }
  }
}
