import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
// rxjs
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// components
import { BrokerFirmCreateDialogComponent } from '../broker-firm-create-dialog/broker-firm-create-dialog.component';
// service
import {
  NotificationService,
  BrokerService,
  ProductService,
  ErrorHandlingService,
} from '../../core/services';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss'],
})
export class ProductViewComponent implements OnInit, AfterViewInit {
  public productId: number;
  public myFormControl = new FormControl();
  public brokerFirmList: string[] = [];
  public brokerFirmsAssciateWithProduct: any;
  public brokerList: any;
  public brokerDataSource: any;
  public filteredBrokerFirmOptions: Observable<string[]>;
  searchedItem: string;
  public displayedColumns: any;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private brokerService: BrokerService,
    private notificationService: NotificationService,
    private productService: ProductService,
    public dialog: MatDialog,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.displayedColumns = ['firm_name', 'created_at', 'select'];
    this.route.params.subscribe((params) => {
      this.productId = +params['id'];
    });
    this.getBrokerFirmListFromProdcutId(this.productId);
    this.getAllBrokerFirms();
    this.getProductinfo(this.productId);
    this.filteredBrokerFirmOptions = this.myFormControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  getAllBrokerFirms(): void {
    this.brokerService.getBrokerFirmList().subscribe(
      (response) => {
        this.brokerFirmList = this.filterBrokerFirmList(response);
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching all broker firms',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  filterBrokerFirmList(data: any): any {
    const brokerFirmArray = [];
    const brokerFirm = data.map((response) => {
      brokerFirmArray.push(response.firm.firm_name);
    });
    return brokerFirmArray;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.brokerFirmList?.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  getSelectedSearched(selectedData): void {
    this.searchedItem = selectedData;
  }

  inviteBrokerFirmButtonClick(): void {
    this.notificationService.success(
      `${this.searchedItem}`,
      'has been invited successfully'
    );
  }

  openBrokerFirmCreateDialog(): void {
    const dialogRef = this.dialog.open(BrokerFirmCreateDialogComponent, {
      width: '680px',
      height: '700px',
      data: { productId: this.productId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getProductinfo(this.productId);
    });
  }

  getBrokerFirmListFromProdcutId(productId: number): void {
    this.brokerService.getBrokerFirmsByProductId(productId).subscribe(
      (response) => {
        this.brokerFirmsAssciateWithProduct = response;
        this.brokerDataSource = new MatTableDataSource(this.brokerList);
        this.brokerDataSource.sort = this.sort;

        const sortState: Sort = { active: 'created_at', direction: 'desc' };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching broker firm list',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
  getProductinfo(id: number): void {
    this.productService.getInfo(id,'').subscribe(
      (response) => {
        // console.log("product info", response);
        // this.brokerList = response.brokers;
        // this.brokerDataSource = new MatTableDataSource(this.brokerList);
        // this.brokerDataSource.sort = this.sort;
        // const sortState: Sort = { active: 'created_at', direction: 'desc' };
        // this.sort.active = sortState.active;
        // this.sort.direction = sortState.direction;
        // this.sort.sortChange.emit(sortState);
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching product info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
  ngAfterViewInit(): void {
    this.brokerList.sort = this.sort;
  }
}
