import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { Product, ProductCategory } from '../models';

@Injectable()
export class UnderwritingService {
  constructor(private apiService: ApiService) {}

  create(data = {}): Observable<any> {
    return this.apiService
      .post('/underwriting', data)
      .pipe(map((res) => res.data));
  }

  getInfo(id: any): Observable<any> {
    return this.apiService
      .get(`/underwriting/searchByProductId?insurance_product_id=${id}`)
      .pipe(map((res) => res.data));
  }
  update(id: number,data = {}): Observable<any> {
    return this.apiService
      .put(`/underwriting/${id}`, data)
      .pipe(map((res) => res.data));
  }

  updateAll(data = {}): Observable<any> {
    return this.apiService
      .put(`/underwriting`, data)
      .pipe(map((res) => res.data));
  }
}