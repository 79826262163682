import { Injectable } from '@angular/core';
// service
import { NotificationService } from '../services/notification.service';
// model
import { ErrorHandling } from '../models/error-handling.models';

@Injectable()
export class ErrorHandlingService {
  storeErrors: Array<any> = [];
  constructor(private notificationService: NotificationService) {}

  diffrentErrorsHandling(err: ErrorHandling): void {
    if (err.status === 500) {
      this.notificationService.error('', err.statusText);
    } else if (err.status === 422) {
      for (const error in err?.error?.errors) {
        if (error) {
          this.notificationService.error('', err.error.errors[error]);
        }
      }
    }
  }

  getErrors(err: ErrorHandling): any {
    if (err.status === 500) {
      return this.storeErrors.push(err.statusText);
    } else if (err.status === 422) {
      for (const error in err?.error?.errors) {
        if (error) {
          this.storeErrors.push(err.error.errors[error]);
        }
      }
      return this.storeErrors;
    } else if (err.status === 403) {
      this.storeErrors.push(err.error.message);
      // return  this.storeErrors;
      return err.error.message;
    } else if (err.status === 400) {
      return err?.error?.message;
    }
  }
}
