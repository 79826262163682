import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// image picker
import { ImagePickerConf } from 'ngp-image-picker';
// external
import {
  Location,
  Appearance,
} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
// custom validation
import {
  AddressService,
  FirmService,
  FormValidatorService,
  MediaService,
  BrokerService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-broker-firm-update',
  templateUrl: './broker-firm-update.component.html',
  styleUrls: ['./broker-firm-update.component.scss'],
})
export class BrokerFirmUpdateComponent implements OnInit {
  brokerFirmId: string;
  brokerFirmInfo: any;
  brokerFirmUpdateForm: FormGroup;
  buttonFlag = false;
  // google maps
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: any;
  preSelectedAddress: string;
  // image picker config
  imagePickerConfig: ImagePickerConf = {
    borderRadius: '4px',
    language: 'en',
    width: '100%',
    height: '170px',
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private firmService: FirmService,
    private addressService: AddressService,
    private brokerService: BrokerService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.brokerFirmId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.fetchStoreInfo();
  }
  fetchStoreInfo(): void {
    this.brokerService.getBrokerFirmInfo(this.brokerFirmId).subscribe(
      (response) => {
        this.brokerFirmInfo = response;
        this.preSelectedAddress =
          this.brokerFirmInfo?.firm?.address?.street_address +
          ' ,' +
          this.brokerFirmInfo?.firm?.address.city +
          ' ,' +
          this.brokerFirmInfo?.firm?.address?.zip_code;
        this.createBrokerFirmFormGroup();
      },
      (error) => {
        this.notificationService.error(
          'Error while fetching store info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  createBrokerFirmFormGroup(): void {
    this.brokerFirmUpdateForm = this.formBuilder.group({
      name: [
        this.brokerFirmInfo?.firm?.name,
        Validators.compose([Validators.required]),
      ],
      firm_address: [
        `${this.brokerFirmInfo?.firm?.address?.street_address}, ${this.brokerFirmInfo?.firm?.address?.city}`,
        Validators.compose([Validators.required]),
      ],
      city: [''],
      zip_code: [''],
      logo: [''],
    });
  }

  onImageChanged(file): void {
    this.brokerFirmUpdateForm.get('logo').setValue(file);
  }

  getCityFromResult(result: PlaceResult): string {
    const cities = result.address_components.filter((item) => {
      if (item.types.includes('locality')) {
        return item.long_name;
      }
    });
    return cities.length > 0 ? cities[0].long_name : 'N/A';
  }

  getPostalCodeFromResult(result: PlaceResult): string {
    const postalCodes = result.address_components.filter((item) => {
      if (item.types.includes('postal_code')) {
        return true;
      }
    });
    return postalCodes.length > 0 ? postalCodes[0].long_name : 'N/A';
  }

  onAutocompleteSelected(result: PlaceResult): void {
    this.brokerFirmUpdateForm.get('firm_address').setValue(result.name);
    this.brokerFirmUpdateForm
      .get('city')
      .setValue(this.getCityFromResult(result));
    this.brokerFirmUpdateForm
      .get('zip_code')
      .setValue(this.getPostalCodeFromResult(result));
  }

  submitForm(): void {
    if (this.brokerFirmUpdateForm.valid) {
      this.buttonFlag = true;
      this.updateBrokerFirm().then(
        (response) => {
          this.router.navigate(['/mga/broker-firms']);
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while submitting form',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
    } else {
      this.formValidatorService.validateAllFormFields(
        this.brokerFirmUpdateForm
      );
    }
  }

  async updateBrokerFirm(): Promise<any> {
    const addressId = this.brokerFirmInfo?.firm?.address?.id;
    try {
      const logoResponse = this.brokerFirmUpdateForm.get('logo').value
        ? await this.mediaService
            .create(this.brokerFirmUpdateForm.get('logo').value)
            .toPromise()
        : null;
      const addressResponse = await this.addressService
        .update(addressId, {
          street_address: this.brokerFirmUpdateForm.get('firm_address').value,
          city: this.brokerFirmUpdateForm.get('city').value,
          zip_code: this.brokerFirmUpdateForm.get('zip_code').value,
          country_id: 1,
        })
        .toPromise();
      const firmResponse = await this.firmService
        .update(this.brokerFirmInfo?.firm.id, {
          name: this.brokerFirmUpdateForm.get('name').value,
          address_id: addressId,
          logo_id: logoResponse
            ? logoResponse.id
            : this.brokerFirmInfo?.firm?.logo.id,
          documents: [],
        })
        .toPromise();
    } catch (error) {
      this.buttonFlag = false;
      this.formValidatorService.validateAllFormFields(
        this.brokerFirmUpdateForm
      );
      this.notificationService.error(
        'Error occurred while updating broker firm',
        this.errorHandlingService.getErrors(error)
      );
    }
  }
}
