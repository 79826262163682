import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// service
import {
  StripeService,
  EftPaymentService,
  ApplicationService,
  NotificationService,
  UserService,
  PaymentService,
} from '../../core/services';
import { Router } from '@angular/router';

const cardStyle = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @Output() afterPayment = new EventEmitter<any>();
  paymentOption: string;
  buttonFlag = false;
  totalPaymentAmount: number;
  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string;
  eftData = {};
  formData = new FormData();
  application: any;
  id: any;
  applicationId: any;
  insuranceProduct: any;
  currentUser: any;
  isSuccess = false;
  paymentTransactionData: any;

  constructor(
    private cd: ChangeDetectorRef,
    private stripService: StripeService,
    private eftPaymentService: EftPaymentService,
    private router: Router,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private userService: UserService,
    private paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.paymentOption = 'credit';
    this.route.params.subscribe((params) => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });
    this.fetchApplicationInfo();
    this.currentUser = this.userService.getCurrentUser();
  }

  fetchApplicationInfo(): void {
    this.applicationService.getInfo(this.id).subscribe(
      (res) => {
        this.application = res;
        this.applicationId = res.id;
        this.insuranceProduct = res.insurance_products[0];
      },
      (err) => {}
    );
  }

  eftPayment(): void {
    this.buttonFlag = true;
    this.handleEftPayment();
  }

  ngAfterViewInit(): void {
    this.initiateCardElement();
  }

  ngOnDestroy(): void {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  selectedOption(event: any): void {
    this.paymentOption = event.value;
  }

  handleEftPayment(): void {
    // this.eftPaymentService.getPostManCode();
    // this.formData.append('AccountID', 'wealthtab');
    // this.formData.append('Key', '7f8958db73377704038f4ba2cf465203d6702e1f');
    // this.formData.append(
    //   'Signature',
    //   'ed5e13fb092ddf1563192d9300981a1d91a575f5'
    // );
    // this.formData.append(
    //   'RedirectURL',
    //   'http://localhost:4200/client/application/payment'
    // );

    // this.eftData['AccountID'] = 'wealthtab';
    // this.eftData['Key'] = '7f8958db73377704038f4ba2cf465203d6702e1f';
    // this.eftData['Signature'] = 'ed5e13fb092ddf1563192d9300981a1d91a575f5';
    // this.eftData['RedirectURL'] =
    //   'http://localhost:4200/client/application/payment';

    const param: any = { userId: 2 };
    this.eftPaymentService.generateEmbedUrl(this.applicationId).subscribe(
      (res) => {
        window.location.href = `${res.EmbedURL}`;
        // console.log('EFT res >>>>>', res);
      },
      (err) => {
        //  console.log('err', err);
      }
    );
  }

  initiateCardElement(): void {
    this.card = elements.create('card', { cardStyle });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  onChange({ error }): void {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }

  async createStripeToken(): Promise<any> {
    this.buttonFlag = true;

    this.notificationService.info('Please wait', 'Payment in process', {
      toastId: 999,
      timeOut: 100000,
      // preventDuplicates:true,
      // resetTimeoutOnDuplicate:true,
      positionClass: 'toast-bottom-right',
    });
    // const { token, error } = await stripe.createToken(this.card);
    // if (token) {
    //   // this.onSuccess(token);
    // } else {
    //   // this.onError(error);
    // }
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        name: `${this.currentUser?.first_name} ${this.currentUser?.last_name}`, // Fetch data from User
      },
    });
    if (paymentMethod) {
      // console.log('paymnet method >>>>', paymentMethod);
      const data = {};
      data['paymentId'] = paymentMethod?.id;
      const create = await this.stripService.create(data).toPromise();
      if (create && create.status === 200) {
        // Charge amount
        data['charge'] = this.application?.premium_amount; // replace with actual amount
        data['description'] = 'Pass Product ID here.';
        const charge = await this.stripService.payment(data).toPromise();
        if (charge && charge.status === 200) {
          this.notificationService.success(
            'Your payment has been processed successfully. A policy will be issued to you shortly!'
          );
          // The payment transaction will be stored here
          this.storePaymentTransaction(charge?.data);
        } else {
          this.notificationService.error(
            'Your payment can’t be processed. Please try again',
            ''
          );
          // console.log(charge);
          this.buttonFlag = false;
        }
        // console.log('charge response', charge);
      } else {
        this.notificationService.error(
          'Your payment can’t be processed. Please try again',
          ''
        );
        // console.log(create);
        this.buttonFlag = false;
      }
    } else {
      this.notificationService.error(
        'Your payment can’t be processed. Please try again',
        ''
      );
      //  console.log(paymentMethod);
      this.buttonFlag = false;
    }
  }
  onError(error): void {
    if (error.message) {
      this.cardError = error.message;
    }
  }

  changeApplicationStatus(data: any, transactionId: any): void {
    this.applicationService.changeApplicationStatus(data).subscribe(
      () => {},
      (err) => {
        this.notificationService.error(
          'Application status does not change. Please contact admin',
          ''
        );
      }
    );
  }

  storePaymentTransaction(chargeResponseData: any): void {
    const paymentTransactionObject = {};
    paymentTransactionObject['status'] = 'Paid';
    paymentTransactionObject[
      'insurance_products_id'
    ] = this.insuranceProduct?.id;
    paymentTransactionObject['user_id'] = this.currentUser?.id;
    paymentTransactionObject['application_id'] = this.applicationId;
    paymentTransactionObject['external_payment_id'] = chargeResponseData.id;
    paymentTransactionObject['payment_type'] = 'STRIPES';
    paymentTransactionObject['external_paymentmethod_id'] =
      chargeResponseData.payment_methods;
    paymentTransactionObject['external_customer_id'] =
      chargeResponseData.customer;

    this.paymentService.paymentTransaction(paymentTransactionObject).subscribe(
      (res) => {
        this.paymentTransactionData = res.data;
        this.isSuccess = true;
        const data1 = {
          statusId: 10,
          id: this.applicationId,
        };
        this.changeApplicationStatus(data1, res?.data?.transaction_id);
      },
      (err) => {}
    );
  }
}
