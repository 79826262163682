export * from './account-layout/account-layout.component';
export * from './admin-layout/admin-layout.component';
export * from './broker-layout/broker-layout.component';
export * from './client-layout/client-layout.component';
export * from './main-layout/main-layout.component';
export * from './main-layout/header/header.component';
export * from './main-layout/footer/footer.component';
export * from './mga-layout/mga-layout.component';
export * from './quote-layout/quote-layout.component';
export * from './quote-layout/quote-header/quote-header.component';
// custom layout
export * from './custom-header/custom-header.component';
