import { Media } from './media.model';

export class Product {
  id?: number;
  name: string;
  aggregate_limit: number;
  product_number: string;
  description: string;
  terms_and_conditions: string;
  region: string;
  valid_from: Date;
  valid_to: Date;
  logo?: Media;
  created_at: Date;
  updated_at: Date;
  rating_table?: any;
  insurance_product_category_id: any;
  store_id: any;
  brokers: any;
  brokerfirms: any;
  is_underwriting_review_req: any;
}

export class ProductCategory {
  id?: any;
  name: string;
  created_at: Date;
  updated_at: Date;
}
