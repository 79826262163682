import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';
// models
import { Industry } from '../models';

@Injectable()
export class IndustryService {
  constructor(private apiService: ApiService) {}

  getList(): Observable<Industry[]> {
    return this.apiService.get(`/industry`).pipe(map((res) => res.data));
  }

  searchByName(searchTerm: string): Observable<Industry[]> {
    return this.apiService
      .get(`/industry/search?name=${searchTerm}&like[]=name`)
      .pipe(map((res) => res.data));
  }
}
