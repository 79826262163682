import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// service
import {
  UserService,
  FirmService,
  NotificationService,
  ErrorHandlingService,
} from '../../../core/services';
@Component({
  selector: 'app-broker-layout',
  templateUrl: './broker-layout.component.html',
  styleUrls: ['./broker-layout.component.scss'],
})
export class BrokerLayoutComponent implements OnInit {
  currentUser;
  public brokerFirmLogo: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private firmService: FirmService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    //  this.fetchBrokerFirmInfo();
    // this.fetchCurrentUserInfo();
  }

  fetchBrokerFirmInfo(): void {
    this.firmService
      .getBrokerFirmInfo(this.currentUser?.userable?.broker_firm_id)
      .subscribe(
        (response) => {
          this.brokerFirmLogo = response?.firm?.logo?.url;
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching broker firm info',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  fetchCurrentUserInfo(): void {
    this.userService.getInfo(this.currentUser.id).subscribe(
      (res) => {},
      (err) => {}
    );
  }

  handleLogout(): void {
    this.userService.purgeAuth();
    this.router.navigate(['/account/signin']);
  }

  handleSetting(): void {
    this.router.navigate(['/broker/information']);
  }

  gotoHome(): void {
    // this.userService.purgeAuth();
    this.router.navigate(['/']);
  }
}
