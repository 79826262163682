import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services';
@Component({
  selector: 'app-quote-success',
  templateUrl: './quote-success.component.html',
  styleUrls: ['./quote-success.component.scss'],
})
export class QuoteSuccessComponent implements OnInit {
  @Input() selectedProduct: any;
  @Input() businessInfo: any;
  @Input() userInfo: any;
  @Input() applicationInfo: any;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToLogInPage(): void {
    this.router.navigate([`/broker/applications`]);
  }
}
