import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// image picker
import { ImagePickerConf } from 'ngp-image-picker';
// external
import { Appearance } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
// custom validation
import {
  AddressService,
  FirmService,
  FormValidatorService,
  MediaService,
  BrokerService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
@Component({
  selector: 'app-broker-firm-update',
  templateUrl: './broker-firm-update.component.html',
  styleUrls: ['./broker-firm-update.component.scss'],
})
export class BrokerFirmUpdateComponent implements OnInit {
  brokerFirmId: string;
  brokerFirmInfo: any;
  brokerFirmUpdateForm: FormGroup;
  brokerFirmLogo: File;
  preSelectedAddress: string;
  buttonFlag = false;
  // google maps
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  initialLogoPresent = false;
  // image picker config
  imagePickerConfig: ImagePickerConf = {
    borderRadius: '4px',
    language: 'en',
    width: '100%',
    height: '170px',
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formValidatorService: FormValidatorService,
    private mediaService: MediaService,
    private firmService: FirmService,
    private addressService: AddressService,
    private brokerService: BrokerService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.brokerFirmId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.fetchStoreInfo();
  }

  fetchStoreInfo(): void {
    this.brokerService.getBrokerFirmInfo(this.brokerFirmId).subscribe(
      (response) => {
        this.preSelectedAddress =
          response?.firm?.address?.street_address +
          ' ,' +
          response?.firm?.address?.city +
          ' ,' +
          response?.firm?.address?.zip_code;
        this.brokerFirmInfo = response;
        if (this.brokerFirmInfo?.firm?.logo) {
          this.initialLogoPresent = true;
        }
        this.createBrokerFirmFormGroup();
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while fetching store info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  createBrokerFirmFormGroup(): void {
    this.brokerFirmUpdateForm = this.formBuilder.group({
      name: [
        this.brokerFirmInfo?.firm?.name,
        Validators.compose([Validators.required]),
      ],
      logo: [''],
      firm_address: [
        this.brokerFirmInfo?.firm?.address?.street_address,
        Validators.compose([Validators.required]),
      ],
      city: [this.brokerFirmInfo?.firm?.address?.city],
      zip_code: [this.brokerFirmInfo?.firm?.address?.zip_code],
      date_of_incorporation: [
        moment(this.brokerFirmInfo?.firm?.date_of_incorporation),
        Validators.compose([Validators.required]),
      ],
    });
  }

  onImageChanged(file): void {
    this.brokerFirmUpdateForm.get('logo').setValue(file);
    this.initialLogoPresent = false;
  }

  addEvent(data): void {
    const selectedDate = new Date(
      moment(data.target.value).format('YYYY-MM-DD')
    );
    this.brokerFirmUpdateForm.controls['date_of_incorporation'].setValue(
      selectedDate.toISOString().split('T')[0]
    );
  }
  getCityFromResult(result: PlaceResult): string {
    const cities = result.address_components.filter((item) => {
      if (item.types.includes('locality')) {
        return item.long_name;
      }
    });
    return cities.length > 0 ? cities[0].long_name : 'N/A';
  }

  getPostalCodeFromResult(result: PlaceResult): string {
    const postalCodes = result.address_components.filter((item) => {
      if (item.types.includes('postal_code')) {
        return true;
      }
    });
    return postalCodes.length > 0 ? postalCodes[0].long_name : 'N/A';
  }

  onAutocompleteSelected(result: PlaceResult): void {
    this.brokerFirmUpdateForm.get('firm_address').setValue(result.name);
    this.brokerFirmUpdateForm
      .get('city')
      .setValue(this.getCityFromResult(result));
    this.brokerFirmUpdateForm
      .get('zip_code')
      .setValue(this.getPostalCodeFromResult(result));
  }

  showSuccessNotification(): void {
    this.notificationService.success(
      'Broker firm information has been updated successfully',
      ''
    );
    this.router.navigate(['/admin/broker-firms']);
  }

  submitForm(): void {
    if (this.brokerFirmUpdateForm.valid) {
      this.buttonFlag = true;
      this.updateBrokerFirm(this.initialLogoPresent).then(
        (response) => {
          this.showSuccessNotification();
        },
        (err) => {
          this.buttonFlag = false;
          this.notificationService.error(
            'Error occurred while submitting form',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
    } else {
      this.formValidatorService.validateAllFormFields(
        this.brokerFirmUpdateForm
      );
    }
  }

  async updateBrokerFirm(initialLogoPresent: boolean): Promise<any> {
    const addressId = this.brokerFirmInfo?.firm?.address?.id;
    try {
      let logoResponse: any = null;

      if (!initialLogoPresent) {
        logoResponse = await this.mediaService
          .create(this.brokerFirmUpdateForm.get('logo').value)
          .toPromise();
      }

      const addressResponse = await this.addressService
        .update(addressId, {
          street_address:
            this.brokerFirmUpdateForm.get('firm_address').value ||
            this.brokerFirmInfo?.firm?.address?.street_address,
          city:
            this.brokerFirmUpdateForm.get('city').value ||
            this.brokerFirmInfo?.firm?.address?.city,
          zip_code:
            this.brokerFirmUpdateForm.get('zip_code').value ||
            this.brokerFirmInfo?.firm?.address?.zip_code,
          country_id: 1, // TODO: Replace static country to dynamic after backend refactor
        })
        .toPromise();
      const firmResponse = await this.firmService
        .update(this.brokerFirmInfo?.firm.id, {
          name: this.brokerFirmUpdateForm.get('name').value,
          date_of_incorporation: this.brokerFirmUpdateForm.value
            .date_of_incorporation,
          address_id: addressResponse.id,
          logo_id: logoResponse
            ? logoResponse.id
            : this.brokerFirmInfo?.firm?.logo.id,
          documents: [],
        })
        .toPromise();
    } catch (error) {
      this.formValidatorService.validateAllFormFields(
        this.brokerFirmUpdateForm
      );
    }
  }
}
