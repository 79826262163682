import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// service
import {
  UserService,
  FirmService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
@Component({
  selector: 'app-store-manager-update',
  templateUrl: './store-manager-update.component.html',
  styleUrls: ['./store-manager-update.component.scss'],
})
export class StoreManagerUpdateComponent implements OnInit {
  phoneMask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  currentUser: any;
  mgaUpdateForm: FormGroup;
  selectedOption: any;
  mgaUserInfo: any;
  phoneNumber: any;
  storeInfo: any;
  storeId: any;
  storeMangerFirmInfo: any;
  buttonFlag = false;
  storeManagerType: any[] = [
    { name: 'No', value: false },
    { name: 'Yes', value: true },
  ];
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private firmService: FirmService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.fetchMgaInfo();
    this.fetchMgaFirmInfo();
    this.updateMga();
  }

  updateMga(): void {
    this.mgaUpdateForm = this.formBuilder.group({
      first_name: [
        this.mgaUserInfo?.first_name,
        Validators.compose([Validators.required]),
      ],
      last_name: [
        this.mgaUserInfo?.last_name,
        Validators.compose([Validators.required]),
      ],
      phone_number: [this.phoneNumber],
      store_manager_type: [
        this.storeManagerType[this.selectedOption?.userable?.admin].value,
      ],
    });
  }

  fetchMgaInfo(): void {
    this.userService.getInfo(this.currentUser.id).subscribe(
      (res) => {
        this.selectedOption = res?.data;
        this.mgaUserInfo = res.data;
        this.phoneNumber = this.testingPhoneNumber(
          this.mgaUserInfo?.userable?.phone_number
        );

        this.updateMga();
      },
      (err) => {}
    );
  }
  testingPhoneNumber(preSelectedPhoneNumber: any): any {
    const cleaned = ('' + preSelectedPhoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + '- ' + match[2] + '- ' + match[3];
    }
  }
  formatPhoneNumber(event: any): string {
    const cleaned = ('' + event).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      this.phoneNumber =
        '(' + match[1] + ') ' + '- ' + match[2] + '- ' + match[3];
    }
    // this.brokerCreateForm.patchValue({ phone_number: this.phoneNumber });
    return null;
  }
  fetchMgaFirmInfo(): void {
    this.firmService
      .getMgaInfo(this.currentUser?.userable?.mga_firm_id)
      .subscribe(
        (response) => {
          this.storeMangerFirmInfo = response;
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching firm info',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  onBackToStore(): void {
    this.router.navigate(['/mga/information']);
  }

  submitForm(): void {
    this.userService
      .updateStoreManager(
        this.mgaUpdateForm.value,
        this.currentUser.userable_id,
        this.mgaUserInfo?.userable?.mga_firm_id,
        this.mgaUserInfo.id
      )
      .subscribe(
        (res) => {
          this.buttonFlag = true;
          this.router.navigate(['/mga/information']);
        },
        (err) => {
          this.buttonFlag = false;
        }
      );
  }
}
