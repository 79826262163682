import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-user-textbox',
  templateUrl: './user-textbox.component.html',
  styleUrls: ['./user-textbox.component.scss'],
})
export class UserTextboxComponent implements OnInit {
  @Input() textBoxValue: any;
  @Input() preDefineValue: any;
  @Input() questionMappingObj: any;
  @Output() inputValue = new EventEmitter<any>();

  questionMapping = {};
  preDefineInputValue: any;
  name: string;

  constructor() {}

  ngOnInit(): void {
    this.questionMapping['What is your annual projected revenue?'] =
      this.preDefineValue?.projected_revenue || '';
    this.questionMapping['How many years has the company been in operation?'] =
      new Date().getFullYear() -
        parseInt(this.preDefineValue?.inception_date) || '';
    this.questionMapping['How many employees are within the company?'] =
      this.preDefineValue?.number_of_employees || '';

    this.preDefineInputValue = this.questionMappingObj[this.textBoxValue];

    this.inputValue.emit({
      question: this.textBoxValue,
      answer: this.questionMapping[this.textBoxValue],
    });
  }

  createTextBox(): void {
    // this.textBoxForm = this.formBuilder.group({
    //   business_number: ['', Validators.compose([Validators.required])],
    // });
  }

  onChangeData(event: any): void {
    this.inputValue.emit({
      question: this.textBoxValue,
      answer: event.target.value,
    });
  }
}
