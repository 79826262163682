import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// service
import {
  BrokerService,
  UserService,
  FormValidatorService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
// external
import { switchMap } from 'rxjs/operators';
import { CustomEmailValidator } from 'src/app/shared';

@Component({
  selector: 'app-broker-create',
  templateUrl: './broker-create.component.html',
  styleUrls: ['./broker-create.component.scss'],
})
export class BrokerCreateComponent implements OnInit {
  brokerType: any[] = [
    { name: 'Yes', id: true },
    { name: 'No', id: false },
  ];

  brokerCreateForm: FormGroup;
  brokerFirmId: any;
  isRegisteredButtonDisabled = false;
  phoneNumber: any;

  constructor(
    private formBuilder: FormBuilder,
    private brokerService: BrokerService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.brokerCreateForm = this.formBuilder.group({
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      phone_number: [
        '',
        Validators.compose([Validators.required, Validators.pattern('[0-9]+')]),
      ],
      broker_type: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.brokerFirmId = this.activatedRoute.snapshot?.paramMap?.get('id');
  }

  formatPhoneNumber(event: any): string {
    const cleaned = ('' + event).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      this.phoneNumber =
        '(' + match[1] + ') ' + '- ' + match[2] + '- ' + match[3];
    }
    return null;
  }

  submitForm(): void {
    if (this.brokerCreateForm.valid) {
      this.isRegisteredButtonDisabled = true;
      this.brokerService
        .create({
          phone_number: this.brokerCreateForm.value.phone_number,
          broker_firm_id: this.brokerFirmId,
          admin: this.brokerCreateForm.value.broker_type,
          email: this.brokerCreateForm.value.email,
          insurance_products: [],
        })
        .pipe(
          switchMap((brokerResponse) => {
            return this.userService.createUser({
              first_name: this.brokerCreateForm.value.first_name,
              last_name: this.brokerCreateForm.value.last_name,
              email: this.brokerCreateForm.value.email,
              userable_id: brokerResponse.id,
              userable_type: 'App\\Models\\User\\Broker',
            });
          })
        )
        .subscribe(
          (response) => {
            this.notificationService.success(
              'broker has been created successfully',
              ''
            );
            this.router.navigate(['/mga/broker-firms/', this.brokerFirmId]);
          },
          (err) => {
            this.isRegisteredButtonDisabled = false;
            this.notificationService.error(
              'Error occurred while submitting form ',
              this.errorHandlingService.getErrors(err)
            );
          }
        );
    }
  }
}
