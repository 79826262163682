import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
// service
import {
  MediaService,
  InsurancePolicyService,
  NotificationService,
  ErrorHandlingService,
  UserService,
} from 'src/app/core/services';
@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent implements OnInit {
  currentUser: any;
  policies: any;
  clientId: any;
  policyForm: FormGroup;
  pendingLawSuitFiles: File[] = [];
  pendingLawSuiteMediaIds: string[] = [];
  displayedPolicyColumns = [
    'policy_number',
    'insurance_type',
    'Inception_date',
    'Expiration_date',
    'actions',
  ];
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private mediaService: MediaService,
    private insurancePolicyService: InsurancePolicyService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private userService: UserService
  ) {
    this.policyForm = this.formBuilder.group({
      policy_number: ['', Validators.compose([Validators.required])],
      policy_description: ['', Validators.compose([Validators.required])],
      premium_amount: [
        '',
        Validators.compose([Validators.required, Validators.pattern('[0-9]+')]),
      ],
      deductible_amount: [
        '',
        Validators.compose([Validators.required, Validators.pattern('[0-9]+')]),
      ],
      inception_date: ['', Validators.compose([Validators.required])],
      expiration_date: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.clientId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.currentUser = this.userService.getCurrentUser();
    this.getPolicyList();
  }

  getPolicyList(): void {
    this.insurancePolicyService
      .getPolicyByBrokerId(this.currentUser?.userable_id, 'insurance_product')
      .subscribe(
        (res) => {
          this.policies = res;
        },
        (err) => {
          this.notificationService.error(
            '',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  handleClick(id: any): void {
    this.router.navigate(['/broker/policies', id]);
  }
}
