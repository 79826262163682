import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// shared
import { CustomEmailValidator } from '../../shared';
// external
import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';
// service
import {
  NotificationService,
  ErrorHandlingService,
  FormValidatorService,
  UserService,
} from '../../core/services';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  phoneNumber: any;
  isSubmitting = false;
  public textMask = {
    mask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: false,
  };
  signUpForm: FormGroup;
  sentMail: boolean;
  serverErrMsg = {
    email: [],
    response: null,
  };

  constructor(
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private formValidatorService: FormValidatorService,
    private _scrollToService: ScrollToService,
    private el: ElementRef,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.signUpForm = this.fb.group({
      first_name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(`[A-Z,a-z,:,']+`),
        ]),
      ],
      last_name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(`[A-Z,a-z,:,']+`),
        ]),
      ],
      city: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z, A-Z]+'),
        ]),
      ],
      street_address: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z, A-Z]+'),
        ]),
      ],
      zip_code: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z, A-Z]+'),
        ]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          CustomEmailValidator.validate,
        ]),
      ],
      personal_phone_number: [
        '',
        Validators.compose([Validators.required, Validators.pattern('[0-9]+')]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z, A-Z]+'),
        ]),
      ],
      password_confirmation: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z, A-Z]+'),
        ]),
      ],
    });
  }

  ngOnInit(): void {}
  formatPhoneNumber(event: any): string {
    const cleaned = ('' + event).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      this.phoneNumber =
        '(' + match[1] + ') ' + '-' + match[2] + '-' + match[3];
    }
    return null;
  }

  submitForm(): void {
    if (this.signUpForm.valid) {
      this.signup(this.signUpForm.value);
    } else {
      this.formValidatorService.validateAllFormFields(this.signUpForm);
    }
  }

  scrollTop(): void {
    setTimeout(() => {
      const error = this.el.nativeElement.querySelector('div > .error');

      const config: ScrollToConfigOptions = {
        target: error.getAttribute('scroll-to') || 'scrollTop',
      };

      this._scrollToService.scrollTo(config);
    }, 1);
  }

  signup(signupFormData: FormGroup): any {
    this.userService.registerBusinessOwner(signupFormData).subscribe(
      (data) => {
        this.router.navigate(['/account/signup-success']);
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while signing up',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }
}
