import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';

@Injectable()
export class EmailService {
  constructor(private apiService: ApiService) {}

  sendQuoteSummaryEmail(data = {}): Observable<any> {
    return this.apiService
      .post(`/quote-summary-email`, data)
      .pipe(map((res) => res.data));
  }
}
