import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// service
import {
  ApplicationService,
  ErrorHandlingService,
  QuoteService,
  NotificationService,
  UserService,
} from 'src/app/core/services';
@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss'],
})
export class QuotesComponent implements OnInit {
  displayedQuoteColumns = [
    'quote_id',
    'product_name',
    'client_name',
    'broker_name',
    'submited_at',
    'quote_status',
    'quote_amount',
    'policy_issued',
    'application_id',
  ];
  quotes: any[] = [];
  quotePrice: any;
  userInfo: any;
  policyData: any[] = [];
  quoteList: any[] = [];
  constructor(
    private applicationService: ApplicationService,
    private quoteService: QuoteService,
    private router: Router,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.userService.getCurrentUser();
    // this.getQuoteList();
  }

  onQuoteStatusChange(data: any): void {
    this.getQuoteList();
  }
  getQuoteList(): void {
    this.quoteService
      .searchQuoteByMgaFirmId(this.userInfo.userable.mga_firm_id, '') // not using
      .subscribe(
        (response) => {
          // this.quotes = response;
          const data = response.map((item) => {
            if (!this.policyData.includes(item.application.premium_amount)) {
              this.quotes.push(item);
            }
            this.policyData.push(item.application.premium_amount);
          });
          this.quotePrice = response?.map((element) => {
            if (element.application.premium_amount) {
              return element.application.premium_amount;
            } else {
              return 'N/A';
            }
          });
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching quote list',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }
}
