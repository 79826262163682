import {
  NgModule,
  ModuleWithProviders,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
// components
import { HomeComponent } from './home.component';
// layouts
import { MainLayoutComponent } from '../shared';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductRequestComponent } from './product-request/product-request.component';

// shared
import { SharedModule } from '../shared';
import { ProductInfoComponent } from './product-info/product-info.component';
import { ProductTermsAndConditionComponent } from './product-request/product-terms-and-condition/product-terms-and-condition.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const homeRouting: ModuleWithProviders<any> = RouterModule.forRoot([
  {
    path: '',
    component: MainLayoutComponent,
    children: [{ path: '', component: HomeComponent, pathMatch: 'full' }],
  },
  { path: 'product-search', component: ProductSearchComponent },
  {
    path: 'category',
    children: [
      { path: ':id', component: ProductRequestComponent },
      { path: ':id/product-info', component: ProductInfoComponent },
    ],
  },
]);

@NgModule({
  imports: [
    BrowserModule,
    homeRouting,
    MatTableModule,
    CdkTableModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    HomeComponent,
    ProductListComponent,
    ProductRequestComponent,
    ProductInfoComponent,
    ProductTermsAndConditionComponent,
    ProductSearchComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeModule {}
