import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// service
import {
  UserService,
  FirmService,
  NotificationService,
  ErrorHandlingService,
} from '../../../core/services';

@Component({
  selector: 'app-mga-layout',
  templateUrl: './mga-layout.component.html',
  styleUrls: ['./mga-layout.component.scss'],
})
export class MgaLayoutComponent implements OnInit {
  brokerFirmLogo: any;
  currentUser: any;
  storeNumber: any;
  constructor(
    private userService: UserService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private firmService: FirmService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.storeNumber = this.currentUser?.stores?.id;
  }

  fetchFirmInfo(): void {
    this.firmService.getInfo(this.currentUser?.userable?.mga_firm_id).subscribe(
      (response) => {
        this.brokerFirmLogo = response?.firm?.logo?.url;
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching firm info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  handleLogout(): void {
    this.userService.purgeAuth();
    this.router.navigate(['/account/signin']);
  }

  handleSetting(): void {
    this.router.navigate(['/mga/information']);
  }

  gotoHome(): void {
    // this.userService.purgeAuth();
    this.router.navigate(['/']);
  }
}
