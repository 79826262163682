import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
// models
import { InsurancePolicy } from 'src/app/core/models';
// service
import {
  InsurancePolicyService,
  NotificationService,
  ErrorHandlingService,
} from 'src/app/core/services';
@Component({
  selector: 'app-policy-list',
  templateUrl: './policy-list.component.html',
  styleUrls: ['./policy-list.component.scss'],
})
export class PolicyListComponent implements OnInit {
  @Input() clientId: string;
  quoteId: any;
  policies: InsurancePolicy;
  displayedPolicyColumns = [
    'policy_number',
    'insurance_type',
    'Inception_date',
    'Expiration_date',
    'actions',
  ];
  constructor(
    private insurancePolicyService: InsurancePolicyService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.insurancePolicyListBasedOnClientId();
  }

  insurancePolicyListBasedOnClientId(): void {
    this.insurancePolicyService
      .getPolicyListByClientId(this.clientId, 'insurance_product')
      .subscribe(
        (response) => {
          this.policies = response;
        },
        (err) => {
          this.notificationService.error(
            'Error while fetching insurance policy list',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }
}
