import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
// rxjs
import { Observable } from 'rxjs';
import {
  map,
  startWith,
  debounceTime,
  switchMap,
  distinctUntilChanged,
} from 'rxjs/operators';
// services
import { UserService } from 'src/app/core/services';

@Component({
  selector: 'app-client-search-input',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss'],
})
export class ClientSearchComponent implements OnInit {
  @Input() initialOptions: any[];
  @Output() filteredClientsList = new EventEmitter<any[]>();
  @Output() clientSelected = new EventEmitter<any>();

  clientSearchControl = new FormControl();
  filteredOptions: any[] = [];
  userList: any[];

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    if (this.initialOptions) {
      this.filteredOptions = this.initialOptions;
    }
    this.clientSearchControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value) => {
          return this._filterUsers(value);
        })
      )
      .subscribe((response) => {
        this.filteredClientsList.emit(response);
        this.filteredOptions = response;
      });
  }

  private _filterUsers(value: string): Observable<any> {
    if (typeof value === 'string') {
      const searchTerm = value.toLowerCase();
      return this.userService.searchUser(searchTerm).pipe(
        map((data) => {
          return data;
        })
      );
    }
  }

  optionSelected(option): void {
    this.clientSelected.emit(option);
  }
}
