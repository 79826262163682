import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// material UI
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// service
import {
  UserService,
  BrokerService,
  ProductService,
  NotificationService,
  ErrorHandlingService,
} from '../../core/services';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  currentUserInfo: any;
  productList: any;
  displayedColumns: any;
  brokerInfo: any;

  constructor(
    public router: Router,
    private userService: UserService,
    private brokerService: BrokerService,
    private productService: ProductService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.displayedColumns = [
      'product_name',
      'description',
      'valid_to',
      'region',
      'created_at',
      'view',
    ];

    this.currentUserInfo = this.userService.getCurrentUser();

    this.brokerService.getInfo(this.currentUserInfo?.userable_id).subscribe(
      (response) => {
        this.brokerInfo = response;
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching broker info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );

    this.productService.getList().subscribe(
      (response) => {
        this.productList = new MatTableDataSource(response);
        this.productList.sort = this.sort;
        const sortState: Sort = { active: 'created_at', direction: 'desc' };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching product list',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
}
