import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// service
import {
  ErrorHandlingService,
  NotificationService,
  UserService,
} from '../../core/services';
@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss'],
})
export class ClientInformationComponent implements OnInit {
  clientInformation: any;
  userUpdatedFlag = false;
  buttonFlag = false;
  clientCreateForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.fetchUserInfo();
  }

  updateClient(): void {
    this.clientCreateForm = this.formBuilder.group({
      first_name: [
        this.clientInformation?.first_name,
        Validators.compose([Validators.required]),
      ],
      last_name: [
        this.clientInformation?.last_name,
        Validators.compose([Validators.required]),
      ],
    });
  }

  fetchUserInfo(): void {
    const currentUser = this.userService.getCurrentUser();

    this.userService.getInfo(currentUser.id).subscribe(
      (response) => {
        this.clientInformation = response.data;
        this.updateClient();
      },
      (err) => {
        this.notificationService.error(
          'Error occurred while fetching user info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  submitForm(): void {
    if (this.clientCreateForm.valid) {
      this.userService
        .update(this.clientInformation?.id, {
          first_name: this.clientCreateForm.value.first_name,
          last_name: this.clientCreateForm.value.last_name,
        })
        .subscribe(
          (res) => {
            this.fetchUserInfo();
            this.userUpdatedFlag = false;
          },
          (err) => {
            this.notificationService.error(
              'Error while updating user info',
              this.errorHandlingService.getErrors(err)
            );
          }
        );
    }
  }

  onUpdate(): void {
    this.userUpdatedFlag = true;
    this.buttonFlag = false;
  }
  onBackToClient(): void {
    this.userUpdatedFlag = false;
  }
}
