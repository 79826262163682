import { Component, OnInit } from '@angular/core';
// services
import {
  FirmService,
  NotificationService,
  ErrorHandlingService,
} from '../../core/services';
// models
import { BrokerFirm } from '../../core/models';

@Component({
  selector: 'app-broker-firms',
  templateUrl: './broker-firms.component.html',
  styleUrls: ['./broker-firms.component.scss'],
})
export class BrokerFirmsComponent implements OnInit {
  displayedColumns = [
    'firm_logo',
    'firm_name',
    'firm_street_address',
    'firm_city',
    'firm_created_at',
    'view',
  ];

  brokerFirmsList: BrokerFirm[];

  constructor(
    private firmService: FirmService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.fetchDataFromServer();
  }

  fetchDataFromServer(): void {
    this.firmService.getBrokerFirmList('firm').subscribe(
      (response) => {
        this.brokerFirmsList = response;
      },
      (error) => {
        this.notificationService.error(
          'Error while fetching broker list',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }
}
