import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// services
import {
  ClientService,
  QuoteService,
  NotificationService,
  ErrorHandlingService,
  BrokerService,
} from 'src/app/core/services';
@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent implements OnInit {
  selectedClientData: any;
  selectQuoteData: any;
  clientId: any;
  clientsList: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private quoteService: QuoteService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private brokerService: BrokerService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params?.quote_id) {
        this.getQuoteInfo(params?.quote_id);
      }
      if (params?.client_id) {
        this.fetchClientInfo(params?.client_id);
      }
    });
    this.fetchBrokerFirmsClientsInfo();
  }

  fetchBrokerFirmsClientsInfo(): void {
    this.brokerService.getBrokerFirmList().subscribe((response) => {
      response.forEach((brokerFirm) => {
        brokerFirm.brokers.forEach((broker) => {
          this.clientService
            .searchClientsByBrokerId(`${broker.id}`)
            .subscribe((res) => {
              this.clientsList.push(res);
            });
        });
      });
    });
  }

  fetchClientInfo(clientId: string): void {
    this.clientService.getInfo(clientId).subscribe(
      (response) => {
        this.selectedClientData = response;
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching client info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }

  clientSelected(data: any): void {
    this.selectedClientData = data;
  }

  selectedQuotedData(data: any): any {
    // console.log('line 76 >>>> selectedQuotedata in policy components', data);
    this.selectQuoteData = data;
  }

  getQuoteInfo(client_Id: string): void {
    this.quoteService.getInfo(client_Id).subscribe(
      (response) => {
        this.selectQuoteData = response;
        // console.log('selected quote data ::: in Policy component', response);
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching quote info',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
}
