import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// service
import {
  ApplicationService,
  ErrorHandlingService,
  QuoteService,
  NotificationService,
  UserService,
} from 'src/app/core/services';
@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ApplicationsComponent implements OnInit {
  displayedQuoteColumns = [
    'quote_id',
    'product_name',
    'client_name',
    'broker_name',
    'submited_at',
    'quote_status',
    'quote_amount',
    'application_id',
  ];
  quotes: any[] = [];
  quotePrice: any;
  userInfo: any;
  policyData: any[] = [];
  applicationData: any[] = [];
  quoteList: any[] = [];
  quoteStatus: any[] = [];
  checkData: any[] = [];
  constructor(
    private applicationService: ApplicationService,
    private quoteService: QuoteService,
    private router: Router,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.userService.getCurrentUser();
    this.getQuoteList();
  }

  onQuoteStatusChange(data: any): void {
    this.getQuoteList();
  }
  getQuoteList(): void {
    this.quoteService
      .searchQuoteByMgaFirmId(
        this.userInfo.userable.mga_firm_id,
        'application,insurance_products,quote_status,client,broker,application_status'
      ) // insurance_product,quote_coverage_variables,quote_status,
      .subscribe(
        (response) => {
          // this.quotes = response;
          response.map((item) => {
            if (
              !this.policyData.includes(item.application.premium_amount) ||
              !this.applicationData.includes(item.application.id)
            ) {
              // Removed by PRAJAL 16/06/2021
              // this.fetchQuoteInformation(
              //   item?.application?.id,
              //   item.insurance_product.id,
              //   item
              // );
              this.quotes.push(item);
            }
            this.policyData.push(item.application.premium_amount);
            this.applicationData.push(item.application.id);
          });
          this.quotePrice = response?.map((element) => {
            if (element.application.premium_amount) {
              return element.application.premium_amount;
            } else {
              return 'N/A';
            }
          });
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching quote list',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  // Removed by PRAJAL 16/06/2021
  // fetchQuoteInformation(applicationId: any, productId: any, item: any): void {
  //   this.quoteService
  //     .searchQuoteByApplicationIdAndProductId(applicationId, productId)
  //     .subscribe(
  //       (res) => {
  //         item.quote_status['status'] = res[0].quote_status.status;
  //         this.checkData.push(item);
  //         return res[0].quote_status.status;
  //       },
  //       (err) => {
  //         this.notificationService.error(
  //           'Error occurred while fetching quote info',
  //           this.errorHandlingService.getErrors(err)
  //         );
  //       }
  //     );
  // }
}
