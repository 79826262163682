import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// api service
import { ApiService } from './api.service';

@Injectable()
export class BusinessOwnerService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  create(data = {}): Observable<any> {
    return this.apiService
      .post(`/business-owner`, data)
      .pipe(map((res) => res.data));
  }
}
