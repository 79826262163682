import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// models
import { Media } from '../models';
// api service
import { ApiService } from './api.service';

@Injectable()
export class MediaService {
  constructor(private apiService: ApiService) {}

  create(data: File): Observable<Media> {
    return this.apiService
      .postFormData(`/media`, {
        media_data: this.apiService.base64ImageToBlob(data),
      })
      .pipe(map((res) => res.data));
  }

  createMany(data: File[]): Observable<any> {
    return this.apiService.postFormDataArray(`/media/createMany`, data);
  }

  // TODO:Need to change url, because it will get expired
  // getMedias(applicationId: number): Observable<any> {
  //   return this.apiService.getMedia(
  //     ``
  //   );
  // }

  getMedias(pdfLink): Observable<any> {
    return this.apiService.getMedia(pdfLink);
  }
}
