import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// services
import {
  ApiService,
  ErrorHandlingService,
  NotificationService,
  UserService,
} from '../../core/services';

@Component({
  selector: 'app-signup-confirm',
  templateUrl: './signup-confirm.component.html',
  styleUrls: ['./signup-confirm.component.scss'],
})
export class SignupConfirmComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.verifyUserRegistration();
  }

  verifyUserRegistration(): void {
    this.route.queryParams.subscribe((params) => {
      this.userService.registerVerify(params.queryURL).subscribe(
        (responseData) => {
          this.apiService.addAuthToken(responseData.data.token.accessToken);
          this.userService.setAuth(responseData.data.user);
        },
        (error) => {
          this.notificationService.error(
            'Error occurred while verifying user registration',
            this.errorHandlingService.getErrors(error)
          );
        }
      );
    });
  }

  onClick(): void {
    this.router.navigate(['/quote/request']);
  }
}
