import { Component, OnInit, Output, EventEmitter } from '@angular/core';
// services
import { ProductService } from '../../core/services';
// lodash
import { find } from 'lodash';

@Component({
  selector: 'app-quote-product',
  templateUrl: './quote-product.component.html',
  styleUrls: ['./quote-product.component.scss'],
})
export class QuoteProductComponent implements OnInit {
  @Output() selectedCategories = new EventEmitter<any>();

  insuranceCategories: any[];
  selected = -1;
  constructor(private productService: ProductService) {}

  ngOnInit(): void {
    this.fetchProducts();
  }

  fetchProducts(): void {
    this.productService.getCategoriesList().subscribe((response) => {
      const comingSoonCategories = response.filter((item) => {
        return item.id !== 5 && item.id !== 1;
      });

      const plCategory = find(response, (e) => e.id === 1);
      const cyberCategory = find(response, (e) => e.id === 5);

      this.insuranceCategories = [
        plCategory,
        cyberCategory,
        ...comingSoonCategories,
      ].map((item) => {
        return { ...item, selected: false };
      });
    });
  }

  checkboxModelChange(data): void {
    const selected = this.insuranceCategories.filter((item) => item.selected);
    this.selectedCategories.emit(selected);
  }
}
