import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// rxjs
import { Observable } from 'rxjs';
// service
import {
  ApplicationService,
  MediaService,
  FormValidatorService,
  QuestionService,
  ErrorHandlingService,
  NotificationService,
} from 'src/app/core/services';
import { CustomEmailValidator } from 'src/app/shared';
// types
import { IAddressResult } from 'src/app/shared/components/google-address-input/google-address-input.component';

@Component({
  selector: 'app-quote-application',
  templateUrl: './quote-application.component.html',
  styleUrls: ['./quote-application.component.scss'],
})
export class QuoteApplicationComponent implements OnInit {
  @Input() selectedCategories: any[] = [];
  @Input() selectedBrokerId: string;
  @Input() questions: any;
  @Output() moveBack = new EventEmitter<any>();
  @Output() saveApplicationInfo = new EventEmitter<any>();
  @Output() saveBusinessInfo = new EventEmitter<any>();
  @Output() saveUserInfo = new EventEmitter<any>();

  applicationForm: FormGroup;
  pendingLawSuitFiles: File[] = [];
  additionalDocuments: File[] = [];
  uploadedMediaIds: string[] = [];
  filteredBusinessIndustries: Observable<any[]>;
  projected_revenue: any;
  userInfo: any;
  businessInfo: any;
  industryData: any[];
  isCreateApplicationButtonDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private questionService: QuestionService,
    private applicationService: ApplicationService,
    private mediaService: MediaService,
    private formValidationService: FormValidatorService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.createApplicationForm();
  }

  ngOnInit(): void {
    this.industryData = this.questions?.uniq[0]?.questionable;
  }

  CommaFormatted(event): void {
    // skip for arrow keys
    if (event.which >= 37 && event.which <= 40) {
      return;
    }

    // format number
    if (this.projected_revenue) {
      this.projected_revenue =
        '$' +
        this.projected_revenue
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  numberCheck(args): boolean {
    if (args.key === 'e' || args.key === '+' || args.key === '-') {
      return false;
    } else {
      return true;
    }
  }

  industrySelect(industry): void {
    this.applicationForm.get('industry_id').setValue(industry.id);
    this.fetchQuestions();
  }

  fetchQuestions(): void {
    const selectedProductIds = this.selectedCategories.map((item) => item.id);
    this.questionService
      .byCategories({
        insurance_product_category_id: selectedProductIds,
      })
      .subscribe((response) => {
        this.industryData = response.uniq[0].questionable;
      });
  }

  createApplicationForm(): void {
    this.applicationForm = this.formBuilder.group({
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      email: [
        '',
        Validators.compose([
          Validators.required,
          CustomEmailValidator.validate,
        ]),
      ],
      company_name: ['', Validators.compose([Validators.required])],
      business_number: ['', Validators.compose([Validators.required])],
      industry_id: ['', Validators.compose([Validators.required])],
      street_address: ['', Validators.compose([Validators.required])],
      number_of_employees: ['', Validators.compose([Validators.required])],
      inception_date: ['', Validators.compose([Validators.required])],
      projected_revenue: ['', Validators.compose([Validators.required])],
      city: [''],
      zip_code: [''],
      country_id: [1],
      pending_lawsuit: [false],
      is_operating_high_risk_area: [false],
      professional_certificate: [''],
    });
  }

  addressChange(address: IAddressResult): void {
    this.applicationForm.get('street_address').setValue(address.street_address);
    this.applicationForm.get('city').setValue(address.city);
    this.applicationForm.get('zip_code').setValue(address.zip_code);
  }

  moveBackToCategories(): void {
    this.moveBack.emit();
  }

  onSelectLawSuitFiles(event): void {
    this.pendingLawSuitFiles.push(...event.addedFiles);
  }

  onRemoveLawSuitFiles(file): void {
    this.pendingLawSuitFiles.splice(this.pendingLawSuitFiles.indexOf(file), 1);
  }

  onSelectAdditionalDocuments(event): void {
    this.additionalDocuments.push(...event.addedFiles);
  }

  onRemoveAdditionalDocuments(file): void {
    this.additionalDocuments.splice(this.additionalDocuments.indexOf(file), 1);
  }

  submitApplicationForm(): void {
    if (
      this.pendingLawSuitFiles.length > 0 ||
      this.additionalDocuments.length > 0
    ) {
      this.isCreateApplicationButtonDisabled = true;
      this.mediaService
        .createMany([...this.pendingLawSuitFiles, ...this.additionalDocuments])
        .subscribe(
          (response) => {
            this.uploadedMediaIds = response.data.map((item) => item.id);
          },
          (error) => {
            this.isCreateApplicationButtonDisabled = false;
            this.notificationService.error(
              'Error occurred while uploading media',
              this.errorHandlingService.getErrors(error)
            );
          }
        );
    }

    if (this.applicationForm.valid) {
      this.applicationService
        .quoteApplicationCreate({
          ...this.applicationForm.value,
          projected_revenue: parseInt(
            this.projected_revenue.substring(1).replace(',', '')
          ),
          broker_id: this.selectedBrokerId,
          inception_date: this.applicationForm
            .get('inception_date')
            .value.format('YYYY-MM-DD'),
        })
        .subscribe(
          (response) => {
            this.saveUserInfo.emit(response.user);
            this.saveBusinessInfo.emit(response.application.business);
            this.saveApplicationInfo.emit(response.application);
          },
          (error) => {
            this.notificationService.error(
              'Error occurred while quote application',
              this.errorHandlingService.getErrors(error)
            );
          }
        );
    } else {
      this.formValidationService.validateAllFormFields(this.applicationForm);
    }
  }
}
