import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// components
import { DeleteDialogComponent } from '../../../shared/components/delete-dialog/delete-dialog.component';
// services
import {
  FirmService,
  BrokerService,
  NotificationService,
  ErrorHandlingService,
} from '../../../core/services';
// models
import { BrokerFirm } from '../../../core/models';

@Component({
  selector: 'app-broker-firm-info',
  templateUrl: './broker-firm-info.component.html',
  styleUrls: ['./broker-firm-info.component.scss'],
})
export class BrokerFirmInfoComponent implements OnInit {
  brokerFirmInfo: BrokerFirm;
  brokerFirmId: string;
  displayedColumns = ['full_name', 'email', 'type', 'actions'];

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private firmService: FirmService,
    private brokerService: BrokerService,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.brokerFirmId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.fetchBrokerFirmInfo();
  }

  fetchBrokerFirmInfo(): void {
    this.firmService.getBrokerFirmInfo(this.brokerFirmId).subscribe(
      (response) => {
        this.brokerFirmInfo = response;
      },
      (error) => {
        this.notificationService.error(
          'Error occurred while fetching broker firm info',
          this.errorHandlingService.getErrors(error)
        );
      }
    );
  }

  openDeleteDialog(brokerId: any): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'Are you sure you want to delete this broker?',
        result: brokerId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.brokerService.delete(result).subscribe((res) => {
          this.notificationService.success(
            'Broker has been deleted successfully',
            ''
          );
          this.fetchBrokerFirmInfo();
        });
      }
    });
  }
}
