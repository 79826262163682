import { Component, OnInit, Input } from '@angular/core';
// models
import { BrokerFirm } from 'src/app/core/models';

@Component({
  selector: 'app-broker-firm-card-list',
  templateUrl: './broker-firm-card-list.component.html',
  styleUrls: ['./broker-firm-card-list.component.scss'],
})
export class BrokerFirmCardListComponent implements OnInit {
  @Input() brokerFirms: BrokerFirm[] = [];

  constructor() {}

  ngOnInit(): void {}
}
