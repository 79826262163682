import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// types
import { IAddressResult } from 'src/app/shared/components/google-address-input/google-address-input.component';
// Service
import { IndustryService } from '../../core/services/industry.service';
import { MediaService } from '../../core/services/media.service';
import { NotificationService } from '../../core/services/notification.service';
import { ProfileService } from '../../core/services/profile.service';
import { QuestionService } from '../../core/services/question.service';
import { UserService } from '../../core/services/user.service';
import { ErrorHandlingService } from '../../core/services/error-handling.service';
// moment
import moment from 'moment';

import { Router } from '@angular/router';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  pendingLawSuitFiles: any[] = [];
  additionalDocuments: any[] = [];
  uploadedMediaIds: string[] = [];
  uploadedAdditionalMediaIds: string[] = [];
  ratingTable: any[] = [];
  preSelectedAddress: any;
  selectedCategoryId: any;
  ratingtableData: any;
  pendingLawSuites = false;
  selectedProductCategory;
  checkingCheckBox = { 5: 'pending_lawsuit', 6: 'is_operating_high_risk_area' };
  industryList: any;
  questionList: any;
  copyOfQuestionList = new Set();
  finalQuestionList: any;
  data: any[] = [];
  questionAndAnswerSet = new Set();
  retentionList = ['r1', 'r2', 'r3', 'r4', 'r5', 'r6', 'r7'];
  selectedRetention: {} = {};
  selectedLiabilityLimit: {} = {};
  submittedData: any;
  submitUpdatedData: any;
  currentUser: any;
  currentUserProfileInfo: any;
  profileInfoResponse: any;
  questionMappingObj = {
    first_question: 'How many employees are within the company?',
    second_question: 'How many employees are within the company?',
    third_question: 'Are you currently under pending lawsuits?',
    forth_question: 'Are you operating in a high risk area?',
  };
  booleanMapping = { 0: false, 1: true };
  existingMediaIdsList: any[] = [];
  existingAdditionalMediaIdsList: any[] = [];
  newUploadedFiles: any[] = [];
  newAdditionalUploadedFiles: any[] = [];
  userId: any = '';
  preSelectedInceptionDate: any = '';
  checkingStatus = false;
  highRiskAreaStatus = false;
  @Output() ratingVariableId = new EventEmitter<any>();
  // @ViewChild('nationalDropZone') componentRef?: any;
  dropzone: any;
  constructor(
    private formBuilder: FormBuilder,
    private industryService: IndustryService,
    private questionService: QuestionService,
    private userService: UserService,
    private profileService: ProfileService,
    private mediaService: MediaService,
    private notificationService: NotificationService,
    private router: Router,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    // this.getQuestionList();
    this.getListOfIndustries();
    this.fetchCurrentUserInfo();
    // this.createProfile();
    this.fetchClientProfile();
    // this.showProfile();
    this.ratingtableData = ['a', 'b', 'c', 'd', 'e'];
  }
  // nationalDropZoneInit(arg: any): void {
  //   this.dropzone = this.componentRef.directiveRef.dropzone();
  // }
  createProfile(): void {
    //  new Date().toISOString().slice(0, 10)
    this.profileForm = this.formBuilder.group({
      company_name: ['', Validators.compose([Validators.required])],
      business_number: ['', Validators.compose([Validators.required])],
      industry_id: ['', Validators.compose([Validators.required])],
      street_address: ['', Validators.compose([Validators.required])],
      number_of_employees: ['', Validators.compose([Validators.required])],
      projected_revenue: ['', Validators.compose([Validators.required])],
      city: [''],
      zip_code: [''],
      country_id: [1],
      professional_certificate: [''],
      inception_date: ['', Validators.compose([Validators.required])],
      expiration_date: ['', Validators.compose([Validators.required])],
      pending_lawsuit: [false, Validators.compose([Validators.required])],
    });
  }
  onPreselectedInd(industry): void {
    this.profileForm.get('industry_id').setValue(industry?.id);
  }
  showProfile(): void {
    this.profileForm = this.formBuilder.group({
      company_name: [
        this.currentUserProfileInfo?.business?.name,
        Validators.compose([Validators.required]),
      ],
      business_number: [
        this.currentUserProfileInfo?.business?.business_number,
        Validators.compose([Validators.required]),
      ],
      industry_id: ['', Validators.compose([Validators.required])],
      street_address: [
        this.currentUserProfileInfo?.business
          ? this.currentUserProfileInfo?.business?.address?.street_address
          : '',
        Validators.compose([Validators.required]),
      ],
      number_of_employees: [
        this.currentUserProfileInfo?.business?.number_of_employees,
        Validators.compose([Validators.required]),
      ],
      projected_revenue: [
        this.currentUserProfileInfo?.business?.projected_revenue,
        Validators.compose([Validators.required]),
      ],
      city: [
        this.currentUserProfileInfo?.business
          ? this.currentUserProfileInfo?.business?.address?.city
          : '',
      ],
      zip_code: [
        this.currentUserProfileInfo?.business
          ? this.currentUserProfileInfo?.business?.address?.zip_code
          : '',
      ],
      country_id: [1],
      professional_certificate: [''],
      inception_date: ['', Validators.compose([Validators.required])],
      expiration_date: ['', Validators.compose([Validators.required])],
      pending_lawsuit: [],
      high_risk_area: [],
      is_operating_high_risk_area: [''],
    });
    //   this inception date is use in question and answer, it is not related to business
    if (this.preSelectedInceptionDate) {
      this.profileForm.controls['inception_date'].setValue(
        moment(this.preSelectedInceptionDate)
      );
      this.profileForm.controls['pending_lawsuit'].setValue(
        this.checkingStatus
      );
      this.profileForm.controls['high_risk_area'].setValue(
        this.highRiskAreaStatus
      );
    }
  }
  fetchCurrentUserInfo(): void {
    this.currentUser = this.userService.getCurrentUser();
  }
  addEvent(data, question): void {
    const selectedDate = new Date(
      moment(data.target.value).format('YYYY-MM-DD')
    );
    question['answer'] = selectedDate.toISOString().split('T')[0];

    this.profileForm.controls['inception_date'].setValue(
      selectedDate.toISOString().split('T')[0]
    );
    this.copyOfQuestionList.add(question);
  }

  fetchClientProfile(): void {
    this.profileService.getInfo(this.currentUser?.id).subscribe(
      (res) => {
        this.profileInfoResponse = res;
        this.userId = res?.user_id;
        this.questionList = res?.answers.map((question) => {
          if (question.id) {
            delete question.id;
            if (question.type === 'boolean' && question.answer === '') {
              question['answer'] = false;
            } else if (question.type === 'boolean') {
              if (
                this.questionMappingObj['third_question'] === question?.value
              ) {
                question['formControlName'] = 'pending_lawsuit';
                //  this.checkingStatus = this.booleanMapping[question?.answer];

                this.checkingStatus = this.booleanMapping[question?.answer];
              } else if (
                this.questionMappingObj['forth_question'] === question?.value
              ) {
                question['formControlName'] = 'high_risk_area';

                this.highRiskAreaStatus = this.booleanMapping[question?.answer];
              }
            }
            return question;
          } else {
            return question;
          }
        });

        res?.answers?.map((item) => {
          this.copyOfQuestionList.add(item);
          if (item.type === 'date') {
            this.preSelectedInceptionDate = item.answer;
          }
        });
        this.currentUserProfileInfo = res;

        if (res?.business) {
          this.preSelectedAddress =
            res?.business?.address?.street_address +
            ', ' +
            res?.business?.address?.city +
            ', ' +
            res?.business?.address?.zip_code;
        } else {
          this.preSelectedAddress = '';
        }
        if (res?.medias) {
          this.pendingLawSuitFiles = res?.medias;
        }
        if (res?.pending_lawsuit_medias) {
          this.additionalDocuments = res?.pending_lawsuit_medias;
        }
        // res?.medias
        //   ? (this.pendingLawSuitFiles = res?.medias)
        //   : this.pendingLawSuitFiles;

        this.showProfile();
      },
      (err) => {}
    );
  }

  addressChange(address: IAddressResult): void {
    this.profileForm.get('street_address').setValue(address.street_address);
    this.profileForm.get('city').setValue(address.city);
    this.profileForm.get('zip_code').setValue(address.zip_code);
  }

  onSelectLawSuitFiles(event): void {
    this.pendingLawSuitFiles.push(...event.addedFiles);
  }

  onRemoveLawSuitFiles(file): void {
    this.pendingLawSuitFiles.splice(this.pendingLawSuitFiles.indexOf(file), 1);
  }

  onSelectAdditionalDocuments(event): void {
    this.additionalDocuments.push(...event.addedFiles);
  }

  onRemoveAdditionalDocuments(file): void {
    this.additionalDocuments.splice(this.additionalDocuments.indexOf(file), 1);
  }

  industrySelect(industry, question): void {
    question['answer'] = industry.name;
    question['question_id'] = question?.id
      ? question?.id
      : question?.question_id;
    this.copyOfQuestionList.add(question);
    this.profileForm.get('industry_id').setValue(industry.id);
  }
  getSelectedProdcutCategories(id: any): void {
    this.ratingTable = [];
    this.selectedCategoryId = id;
    const filteredData = this.ratingtableData.filter((value) => {
      if (value.insurance_product_category_id === this.selectedCategoryId) {
        this.ratingTable.push(value);
      }
    });
  }

  // confirmProductCategory(data: any): void {}
  onChange(question, event): void {
    question['answer'] = event;
    question['question_id'] = question?.id
      ? question?.id
      : question?.question_id;
    if (this.questionMappingObj['third_question'] === question?.value) {
      this.checkingStatus = event;

      this.profileForm.get('pending_lawsuit').setValue(event);
    } else {
      this.profileForm.controls['high_risk_area'].setValue(event);
    }

    this.copyOfQuestionList.add(question);
    this.questionAndAnswerSet.add(question);
    this.pendingLawSuites = event;
  }

  getQuestionList(): void {
    this.questionService.getQuestionList().subscribe(
      (res) => {
        this.questionList = res;
        res?.map((item) => {
          item['question_id'] = item.id;
          if (item.type === 'boolean') {
            item['answer'] = false;
          } else {
            item['answer'] = '';
          }
          this.copyOfQuestionList.add(item);
        });
      },
      (err) => {}
    );
  }

  getListOfIndustries(): void {
    this.industryService.getList().subscribe(
      (res) => {
        this.industryList = res;
      },
      (err) => {}
    );
  }
  // setLimit(limit: any, question: any): void {
  //   question['answer'] = '';
  //   if (this.retentionList.includes(limit.value)) {
  //     question['question_id'] = question?.id
  //       ? question?.id
  //       : question?.question_id;
  //     question['answer'] = limit.value;
  //     this.copyOfQuestionList.add(question);
  //   } else {
  //     question['question_id'] = question?.id
  //       ? question?.id
  //       : question?.question_id;
  //     question['answer'] = limit?.value ? limit?.value : '';
  //     this.copyOfQuestionList.add(question);
  //   }
  // }

  questionWithAnswerData(questionWithAnswer: any): void {
    if (
      questionWithAnswer.value === this.questionMappingObj['first_question']
    ) {
      this.profileForm
        .get('number_of_employees')
        .setValue(questionWithAnswer?.answer);
    }
    if (
      questionWithAnswer.value === this.questionMappingObj['second_question']
    ) {
      this.profileForm
        .get('projected_revenue')
        .setValue(questionWithAnswer?.answer);
    }
    this.copyOfQuestionList.add(questionWithAnswer);
  }

  async create(data): Promise<any> {
    this.pendingLawSuitFiles.map((media) => {
      if (media?.id) {
        this.existingMediaIdsList.push(media.id);
      } else {
        this.newUploadedFiles.push(media);
      }
    });

    this.additionalDocuments.map((media) => {
      if (media?.id) {
        this.existingAdditionalMediaIdsList.push(media.id);
      } else {
        this.newAdditionalUploadedFiles.push(media);
      }
    });
    // this.existingMediaIdsList;
    try {
      if (this.newUploadedFiles?.length > 0) {
        const mediaResponse = await this.mediaService
          .createMany([...this.newUploadedFiles])
          .toPromise();
        this.uploadedMediaIds = mediaResponse?.data?.map((item) => item.id);
      }

      if (this.newAdditionalUploadedFiles?.length > 0) {
        const additionalMediaResponse = await this.mediaService
          .createMany([...this.newAdditionalUploadedFiles])
          .toPromise();
        this.uploadedAdditionalMediaIds = additionalMediaResponse?.data?.map(
          (item) => item.id
        );
      }

      this.submittedData = {
        ...this.submittedData,
        medias: [...this.uploadedMediaIds, ...this.existingMediaIdsList],
        pending_lawsuit_medias: [
          ...this.uploadedAdditionalMediaIds,
          ...this.existingAdditionalMediaIdsList,
        ],
      };
      this.submitUpdatedData = {
        ...this.submitUpdatedData,
        medias: [...this.uploadedMediaIds, ...this.existingMediaIdsList],
        pending_lawsuit_medias: [
          ...this.uploadedAdditionalMediaIds,
          ...this.existingAdditionalMediaIdsList,
        ],
      };
      if (this.userId) {
        const profileResponse = await this.profileService
          .update(this.submitUpdatedData)
          .toPromise();
      } else {
        const profileResponse = await this.profileService
          .create(this.submittedData)
          .toPromise();
      }
    } catch {}
  }

  submitProfileForm(): void {
    this.submittedData = {
      user_id: this.currentUser.id,
      client_id: this.currentUser?.userable_id,
      version: 1,
      business: {
        name: this.profileForm.value.company_name,
        business_number: this.profileForm.value.business_number,
        number_of_employees: this.profileForm.value.number_of_employees,
        projected_revenue: this.profileForm.value.projected_revenue,
        owner_id: this.currentUser?.userable_id,
        industry_id: this.profileForm.value.industry_id
          ? this.profileForm.value.industry_id
          : this.currentUserProfileInfo?.business?.industry_id,
        pending_lawsuit: this.profileForm.value.pending_lawsuit
          ? this.profileForm.value.pending_lawsuit
          : false,
        inception_date: this.profileForm.value.inception_date,
      },
      address: {
        city: this.profileForm.value.city,
        country_id: 1,
        street_address: this.profileForm.value.street_address,
        zip_code: this.profileForm.value.zip_code,
      },
      answers: [...this.copyOfQuestionList],
    };

    this.submitUpdatedData = {
      id: this.profileInfoResponse?.id,
      user_id: this.currentUser.id,
      client_id: this.currentUser?.userable_id,
      version: this.profileInfoResponse?.version,
      business: {
        id: this.profileInfoResponse?.business?.id,
        name: this.profileForm.value.company_name,
        business_number: this.profileForm.value.business_number,
        number_of_employees: this.profileForm.value.number_of_employees,
        projected_revenue: this.profileForm.value.projected_revenue,
        owner_id: this.currentUser?.userable_id,
        industry_id: this.profileForm.value.industry_id
          ? this.profileForm.value.industry_id
          : this.currentUserProfileInfo?.business?.industry_id,
        pending_lawsuit: this.profileForm.value.pending_lawsuit
          ? this.profileForm.value.pending_lawsuit
          : false,
        inception_date: this.profileForm.value.inception_date,
      },
      address: {
        id: this.profileInfoResponse?.business?.address?.id,
        city: this.profileForm.value.city,
        country_id: 1,
        street_address: this.profileForm.value.street_address,
        zip_code: this.profileForm.value.zip_code,
      },
      answers: [...this.copyOfQuestionList],
    };

    if (this.userId) {
      this.create(this.submitUpdatedData)
        .then((response) => {
          this.notificationService.success(
            'Profile has been updated Successfully',
            ''
          );
          this.router.navigate(['/client']);
        })
        .catch((error) => {
          this.notificationService.error(
            'Error occurred while updating Profile',
            this.errorHandlingService.getErrors(error)
          );
        });
    } else {
      this.create(this.submittedData)
        .then((response) => {
          this.notificationService.success(
            'Profile has been crated Successfully',
            ''
          );
          this.router.navigate(['/client']);
        })
        .catch((error) => {
          this.notificationService.error(
            'Error occurred while Creating Profile',
            this.errorHandlingService.getErrors(error)
          );
        });
    }
  }
}
