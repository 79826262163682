import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// material ui
import { MatTableDataSource } from '@angular/material/table';
import { Application } from 'src/app/core/models';
// service
import {
  ApplicationService,
  UserService,
  NotificationService,
  ErrorHandlingService,
  ProductService,
} from '../../../core/services';
@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss'],
})
export class ApplicationListComponent implements OnInit {
  public applicationList: Application[] = [];
  currentUser: any;
  public displayedColumns = [
    'application_id',
    'business_name',
    'broker_name',
    'client_email',
    'created_at',
    'status',
    'actions',
  ];
  dataSource = new MatTableDataSource<any>();
  brokerProductList: any;
  showMessage = false;
  constructor(
    private applicationService: ApplicationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private errorHandlingService: ErrorHandlingService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.fetchApplicationByBrokerId();
    this.fetchProductByBrokerId();
  }

  fetchProductByBrokerId(): void {
    this.productService
      .searchProductsByBrokerFirmId(
        this.currentUser?.userable?.broker_firm_id,
        'NA'
      )
      .subscribe(
        (res) => {
          this.brokerProductList = res;
        },
        (err) => {
          this.notificationService.error(
            'Error occurred while fetching products',
            this.errorHandlingService.getErrors(err)
          );
        }
      );
  }

  fetchApplicationByBrokerId(): void {
    this.applicationService
      .searchApplicationByBrokerId(
        this.currentUser?.userable_id,
        'application_status,client,business'
      )
      .subscribe(
        (response) => {
          this.applicationList = response;
        },
        (error) => {
          this.notificationService.error(
            'Error occurred while fetching application by broker id',
            this.errorHandlingService.getErrors(error)
          );
        }
      );
  }

  handleButtonClick(): void {
    if (this.brokerProductList.length === 0) {
      this.showMessage = true;
    } else {
      this.router.navigate([`/broker/applications/create`]);
    }
  }
  handlePageRefresh(): void {
    this.showMessage = false;
  }
  getSelectedApplication(applicationDetails: any): void {
    this.router.navigate([`/broker/applications`, applicationDetails.id]);
  }
}
