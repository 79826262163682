export class InsurancePolicy {
  id?: number;
  policy_number: number;
  policy_description?: string;
  premium_frequency?: any;
  limit_type?: any;
  inception_date?: Date;
  expiration_date?: Date;
  premium_amount?: number;
  limit_percentage?: number;
  deductible_amount?: number;
  deductible_percentage?: number;
  created_at: Date;
  updated_at: Date;
  insurance_product__id: number;
  media_id: number;
  quote_id: number;
  client_id: number;
}
