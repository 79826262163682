import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
// service
import {
  ErrorHandlingService,
  InsurancePolicyService,
  MediaService,
  NotificationService,
} from 'src/app/core/services';

import moment from 'moment';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-policy-info',
  templateUrl: './policy-info.component.html',
  styleUrls: ['./policy-info.component.scss'],
})
export class PolicyInfoComponent implements OnInit {
  policyUpdateForm: FormGroup;
  isEditingMode = false;
  policyId: any;
  policyDetail: any;
  pendingLawSuitFiles: File[] = [];
  pendingLawSuiteMediaIds: string[] = [];

  constructor(
    private router: Router,
    private insurancePolicyService: InsurancePolicyService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.policyId = this.activatedRoute.snapshot?.paramMap?.get('id');
    this.fetchPolicyDetails();
  }

  getPdf(fileData: any): void {
    FileSaver.saveAs(fileData, `application.pdf`);
  }

  fetchPolicyDetails(): void {
    this.insurancePolicyService.getInfo(this.policyId).subscribe(
      (response) => {
        this.policyDetail = response;
      },
      (err) => {
        this.notificationService.error(
          'Error while fetching policy details',
          this.errorHandlingService.getErrors(err)
        );
      }
    );
  }
}
